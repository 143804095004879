<template>
  <span>
    {{ spacer }}
    <span
      v-for="(author, index) in authors"
      :key="index"
      class="author"
      :class="{ 'hover-active pointer': !disableClick }"
      @click.stop="loadJournalist(author)"
    >
      <EllipseContent>
        {{ capitalizeName(author)
        }}{{ !(authors.length - index - 1) ? "" : ", " }}
      </EllipseContent>
    </span>
  </span>
</template>

<script>
import { useSideBarStore } from "desktop/src/stores/sideBar";

import { EllipseContent } from "shared/components/base";
import { capitalizeName } from "shared/helpers/string";

export default {
  name: "AuthorWidget",
  components: { EllipseContent },
  props: {
    mention: {
      type: Object,
      required: true,
    },
    noSpacer: {
      type: Boolean,
    },
    disableClick: {
      type: Boolean,
    },
  },
  setup() {
    const sideBarStore = useSideBarStore();
    const { showSideBar, setSideBarContent } = sideBarStore;

    return { showSideBar, setSideBarContent };
  },
  computed: {
    isSocial() {
      return [
        "facebook_post",
        "tweet",
        "youtube_clip",
        "instagram_post",
        "reddit_post",
        "reddit_post_comment",
        "blog_post",
        "forum_post",
      ].includes(this.mention.type);
    },
    spacer() {
      return this.isSocial || this.noSpacer ? "" : "·";
    },
    authors() {
      if (!this.mention.author) return [];

      // split by comma (,) and 'and'
      const regex = /\s?(?:,|\band\b)\s?/i;

      // remove anything after/including for, in, #, -, ? unless its part of a word.
      const cleaningRegex = /\s(for|in|[#\-?]|#.+)(?!\w).*/i;

      return this.mention.author
        .split(regex)
        .map((name) => name.replace(cleaningRegex, ""));
    },
  },
  methods: {
    capitalizeName,
    loadJournalist(author) {
      if (this.disableClick) return;

      if (this.$isDesktop) {
        this.loadSideBar(author);
      } else if (this.$isMobile) {
        this.$safeRouterPush({
          name: "journalist-view",
          query: { author },
          state: {
            mention: JSON.stringify(this.mention),
          },
        }).catch(() => {});
      }
    },
    loadSideBar(author) {
      this.setSideBarContent({
        title: "",
        component: "JournalistSideBar",
        data: { author, mention: this.mention },
      });
      this.showSideBar();
    },
  },
};
</script>

<style lang="scss" scoped>
.author :deep(.ellipsis) {
  display: inline-block;
  vertical-align: bottom;
  max-width: 198px;
  margin-right: 3px;
}
</style>

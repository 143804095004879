import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Podcast from "./Podcast";
import PodcastEpisodeFragment from "./PodcastEpisodeFragment";

@Model()
class PodcastEpisode extends ApplicationRecord {
  static jsonapiType = "podcast_episodes";

  @BelongsTo() source: Podcast;

  @HasMany() podcastEpisodeFragments: PodcastEpisodeFragment[];

  @Attr() title: string;

  @Attr() author: string;

  @Attr() podcastId: string;

  @Attr() body: string;

  @Attr() url: string;

  @Attr() mediaUrl: string;

  @Attr() mediaFileData: string;

  @Attr() mediaFilename: string;

  @Attr({ persist: false }) signedUrl: string;

  @Attr() urlHash: string;

  @Attr() externalMediaUrl: string;

  @Attr({ persist: false }) excerpts: string[];

  @Attr({ persist: false }) keywordCounts: Record<string, number>;

  @Attr() publishedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default PodcastEpisode;

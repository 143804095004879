import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import InsightsReport from "./InsightsReport";
import type { Chart } from "./types";

@Model()
class InsightsReportKeyInsight extends ApplicationRecord {
  static jsonapiType = "insights_report_key_insights";

  @BelongsTo() insightsReport: InsightsReport;

  @Attr() chart: Chart;

  @Attr() primaryValue: number;

  @Attr() secondaryValue: number;

  @Attr() position: number;
}

export default InsightsReportKeyInsight;

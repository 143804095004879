import { Attr, HasOne, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import OrganisationContract from "./OrganisationContract";

@Model()
class OrganisationContractService extends ApplicationRecord {
  static jsonapiType = "organisation_contract_services";

  @HasOne() organisationContract: OrganisationContract;

  @Attr() organisationContractId: number;

  @Attr() service: string;

  @Attr() pricePerMonth: number;

  @Attr() invoiceDescription: string;

  @Attr() overrideBillingCycle: boolean;

  @Attr() billMonthInAdvance: boolean;

  @Attr() billingCycle: string;

  @Attr({ persist: false }) priceIncludingTax: number;

  @Attr() reportFrequencyDelivery: string;

  @Attr() reportFirstDeliveryDate: string;

  @Attr() expectedReportDeliveries: number;

  @Attr({ persist: false }) taxAmount: number;
}

export default OrganisationContractService;

/* eslint-disable import/no-mutable-exports */
import type { StoreInstance } from "@quasar/app-vite";
import type { App } from "vue";
import type { Router } from "vue-router";

import { adminBaseUrl, v1BaseUrl } from "shared/constants";
import createAxiosInstance from "shared/helpers/createAxiosInstance";
import ApiClient from "shared/services/api/ApiClient";
import { useUserStore } from "shared/stores/user";

const $cacheFetch = new ApiClient("/", createAxiosInstance({ baseURL: "" }));

let $streemApiV1 = new ApiClient(
  "/",
  createAxiosInstance({ baseURL: v1BaseUrl })
);
let $streemApiAdmin = new ApiClient(
  "/",
  createAxiosInstance({ baseURL: adminBaseUrl })
);

export default ({
  app,
  router,
  store,
  userStore,
}: {
  app: App;
  router: Router;
  store: StoreInstance;
  userStore: ReturnType<typeof useUserStore>;
}) => {
  $streemApiV1 = new ApiClient(
    "/",
    createAxiosInstance({
      router,
      store,
      userStore,
      baseURL: v1BaseUrl,
    })
  );

  $streemApiAdmin = new ApiClient(
    "/",
    createAxiosInstance({
      router,
      store,
      userStore,
      baseURL: adminBaseUrl,
    })
  );

  Object.assign(app.config.globalProperties, {
    $streemApiV1,
    $streemApiAdmin,
    $cacheFetch,
  });
};

export { $streemApiV1, $streemApiAdmin, $cacheFetch };

import { BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import TwitterUser from "./TwitterUser";

@Model()
class Tweet extends ApplicationRecord {
  static jsonapiType = "tweets";

  @BelongsTo() twitterUser: TwitterUser;
}

export default Tweet;

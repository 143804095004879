import { track } from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";
import { streamTypes } from "shared/constants";
import {
  notifyAdditionOfMentionToBookmarkStream,
  notifyRemovalOfMentionFromBookmarkStream,
} from "shared/helpers/actionNotifications";
import { compositeIdToMention, targetType } from "shared/helpers/mentions";
import { getSource } from "shared/helpers/sources";
import features from "shared/services/features";
import { useStreamsStore } from "shared/stores/streams";

const handleErrors = true;

export default {
  getBookmarkStreams() {
    const params = {
      types: [streamTypes.bookmarkStream],
    };

    return $streemApiV1
      .get("streams", { params, handleErrors })
      .then((response) => useStreamsStore().replaceStreams(response.data));
  },

  async findOrCreateBookmarkStream({
    id,
    label,
    groupId,
    sourceStream = null,
    organisationTeamId = null,
  }) {
    return id
      ? useStreamsStore().bookmarkStreams.find((stream) => stream.id === id)
      : this.addBookmarkStream({
          label,
          group_id: groupId,
          ...(features.has("has_team_workspaces")
            ? { organisation_team_id: organisationTeamId }
            : {}),
          social_bookmarks_stream:
            sourceStream?.type === streamTypes.socialStream ||
            sourceStream?.social_bookmarks_stream,
        });
  },
  addBookmarkStream(params = {}) {
    const stream = {
      type: streamTypes.bookmarkStream,
      query_style: "boolean",
      boolean_query: "",
      print_content: true,
      magazine_content: true,
      podcast_content: true,
      online_content: true,
      tv_content: true,
      radio_content: true,
      social_content: true,
      social_impact_threshold: 0,
      sentiment_mode_enabled: true,
      ...params,
    };

    track("Created Bookmark Stream");

    return $streemApiV1
      .post("streams", { params: { ...stream }, handleErrors })
      .then((response) => {
        useStreamsStore().pushStream(response.data);

        return response.data;
      });
  },

  updateBookmarkStream({ id, params }) {
    track("Updated Bookmark Stream", { shared: params.shared });

    return $streemApiV1
      .put(`streams/${id}`, { params, handleErrors })
      .then((response) =>
        useStreamsStore().setStream({ id, data: response.data })
      );
  },
  deleteBookmarkStream(id) {
    track("Deleted Bookmark Stream");

    return $streemApiV1.delete(`streams/${id}`, { handleErrors }).then(() => {
      useStreamsStore().filterStream({ id });
    });
  },
  async addToBookmarkStream({
    id,
    label,
    mention,
    groupId,
    notify = true,
    autoUpdate = true,
    sourceStream = null,
    organisationTeamId = null,
  }) {
    track("Created Bookmark");

    const bookmarkStream = await this.findOrCreateBookmarkStream({
      id,
      label,
      sourceStream,
      organisationTeamId,
      groupId,
    });

    const response = await $streemApiV1.post(
      `streams/${bookmarkStream.id}/bookmarks`,
      {
        params: {
          target_id: mention.id,
          target_type: targetType(mention),
          source_stream: sourceStream?.id,
        },
        handleErrors,
      }
    );

    if (notify) {
      notifyAdditionOfMentionToBookmarkStream(bookmarkStream.label);
    }

    if (autoUpdate) {
      this.getBookmarkStreams();
      this.$patch({
        bookmarksCounts: {
          ...this.bookmarksCounts,
          [bookmarkStream.id]: response.data.count,
        },
      });
    }
  },
  async bulkAddToBookmarkStream({
    id,
    label,
    mentionIds,
    notify = true,
    autoUpdate = true,
    sourceStream = null,
    organisationTeamId = null,
  }) {
    track("Created Bookmark", { count: mentionIds.length });

    const bookmarkStream = await this.findOrCreateBookmarkStream({
      id,
      label,
      sourceStream,
      organisationTeamId,
    });

    const mentions = mentionIds.map(compositeIdToMention);

    const response = await $streemApiV1.post(
      `streams/${bookmarkStream.id}/bookmarks/bulk_create`,
      {
        params: {
          mentions: mentions.map((mention) => ({
            target_id: mention.id,
            target_type: targetType(mention),
          })),
          source_stream: sourceStream?.id,
        },
        handleErrors,
      }
    );

    if (notify) {
      notifyAdditionOfMentionToBookmarkStream(bookmarkStream.label);
    }

    if (autoUpdate) {
      this.getBookmarkStreams();
      this.$patch({
        bookmarksCounts: {
          ...this.bookmarksCounts,
          [bookmarkStream.id]: response.data.count,
        },
      });
    }
  },

  async removeFromBookmarkStream({
    bookmarkStream,
    mention,
    notify = true,
    autoUpdate = true,
  }) {
    track("Deleted Bookmark");
    const response = await $streemApiV1.delete(
      `streams/${bookmarkStream.id}/bookmarks/${mention.bookmark_id}`,
      { handleErrors }
    );

    if (notify) {
      notifyRemovalOfMentionFromBookmarkStream(bookmarkStream.label);
    }

    if (autoUpdate) {
      this.getBookmarkStreams();
      this.$patch({
        bookmarksCounts: {
          ...this.bookmarksCounts,
          [bookmarkStream.id]: response.data.count,
        },
      });
    }
  },

  async bulkRemoveFromBookmarkStream({
    bookmarkStream,
    mentionIds,
    notify = true,
    autoUpdate = true,
  }) {
    track("Deleted Bookmark", { count: mentionIds.length });

    const mentions = mentionIds.map(compositeIdToMention);

    const response = await $streemApiV1.put(
      `streams/${bookmarkStream.id}/bookmarks/bulk_destroy`,
      {
        params: {
          mentions: mentions.map((mention) => ({
            target_id: mention.id,
            target_type: getSource(mention.type).relatedCamelCaseField,
          })),
        },
        handleErrors,
      }
    );

    if (notify) {
      notifyRemovalOfMentionFromBookmarkStream(bookmarkStream.label);
    }

    if (autoUpdate) {
      this.getBookmarkStreams();
      this.$patch({
        bookmarksCounts: {
          ...this.bookmarksCounts,
          [bookmarkStream.id]: response.data.count,
        },
      });
    }
  },

  async fetchBookmarkCounts() {
    const { data } = await $streemApiV1.get("bookmarks/counts");
    this.$patch({ bookmarksCounts: data });
  },
};

import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import BriefSetsOrganisationTeam from "./BriefSetsOrganisationTeam";
import BriefSetsStream from "./BriefSetsStream";
import BriefSetsUser from "./BriefSetsUser";
import Organisation from "./Organisation";
import Stream from "./Stream";

@Model()
class BriefSet extends ApplicationRecord {
  static jsonapiType = "brief_sets";

  @BelongsTo() organisation: Organisation;

  @HasMany() briefSetsStreams: BriefSetsStream[];

  @HasMany() briefSetsUsers: BriefSetsUser[];

  @HasMany() briefSetsOrganisationTeams: BriefSetsOrganisationTeam[];

  @HasMany() streams: Stream[];

  @Attr() name: string;

  @Attr() organisationId: number;

  @Attr() organisationTeamIds: number[];

  @Attr({ persist: false }) briefCount: number;

  @Attr({ persist: false }) usersCount: number;
}

export default BriefSet;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import User from "./User";

type Scope = "offline_access" | "accounting.contacts.read";

@Model()
class XeroAuthorisation extends ApplicationRecord {
  static jsonapiType = "xero_authorisations";

  @BelongsTo() user: User;

  @Attr() userId: number;

  @Attr() code: string;

  @Attr() redirectUri: string;

  @Attr() scopes: Scope[];
}

export default XeroAuthorisation;

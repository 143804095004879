export const props = {
  mention: {
    type: Object,
    required: true,
  },
  mentions: {
    type: Array,
    default: () => [],
  },
  stream: {
    type: Object,
    default: () => ({}),
  },
  actionSheetComponent: {
    type: [Function, Object, String],
    default: null,
  },
  options: {
    type: Object,
    default: () => ({}),
  },
  keywords: {
    type: Array,
    default: () => [],
  },
  contentClass: {
    type: [Array, Object, String],
    default: "",
  },
  title: {
    type: String,
    default: "",
  },
  listTitle: {
    type: String,
    default: "",
  },
  showCheckbox: {
    type: Boolean,
  },
  logo: {
    type: String,
    default: "",
  },
  icon: {
    type: String,
    default: "",
  },
  dark: {
    type: Boolean,
  },
  inMentionPreviewAlert: {
    type: Boolean,
  },
  noHighlighting: {
    type: Boolean,
  },
  hideBorderColor: {
    type: Boolean,
  },
  hideEngagement: {
    type: Boolean,
  },
  hideMedia: {
    type: Boolean,
  },
  alwaysShowKeywords: {
    type: Boolean,
  },
  alwaysPlayInPopout: {
    type: Boolean,
  },
  product: {
    type: String,
    default: null,
  },
  selected: {
    type: Boolean,
  },
};

export function propsExcept(keys = []) {
  const obj = {};

  Object.keys(props).forEach((key) => {
    if (!keys.includes(key)) {
      obj[key] = props[key];
    }
  });

  return obj;
}

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import TvChannel from "./TvChannel";

@Model()
class TvSuper extends ApplicationRecord {
  static jsonapiType = "tv_supers";

  @BelongsTo() source: TvChannel;

  @Attr() excludedFrom: string;

  @Attr() endTime: number;

  @Attr() sourceId: number;

  @Attr() startTime: number;

  @Attr() text: string;

  @Attr() programExclusion: boolean;
}

export default TvSuper;

import { getLocaleText } from "shared/boot/i18n";
import { streamTypes } from "shared/constants";
import { sourceColors } from "shared/helpers/colors";
import features from "shared/services/features";

export const online = {
  medium: "Online",
  label: "Online",
  field: "online",
  icon: "https://assets.streem.com.au/icons/online.svg",
  color: sourceColors.online,
  relatedFields: ["article"],
  relatedLabels: ["Online Article"],
  relatedTypes: [
    {
      label: "Online Article",
      appLabel: "Online Articles",
      field: "article",
      featureFlag: "read_online_article",
      featureId: 20,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["Article"],
  sourceLabel: "Publisher",
  classField: "website",
  resource: "websites",
  featureFlag: "read_online_article",
  displayExcerptsFeature: "read_online_article",
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const print = {
  medium: "Print",
  label: "Print",
  field: "print",
  icon: "https://assets.streem.com.au/icons/print.svg",
  color: sourceColors.print,
  relatedFields: ["paper_article"],
  relatedLabels: ["Print Article"],
  relatedTypes: [
    {
      label: "Paper Article",
      appLabel: "Print Articles",
      field: "paper_article",
      featureFlag: "view_paper_articles",
      featureId: 18,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["PaperArticle"],
  sourceLabel: "Publication",
  classField: "newspaper",
  resource: "newspapers",
  featureFlag: "view_paper_articles",
  displayExcerptsFeature: "display_print_excerpts",
  viewFeatureFlag: "view_paper_articles",
  featureAllowanceFlag: "view_print_articles",
  viewWarningFeatureFlag: "view_print_articles_warning",
  viewPopupShown: "always",
  viewPopupAllowOverspend: true,
  featureName: "Print Items",
  createActivity: false, // is recorded when loading the print item via preview components
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const magazine = {
  medium: "Magazine",
  label: "Magazine",
  field: "magazine",
  icon: "https://assets.streem.com.au/icons/magazine.svg",
  color: sourceColors.magazine,
  relatedFields: ["magazine_article"],
  relatedLabels: ["Magazine Article"],
  relatedTypes: [
    {
      label: "Magazine Article",
      appLabel: "Magazine Articles",
      field: "magazine_article",
      featureFlag: "view_magazine_articles",
      featureId: 33,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["MagazineArticle"],
  sourceLabel: "Magazine",
  classField: "magazine",
  resource: "magazines",
  featureFlag: "view_magazine_articles",
  displayExcerptsFeature: "display_magazine_excerpts",
  viewFeatureFlag: "view_magazine_articles",
  featureAllowanceFlag: "view_print_articles",
  viewWarningFeatureFlag: "view_print_articles_warning",
  viewPopupShown: "always",
  viewPopupAllowOverspend: true,
  featureName: "Magazine Items",
  createActivity: false, // is recorded when loading the print item via preview components
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const podcast = {
  medium: "Podcast",
  label: "Podcast",
  field: "podcast",
  icon: "https://assets.streem.com.au/icons/podcast.svg",
  color: sourceColors.podcast,
  relatedFields: ["podcast_episode"],
  relatedLabels: ["Podcast Episode"],
  relatedTypes: [
    {
      label: "Podcast Episode",
      appLabel: "Podcast Episodes",
      field: "podcast_episode",
      featureFlag: "view_podcast_episode",
      featureId: 116,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["PodcastEpisode"],
  sourceLabel: "Podcast",
  classField: "podcast",
  resource: "podcasts",
  featureFlag: "podcast_mentions",
  displayExcerptsFeature: "podcast_mentions",
  viewPopupShown: "always",
  viewPopupAllowOverspend: true,
  featureName: "Podcast Items",
  createActivity: false,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const tv = {
  medium: "TV",
  label: "TV",
  field: "tv",
  icon: "https://assets.streem.com.au/icons/tv.svg",
  color: sourceColors.tv,
  relatedFields: [
    "tv_caption",
    "tv_super",
    "tv_media_request",
    "tv_logo_appearance",
  ],
  relatedLabels: [
    "TV Caption",
    "TV Super",
    "TV Media Request",
    "Tv Logo Appearance",
  ],
  relatedTypes: [
    {
      label: "Caption",
      appLabel: "Spoken",
      field: "tv_caption",
      featureFlag: "view_tv_caption",
      checked: true,
    },
    {
      label: "Super",
      appLabel: "On-screen text",
      field: "tv_super",
      featureFlag: "view_tv_super",
      checked: false,
    },
    {
      label: "Logo Appearance",
      appLabel: "On-screen logos",
      field: "tv_logo_appearance",
      featureFlag: "view_tv_logo_appearance",
      checked: false,
    },
  ],
  relatedCamelCaseFields: [
    "TvCaption",
    "TvSuper",
    "TvSegment",
    "TvLogoAppearance",
  ],
  sourceLabel: "Channel",
  classField: "tv_channel",
  resource: "tv_channels",
  featureFlag: "tv_mentions",
  displayExcerptsFeature: "tv_mentions",
  viewFeatureFlag: "play_tv_clips",
  featureAllowanceFlag: "play_tv_clips",
  featureName: "TV Replay",
  createActivity: true,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const radio = {
  medium: "Radio",
  label: "Radio",
  field: "radio",
  icon: "https://assets.streem.com.au/icons/radio.svg",
  color: sourceColors.radio,
  relatedFields: ["radio_clip", "radio_media_request"],
  relatedLabels: ["Radio Clip", "Radio Media Request"],
  relatedTypes: [
    {
      label: "Radio Clip",
      appLabel: "Radio Clip",
      field: "radio_clip",
      featureFlag: "view_radio_clip",
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["RadioClip", "RadioSegment"],
  sourceLabel: "Station",
  classField: "radio_station",
  resource: "radio_stations",
  displayExcerptsFeature: "radio_excerpts",
  featureFlag: "radio_mentions",
  viewFeatureFlag: "play_radio_clips",
  featureAllowanceFlag: "play_radio_clips",
  featureName: "Radio Replay",
  createActivity: true,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const influencerSocial = {
  medium: "Social",
  label: "Influencer Social",
  field: "social",
  icon: "https://assets.streem.com.au/icons/social.svg",
  color: sourceColors.social,
  relatedFields: [
    "tweet",
    "facebook_post",
    "youtube_video",
    "instagram_post",
    "reddit_post",
    "reddit_post_comment",
    "blog_post",
    "forum_post",
  ],
  relatedLabels: [
    "Tweet",
    "FacebookPost",
    "YoutubeVideo",
    "InstagramPost",
    "RedditPost",
    "RedditPostComment",
    "BlogPost",
    "ForumPost",
  ],
  relatedTypes: [
    {
      label: "Tweet",
      appLabel: "Twitter",
      color: "#77BDF1",
      field: "tweet",
      featureFlag: "view_tweet",
      icon: "https://assets.streem.com.au/icons/twitter.svg",
      checked: true,
    },
    {
      label: "Facebook Post",
      appLabel: "Facebook",
      color: "#61749D",
      field: "facebook_post",
      featureFlag: "view_facebook_post",
      icon: "https://assets.streem.com.au/icons/facebook.svg",
      checked: true,
    },
    {
      label: "Instagram Post",
      appLabel: "Instagram",
      color: "#E3787C",
      field: "instagram_post",
      featureFlag: "view_instagram_post",
      icon: "https://assets.streem.com.au/icons/instagram.svg",
      checked: true,
    },
    {
      label: "YouTube Video",
      appLabel: "Youtube",
      color: "#D54B3F",
      field: "youtube_video",
      featureFlag: "view_youtube_video",
      icon: "https://assets.streem.com.au/icons/youtube.svg",
      checked: true,
    },
    {
      label: "Reddit Post",
      appLabel: "Reddit Posts",
      color: "#FB7A4A",
      field: "reddit_post",
      featureFlag: "view_reddit_post",
      icon: "https://assets.streem.com.au/icons/reddit.svg",
      checked: true,
      hidden: true,
    },
    {
      label: "Reddit Post Comment",
      appLabel: "Reddit Comments",
      color: "#FB7A4A",
      field: "reddit_post_comment",
      featureFlag: "view_reddit_post",
      icon: "https://assets.streem.com.au/icons/reddit.svg",
      checked: true,
      hidden: true,
    },
    {
      label: "Blog Post",
      appLabel: "Blog Posts",
      color: "#F06A35",
      field: "blog_post",
      featureFlag: "view_blog_post",
      icon: "https://assets.streem.com.au/icons/blog.svg",
      checked: true,
      hidden: true,
    },
    {
      label: "Forum Post",
      appLabel: "Forum Posts",
      color: "#FFB649",
      field: "forum_post",
      featureFlag: "view_forum_post",
      icon: "https://assets.streem.com.au/icons/forum.svg",
      checked: true,
      hidden: true,
    },
  ],
  relatedCamelCaseFields: [
    "Tweet",
    "FacebookPost",
    "YoutubeVideo",
    "InstagramPost",
    "RedditPost",
    "RedditPostComment",
    "BlogPost",
    "ForumPost",
  ],
  sourceLabel: "Source",
  featureFlag: "social_mentions",
  displayExcerptsFeature: "social_mentions",
  triggerAlert: false,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const social = {
  medium: "Social",
  label: "Social",
  field: "social",
  icon: "https://assets.streem.com.au/icons/social.svg",
  color: sourceColors.social,
  relatedFields: [
    "tweet",
    "facebook_post",
    "youtube_video",
    "instagram_post",
    "reddit_post",
    "reddit_post_comment",
    "blog_post",
    "forum_post",
  ],
  relatedLabels: [
    "Tweet",
    "FacebookPost",
    "YoutubeVideo",
    "InstagramPost",
    "RedditPost",
    "RedditPostComment",
    "BlogPost",
    "ForumPost",
  ],
  platforms: [
    {
      label: "X | Twitter",
      platform: "twitter",
      color: "#77BDF1",
      fields: ["tweet"],
      featureFlag: "view_tweet",
      icon: "https://assets.streem.com.au/icons/twitter.svg",
      checked: true,
      widgets: ["key_twitter_influencers", "top_tweets"],
    },
    {
      label: "Facebook",
      platform: "facebook",
      color: "#61749D",
      fields: ["facebook_post"],
      featureFlag: "view_facebook_post",
      icon: "https://assets.streem.com.au/icons/facebook.svg",
      checked: true,
      widgets: ["top_facebook_pages", "top_facebook_posts"],
    },
    {
      label: "Instagram",
      platform: "instagram",
      color: "#E3787C",
      fields: ["instagram_post"],
      featureFlag: "view_instagram_post",
      icon: "https://assets.streem.com.au/icons/instagram.svg",
      checked: true,
      widgets: ["key_instagram_influencers"],
    },
    {
      label: "YouTube",
      platform: "youtube",
      color: "#D54B3F",
      fields: ["youtube_video"],
      featureFlag: "view_youtube_video",
      icon: "https://assets.streem.com.au/icons/youtube.svg",
      checked: true,
    },
    {
      label: "Reddit",
      platform: "reddit",
      color: "#FB7A4A",
      fields: ["reddit_post", "reddit_post_comment"],
      featureFlag: "view_reddit_post",
      icon: "https://assets.streem.com.au/icons/reddit.svg",
      checked: true,
    },
    {
      label: "Blogs",
      platform: "blog",
      color: "#F06A35",
      fields: ["blog_post"],
      featureFlag: "view_blog_post",
      icon: "https://assets.streem.com.au/icons/blog.svg",
      checked: true,
    },
    {
      label: "Forums",
      platform: "forum",
      color: "#FFB649",
      fields: ["forum_post"],
      featureFlag: "view_forum_post",
      icon: "https://assets.streem.com.au/icons/forum.svg",
      checked: true,
    },
  ],
  relatedTypes: [
    {
      label: "Tweet",
      appLabel: "Twitter",
      color: "#77BDF1",
      field: "tweet",
      featureFlag: "view_tweet",
      icon: "https://assets.streem.com.au/icons/twitter.svg",
      checked: true,
    },
    {
      label: "Facebook Post",
      appLabel: "Facebook",
      color: "#61749D",
      field: "facebook_post",
      featureFlag: "view_facebook_post",
      icon: "https://assets.streem.com.au/icons/facebook.svg",
      checked: true,
    },
    {
      label: "Instagram Post",
      appLabel: "Instagram",
      color: "#E3787C",
      field: "instagram_post",
      featureFlag: "view_instagram_post",
      icon: "https://assets.streem.com.au/icons/instagram.svg",
      checked: true,
    },
    {
      label: "YouTube Video",
      appLabel: "Youtube",
      color: "#D54B3F",
      field: "youtube_video",
      featureFlag: "view_youtube_video",
      icon: "https://assets.streem.com.au/icons/youtube.svg",
      checked: true,
    },
    {
      label: "Reddit Post",
      appLabel: "Reddit Posts",
      color: "#FB7A4A",
      field: "reddit_post",
      featureFlag: "view_reddit_post",
      icon: "https://assets.streem.com.au/icons/reddit.svg",
      checked: true,
      hidden: true,
    },
    {
      label: "Reddit Post Comment",
      appLabel: "Reddit Comments",
      color: "#FB7A4A",
      field: "reddit_post_comment",
      featureFlag: "view_reddit_post",
      icon: "https://assets.streem.com.au/icons/reddit.svg",
      checked: true,
      hidden: true,
    },
    {
      label: "Blog Post",
      appLabel: "Blog Posts",
      color: "#F06A35",
      field: "blog_post",
      featureFlag: "view_blog_post",
      icon: "https://assets.streem.com.au/icons/blog.svg",
      checked: true,
      hidden: true,
    },
    {
      label: "Forum Post",
      appLabel: "Forum Posts",
      color: "#FFB649",
      field: "forum_post",
      featureFlag: "view_forum_post",
      icon: "https://assets.streem.com.au/icons/forum.svg",
      checked: true,
      hidden: true,
    },
  ],
  relatedCamelCaseFields: [
    "Tweet",
    "FacebookPost",
    "YoutubeVideo",
    "InstagramPost",
    "RedditPost",
    "RedditPostComment",
    "BlogPost",
    "ForumPost",
  ],
  sourceLabel: "Source",
  featureFlag: "has_social_platform",
  displayExcerptsFeature: "has_social_platform",
  triggerAlert: false,
  hasNotification: true,
  filterEnabled: true,
  categoryLocationFiltersEnabled: true,
};

export const externalItem = {
  label: "External Item",
  icon: "https://assets.streem.com.au/icons/external-item.svg",
  color: "#E5EAEF",
  relatedFields: ["external_item"],
  relatedLabels: ["external_item"],
  relatedTypes: [
    {
      label: "External Item",
      appLabel: "External Items",
      field: "external_item",
      featureFlag: "has_external_items",
      featureId: 132,
      checked: true,
    },
  ],
  relatedCamelCaseFields: ["ExternalItem"],
  sourceLabel: "External Item",
  classField: "external_item",
  resource: "external_items",
  featureFlag: "has_external_items",
  viewPopupShown: "always",
  viewPopupAllowOverspend: true,
  featureName: "External Items",
  createActivity: false,
  triggerAlert: true,
  hasNotification: true,
  filterEnabled: false,
  categoryLocationFiltersEnabled: false,
};

const sources = [online, print, tv, radio, magazine, influencerSocial, podcast];

const sourceByResource = sources.reduce(
  (lookup, source) => ({
    ...lookup,
    [source.resource]: source,
  }),
  {}
);

const sourceByClass = sources.reduce(
  (lookup, source) => ({
    ...lookup,
    [source.classField]: source,
  }),
  {}
);

const sourceByField = sources.reduce(
  (lookup, source) => ({
    ...lookup,
    [source.field]: source,
  }),
  {}
);

const sourceByMedium = sources.reduce(
  (lookup, source) => ({
    ...lookup,
    [source.medium]: source,
  }),
  {}
);

const sourceByRelatedFields = sources.reduce((lookup, source) => {
  source.relatedFields.forEach((field) => {
    Object.assign(lookup, { [field]: source });
  });

  return lookup;
}, {});

function adminDefaultContentToggles() {
  const defaults = {};
  sources.forEach((source) => {
    defaults[`selected_${source.field}_content`] = source.relatedTypes.map(
      ({ field }) => field
    );
  });

  return defaults;
}

function adminDefaultCheckedContentToggles() {
  const defaults = {};
  sources.forEach((source) => {
    defaults[`selected_${source.field}_content`] = source.relatedTypes
      .filter(({ checked }) => checked)
      .map(({ field }) => field);
  });

  return defaults;
}

function defaultContentToggles() {
  const defaults = {};
  sources.forEach((source) => {
    if (features.has(source.featureFlag)) {
      defaults[`selected_${source.field}_content`] = source.relatedTypes
        .filter(
          (type) =>
            (!type.featureFlag || features.has(type.featureFlag)) &&
            !type.hidden
        )
        .map(({ field }) => field);
    }
  });

  return defaults;
}

function defaultSocialContentToggles() {
  const defaults = {};
  sources.forEach((source) => {
    if (features.has(source.featureFlag)) {
      defaults[`selected_${source.field}_content`] = [];
    }
  });

  if (features.has(social.featureFlag)) {
    defaults.selected_social_content = social.relatedTypes
      .filter((type) => !type.featureFlag || features.has(type.featureFlag))
      .map(({ field }) => field);
  }

  return defaults;
}

function defaultCheckedContentToggles() {
  const defaults = {};
  sources.forEach((source) => {
    if (features.has(source.featureFlag)) {
      defaults[`selected_${source.field}_content`] = source.relatedTypes
        .filter(
          (type) =>
            type.checked &&
            (!type.featureFlag || features.has(type.featureFlag)) &&
            !type.hidden
        )
        .map(({ field }) => field);
    }
  });

  return defaults;
}

function getLocalisedSourceLabels(localSources) {
  return localSources.map((source) => {
    const { relatedTypes } = source;

    const localisedRelatedTypes = relatedTypes.map((relatedType) => ({
      ...relatedType,
      label: getLocaleText(
        `helpers.sources.${source.field}.related_type_${relatedType.field}_label`
      ),
      appLabel: getLocaleText(
        `helpers.sources.${source.field}.related_type_${relatedType.field}_app_label`
      ),
    }));

    return {
      ...source,
      label: getLocaleText(`helpers.sources.${source.field}.label`),
      relatedTypes: localisedRelatedTypes,
    };
  });
}

function getLocalisedPlatformLabels(platforms) {
  return platforms.map((platform) => ({
    ...platform,
    label: getLocaleText(
      `helpers.sources.platform_labels.${platform.platform}`
    ),
  }));
}

function getSources() {
  const localisedSources = getLocalisedSourceLabels(sources);

  const enabledSources = localisedSources.filter(
    (source) => source.filterEnabled
  );

  if (
    features.has("has_outreach_2") &&
    !features.hasAny(["has_media_platform", "has_social_platform"])
  ) {
    return enabledSources;
  }

  return localisedSources.filter((source) => features.has(source.featureFlag));
}

function getTypesForSources(selectedSources) {
  const types = selectedSources.reduce(
    (acc, source) =>
      acc.concat(
        source.relatedTypes
          .filter((type) => !type.featureFlag || features.has(type.featureFlag))
          .map(({ field }) => field)
      ),
    []
  );

  if (
    features.has("view_tv_logo_appearance") &&
    selectedSources.filter((source) => source.medium === "TV").length
  ) {
    types.push("tv_logo_appearance");
  }

  return Array.from(new Set(types));
}

function getTypesFromSelectedContent(object) {
  const types = sources
    .reduce(
      (acc, source) => acc.concat(object[`selected_${source.field}_content`]),
      []
    )
    .filter((type) => Boolean(type));

  return Array.from(new Set(types));
}

function getTypesForStreamFromSources(stream, selectedSources) {
  const types = selectedSources
    .reduce((acc, source) => {
      const sourceTypes = stream[`${source.field}_content`]
        ? stream[`selected_${source.field}_content`]
        : [];

      return acc.concat(sourceTypes);
    }, [])
    .filter((type) => Boolean(type));

  return Array.from(new Set(types));
}

function getTypesForStream(stream) {
  const isSocialStream =
    stream.type === streamTypes.socialStream || stream.social_bookmarks_stream;
  const allowedSources = isSocialStream ? [social] : getSources();

  return getTypesForStreamFromSources(stream, allowedSources);
}

function getTypesForMedia(media = []) {
  const searchTypes = [];
  sources.forEach((source) => {
    if (media.includes(source.medium)) {
      const enabledRelatedTypes = source.relatedTypes
        .filter(
          ({ featureFlag, hidden }) =>
            (!featureFlag || features.has(featureFlag)) && !hidden
        )
        .map(({ field }) => field);
      searchTypes.push(...enabledRelatedTypes);
    }
  });

  return searchTypes;
}

function getMediaForStream(stream) {
  return getSources()
    .filter((source) => stream[`${source.field}_content`])
    .map((source) => source.medium);
}

function getMediaForInsightsQuery(insightsQuery) {
  return getSources()
    .filter((source) => insightsQuery[`${source.field}Content`])
    .map((source) => source.medium);
}

function getSourcesForStream(stream) {
  return getSources().filter(({ field }) => stream[`${field}_content`]);
}

function getSource(type) {
  const source = { ...sourceByRelatedFields[type] };
  const relatedIndex = source.relatedFields.indexOf(type);
  source.relatedLabel = source.relatedLabels[relatedIndex];
  source.relatedCamelCaseField = source.relatedCamelCaseFields[relatedIndex];

  return source;
}

function getSourceFromGroup(field) {
  return sourceByField[field];
}

function getRelatedLabelFromRelatedField(field) {
  return getSource(field).relatedLabel;
}

function getAllRelatedFields() {
  return getSources().reduce(
    (acc, value) => [...acc, ...value.relatedFields],
    []
  );
}

function getSourceByField(field) {
  return sourceByField[field];
}

function getSourceByClass(classField) {
  return sourceByClass[classField];
}

function getSourceByResource(resource) {
  return sourceByResource[resource];
}

function getSocialPlatforms() {
  const platforms = getLocalisedPlatformLabels(social.platforms);

  return platforms.filter(
    ({ featureFlag }) => !featureFlag || features.has(featureFlag)
  );
}

function getEnabledSocialPlatformsForStream(stream) {
  return getSocialPlatforms().filter((platform) =>
    Boolean(
      stream.selected_social_content.some((value) =>
        platform.fields.includes(value)
      )
    )
  );
}

function getSelectedTypesFromSelectedContent(object) {
  return getSources().reduce(
    (obj, source) => ({
      ...obj,
      [`selected_${source.field}_content`]: object[`${source.field}_content`]
        ? getTypesForSources([source])
        : [],
    }),
    {}
  );
}

function sourceIcon(sourceType) {
  switch (sourceType) {
    case "facebook_post":
      return "ion-logo-facebook";
    case "instagram_post":
      return "ion-logo-instagram";
    case "reddit_post":
    case "reddit_post_comment":
      return "ion-logo-reddit";
    case "youtube_video":
      return "ion-logo-youtube";
    case "tweet":
      return "img:https://assets.streem.com.au/icons/twitterx-light.svg";
    case "blog_post":
      return "img:https://assets.streem.com.au/icons/blog.svg";
    case "forum_post":
      return "img:https://assets.streem.com.au/icons/forum.svg";
    default:
      return "ion-person";
  }
}

function socialIcon(socialType) {
  return {
    facebook_post: "img:https://assets.streem.com.au/icons/facebook.svg",
    instagram_post: "img:https://assets.streem.com.au/icons/instagram.svg",
    reddit_post: "img:https://assets.streem.com.au/icons/reddit.svg",
    reddit_post_comment: "img:https://assets.streem.com.au/icons/reddit.svg",
    tweet: "img:https://assets.streem.com.au/icons/twitter.svg",
    youtube_video: "img:https://assets.streem.com.au/icons/youtube.svg",
    blog_post: "img:https://assets.streem.com.au/icons/blog.svg",
    forum_post: "img:https://assets.streem.com.au/icons/forum.svg",
  }[socialType];
}

const externalItemIcon =
  "img:https://assets.streem.com.au/icons/external-item.svg";

function filterSocialPlatformsByTypes(types = []) {
  const { platforms } = social;

  if (!types.length) {
    return platforms;
  }

  return platforms.filter((platform) =>
    platform.fields.some((field) => types.includes(field))
  );
}

export default sources;
export {
  sources,
  sourceByMedium,
  adminDefaultContentToggles,
  adminDefaultCheckedContentToggles,
  getTypesForSources,
  getTypesFromSelectedContent,
  getSources,
  getSource,
  getSocialPlatforms,
  getEnabledSocialPlatformsForStream,
  getTypesForStreamFromSources,
  getTypesForStream,
  getMediaForStream,
  getMediaForInsightsQuery,
  getTypesForMedia,
  getSourcesForStream,
  getAllRelatedFields,
  getSourceFromGroup,
  getRelatedLabelFromRelatedField,
  getSelectedTypesFromSelectedContent,
  getSourceByField,
  getSourceByClass,
  getSourceByResource,
  defaultContentToggles,
  defaultSocialContentToggles,
  defaultCheckedContentToggles,
  sourceIcon,
  socialIcon,
  externalItemIcon,
  filterSocialPlatformsByTypes,
};

import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class SourceGroup extends ApplicationRecord {
  static jsonapiType = "source_groups";

  @Attr() name: string;

  @Attr({ persist: false }) medium: string;

  @Attr() logoUrl: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default SourceGroup;

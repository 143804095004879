export const REGEXP_EMAIL =
  /^[A-Z0-9_!#$%&'*+/=?`{|}~^.-]+@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,}(?:\.[a-z]{2})?)$/i;
export const REGEXP_NO_SPECIAL_CHARACTERS = /^[^`~!@#$%^&*+={}|\\:;“’<,>?]*$/;
export const REGEXP_PHONE_NUMBER = /^\+?[\d\s]{10,13}$/;
export const REGEXP_URL =
  /^(?<protocol>https?:\/\/|mailto:|tel:)(?<host>[+0-9]+|(?:[A-Za-z0-9-._+]+@)?[A-Za-z0-9-]+(?:\.[A-Za-z0-9-]+)+)(?<path>\/[@+~%:/.\w\-_[\]]*)?((?<anchor>#[.!\w-]+)|(?<query>\?['-+=&;%@.,\w_\-[\]]+)){0,2}$/i;
export const REGEXP_YOUTUBE_URL =
  /^(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/;
export const REGEXP_DOMAIN =
  /^(?!-)[A-Za-z0-9-]{1,63}(?<!-)(\.[A-Za-z0-9-]{1,63})*(\.[A-Za-z]{2,})$/;

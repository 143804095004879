import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class FrontPageReport extends ApplicationRecord {
  static jsonapiType = "front_page_reports";

  @BelongsTo() organisation: Organisation;

  @Attr() billable: boolean;

  @Attr() contentDate: string;

  @Attr() excludedArticlesIds: number[];

  @Attr() includeFrontpages: boolean;

  @Attr() newspaperIds: number[];

  @Attr() organisationId: number;

  @Attr() pdfUrl: string;

  @Attr() status: string;

  @Attr() recipients: string[];

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default FrontPageReport;

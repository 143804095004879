import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import ScheduledReportSpecification from "./ScheduledReportSpecification";
import ScheduledReportSpecificationRule from "./ScheduledReportSpecificationRule";

@Model()
class ScheduledReportSpecificationSection extends ApplicationRecord {
  static jsonapiType = "scheduled_report_specification_sections";

  @BelongsTo()
  scheduledReportSpecification: ScheduledReportSpecification;

  @HasMany() rules: ScheduledReportSpecificationRule[];

  @Attr() title: string;

  @Attr() position: number;

  @Attr() frequency: string;
}

export default ScheduledReportSpecificationSection;

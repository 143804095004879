import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

enum HydrationStatus {
  hydration_completed = 0,
  hydration_requested = 1,
  hydration_running = 2,
  hydration_failed = 3,
  hydration_triggered = 4,
}

@Model()
class SocialgistCaptureRule extends ApplicationRecord {
  static jsonapiType = "socialgist_capture_rules";

  @Attr() query: string;

  @Attr() externalId: string;

  @Attr() externalType: string;

  @Attr() notes: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) verifiedAt: string;

  @Attr() signedUrl: string;

  @Attr() hydrationStatus: HydrationStatus;
}

export default SocialgistCaptureRule;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import BroadcastRecorder from "./BroadcastRecorder";
import RadioStation from "./RadioStation";

@Model()
class RadioStationsBroadcastRecorder extends ApplicationRecord {
  static jsonapiType = "radio_stations_broadcast_recorders";

  @BelongsTo() radioStation: RadioStation;

  @BelongsTo() broadcastRecorder: BroadcastRecorder;

  @Attr() primary: boolean;
}

export default RadioStationsBroadcastRecorder;

import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class Attachment extends ApplicationRecord {
  static jsonapiType = "attachments";

  @Attr() url: string;

  @Attr() filename: string;

  @Attr() description: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default Attachment;

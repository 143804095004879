import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Category from "./Category";
import Location from "./Location";

@Model()
class InstagramHandle extends ApplicationRecord {
  static jsonapiType = "instagram_handles";

  @BelongsTo() location: Location;

  @BelongsTo() category: Category;

  @Attr({ persist: false }) url: string;

  @Attr() username: string;

  @Attr() businessCategoryName: string;

  @Attr() name: string;

  @Attr() fullName: string;

  @Attr() profilePicUrl: string;

  @Attr() verified: boolean;

  @Attr() enabled: boolean;

  @Attr() biography: string;

  @Attr() categoryId: number;

  @Attr() locationId: number;

  @Attr() label: string;

  @Attr() notes: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default InstagramHandle;

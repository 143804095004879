import { Attr, HasOne, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class OrganisationClient extends ApplicationRecord {
  static jsonapiType = "organisation_clients";

  @HasOne() organisation: Organisation;

  @Attr() organisationId: number;

  @Attr() name: string;

  @Attr() notes: string;

  @Attr() position: number;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) briefCount: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default OrganisationClient;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import ScheduledReportSpecificationSection from "./ScheduledReportSpecificationSection";
import Stream from "./Stream";

@Model()
class ScheduledReportSpecificationRule extends ApplicationRecord {
  static jsonapiType = "scheduled_report_specification_rules";

  @BelongsTo()
  scheduledReportSpecificationSection: ScheduledReportSpecificationSection;

  @BelongsTo() stream: Stream;

  @Attr() scheduledReportSpecificationSectionId: number;

  @Attr() streamId: number;

  @Attr() printContent: boolean;

  @Attr() magazineContent: boolean;

  @Attr() onlineContent: boolean;

  @Attr() tvContent: boolean;

  @Attr() radioContent: boolean;

  @Attr() socialContent: boolean;

  @Attr() podcastContent: boolean;
}

export default ScheduledReportSpecificationRule;

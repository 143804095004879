import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";
import Stream from "./Stream";

@Model()
class CustomerProfile extends ApplicationRecord {
  static jsonapiType = "customer_profiles";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() competitorBrief: Stream;

  @HasMany() keyMessages: any[];

  @HasMany() keyPeople: any[];

  @HasMany() brands: any[];

  @HasMany() dashboards: any[];

  @Attr() label: string;

  @Attr() stockCode: string;

  @Attr() industry: string;

  @Attr() locationIds: number[];

  @Attr() kpis: string[];

  @Attr() keyDates: string[];

  @Attr() organisationId: number;

  @Attr() competitorBriefId: number;

  @Attr({ persist: false }) updatedAt: string;
}

export default CustomerProfile;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import EmailDomain from "./EmailDomain";

@Model()
class AllowedSenderEmailAddress extends ApplicationRecord {
  static jsonapiType = "allowed_sender_email_addresses";

  @BelongsTo() EmailDomain: EmailDomain;

  @Attr() emailDomainId: number;

  @Attr() emailAddress: string;

  @Attr() label: string;
}

export default AllowedSenderEmailAddress;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class MediaReleaseUnsubscribedRecipient extends ApplicationRecord {
  static jsonapiType = "media_release_unsubscribed_recipients";

  @BelongsTo() organisation: Organisation;

  @Attr() recipient: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default MediaReleaseUnsubscribedRecipient;

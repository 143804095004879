import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class StreamsExternalItem extends ApplicationRecord {
  static jsonapiType = "streams_external_items";

  @Attr() externalItemId: number;

  @Attr() streamId: number;
}

export default StreamsExternalItem;

<template>
  <div>
    <div v-if="loading">
      <QSpinner :size="30" />
    </div>
    <div v-else>
      <strong
        v-if="showTitle"
        class="q-mb-md"
      >
        {{ $t("location_selector.title") }}
      </strong>
      <div
        v-for="topLocation in topLocations"
        :key="topLocation.key"
      >
        <template v-if="showTopLocationCheckbox(topLocation.name)">
          <Checkbox
            :model-value="mapLocations[topLocation.id]"
            class="small"
            :class="`color-${medium}`"
            :label="topLocation.name"
            new-design
            :disabled="disabled"
            @update:model-value="$emit('toggle-filter', topLocation.id)"
          />
          <div
            v-if="locationsForParent(topLocation).length > 0"
            :class="{
              disabled: !selectAny && !mapLocations[topLocation.id],
              row: isAU || $isAdminMode,
              'q-ml-md': $isDesktop,
              'q-ml-xl': $isAdminMode,
            }"
          >
            <Checkbox
              v-for="location in locationsForParent(topLocation)"
              :key="location.key"
              :label="location.name"
              :model-value="mapLocations[location.id]"
              :class="[`color-${medium}`, colClass]"
              :disabled="
                !selectAny && (disabled || !mapLocations[topLocation.id])
              "
              new-design
              @update:model-value="$emit('toggle-filter', location.id)"
            />
          </div>
        </template>
        <template v-else>
          <div :class="colClass">
            {{ disabledContentMessage(topLocation) }}
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import { sortBy } from "lodash-es";
import { storeToRefs } from "pinia";

import { Organisation } from "shared/resources";
import { useGlobalStore } from "shared/stores/global";

export default {
  name: "LocationSelector",
  props: {
    medium: {
      type: String,
      default: "",
    },
    selectedLocations: {
      type: Array,
      required: true,
    },
    organisationId: {
      type: [Number, String],
      required: true,
    },
    showTitle: {
      type: Boolean,
    },
    large: {
      type: Boolean,
    },
    small: {
      type: Boolean,
    },
    disabled: {
      type: Boolean,
    },
    selectAny: {
      type: Boolean,
    },
    customColClass: {
      type: String,
      default: null,
    },
  },
  emits: ["toggle-filter"],
  setup() {
    const globalStore = useGlobalStore();
    const { appConfig, locations } = storeToRefs(globalStore);

    return { appConfig, locations };
  },
  data() {
    return {
      loading: true,
      organisationFeatures: [],
      hasViewNZPrintAndMagazineContent: false,
      hasViewNZOnlineContent: false,
    };
  },
  computed: {
    topLocations() {
      const { international } = this;

      const filteredLocations = this.locations.filter((location) => {
        if (location.id === international.id) return false;

        return (
          location.parent_id === null || location.parent_id === international.id
        );
      });

      return sortBy(filteredLocations, ["position", "id"]);
    },
    international() {
      return this.locations.find((location) => location.type === "Global");
    },
    mapLocations() {
      const mappedLocations = {};
      this.selectedLocations.forEach((location) => {
        mappedLocations[location] = true;
      });

      return mappedLocations;
    },
    colClass() {
      if (this.customColClass) return this.customColClass;

      if (this.$isAdminMode) {
        return ["col-2"];
      }

      if (!this.isAU) {
        return ["col-12"];
      }

      return ["col-6"];
    },
    isAU() {
      return this.appConfig.platformName === "Streem";
    },
  },
  mounted() {
    this.setHasViewNZPrintAndMagazineContent();
  },
  methods: {
    async loadOrganisationFeatures() {
      this.organisationFeatures = (
        await Organisation.selectExtra(["features"]).find(this.organisationId)
      ).data.features;
    },
    async setHasViewNZPrintAndMagazineContent() {
      try {
        if (this.$isAdminMode) {
          await this.loadOrganisationFeatures();
          this.hasViewNZPrintAndMagazineContent =
            this.organisationFeatures.includes(
              "view_new_zealand_print_and_magazine_content"
            );

          this.hasViewNZOnlineContent = this.organisationFeatures.includes(
            "view_new_zealand_online_content"
          );
        } else {
          this.hasViewNZPrintAndMagazineContent = this.$features.has(
            "view_new_zealand_print_and_magazine_content"
          );
          this.hasViewNZOnlineContent = this.$features.has(
            "view_new_zealand_online_content"
          );
        }
      } catch (error) {
        this.$q.notify({
          message: this.$t(
            "location_selector.load_organisation_features_error_message",
            { error }
          ),
          color: "red",
        });
      } finally {
        this.loading = false;
      }
    },
    disabledContentMessage(topLocation) {
      return this.$isAdminMode
        ? this.$t("location_selector.disabled_content_message", {
            topLocationName: topLocation.name,
            medium: this.medium,
          })
        : "";
    },
    showTopLocationCheckbox(topLocation) {
      const isNewZealand = topLocation === "New Zealand";

      if (
        !this.hasViewNZPrintAndMagazineContent &&
        ["print", "magazine"].includes(this.medium) &&
        isNewZealand
      ) {
        return false;
      }

      if (
        !this.hasViewNZOnlineContent &&
        this.medium === "online" &&
        isNewZealand
      ) {
        return false;
      }

      return true;
    },
    locationsForParent({ id }) {
      return this.locations.filter((location) => location.parent_id === id);
    },
  },
};
</script>

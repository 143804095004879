import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import BriefSet from "./BriefSet";
import DistributionList from "./DistributionList";
import OrganisationClient from "./OrganisationClient";
import OrganisationContract from "./OrganisationContract";
import OrganisationContractService from "./OrganisationContractService";
import OrganisationMdmLocationExclusion from "./OrganisationMdmLocationExclusion";
import OrganisationsFeature from "./OrganisationsFeature";
import OrganisationsManager from "./OrganisationsManager";
import OrganisationTeam from "./OrganisationTeam";
import Stream from "./Stream";
import TranscriptTurnaroundTime from "./TranscriptTurnaroundTime";
import UpsellRegistration from "./UpsellRegistration";
import User from "./User";
import XeroContact from "./XeroContact";

enum Status {
  draft = "draft",
  live = "live",
  suspended = "suspended",
}

@Model()
class Organisation extends ApplicationRecord {
  static jsonapiType = "organisations";

  @HasOne() businessDevelopmentManager: User;

  @HasOne() operationalTeam: OrganisationTeam;

  @HasMany() attachments: ActiveStorageAttachment[];

  @BelongsTo() keyContact: User;

  @BelongsTo() secondaryKeyContact: User;

  @HasMany() organisationTeams: OrganisationTeam[];

  @HasMany() organisationsManagers: OrganisationsManager[];

  @HasMany() organisationsAccountManagers: OrganisationsManager[];

  @HasMany() users: User[];

  @HasMany() streams: Stream[];

  @HasMany() briefSets: BriefSet[];

  @HasMany() transcriptTurnaroundTimes: TranscriptTurnaroundTime[];

  @HasMany() organisationClients: OrganisationClient[];

  @HasMany() organisationContracts: OrganisationContract[];

  @HasMany() notExpiredOrganisationContracts: OrganisationContract[];

  @HasMany() organisationsFeatures: OrganisationsFeature[];

  @HasOne() latestContract: OrganisationContract;

  @HasMany() distributionLists: DistributionList[];

  @HasOne() xeroContact: XeroContact;

  @HasMany() upsellRegistrations: UpsellRegistration[];

  @HasMany()
  organisationMdmLocationExclusions: OrganisationMdmLocationExclusion[];

  @Attr() abn: string;

  @Attr() acronyms: string[];

  @Attr() active: boolean;

  @Attr() address: string;

  @Attr({ persist: false }) admin: boolean;

  @Attr() aircallOption: string;

  @Attr({ persist: false }) allowedPhoneCountryCodes: string[];

  @Attr({ persist: false }) archivedAt: string;

  @Attr() archivedReason: string;

  @Attr() billable: boolean;

  @Attr() billingContactEmail: string;

  @Attr() billingContactName: string;

  @Attr() billingContactPhone: string;

  @Attr() billingNotes: string;

  @Attr() businessDevelopmentManagerId: number;

  @Attr({ persist: false }) clientCount: number;

  @Attr() contentFlagsPackages: string;

  @Attr({ persist: false }) contractExpiry: string;

  @Attr() country: string;

  @Attr() cslPackage: string;

  @Attr() customHelpCenterContact: string;

  @Attr() customHelpMessage: string;

  @Attr() customSupportContact: string;

  @Attr() dailyReportRangeOffset: number;

  @Attr() dashboardDateRange: string;

  @Attr() defaultBriefSetId: number;

  @Attr() defaultCslRole: string;

  @Attr() defaultFilterSetId: number;

  @Attr() defaultOutreachRoleId: number;

  @Attr() defaultReportingUser: boolean;

  @Attr() defaultRoleId: number;

  @Attr() defaultSocialRoleId: number;

  @Attr() defaultTeamId: number;

  @Attr() emailFromOption: string;

  @Attr() emailReplyToOption: string;

  @Attr() excludedKeywords: string[];

  @Attr() excludeFromCopyrightReports: boolean;

  @Attr() externalSource: string;

  @Attr() featureFlagsBatch: string;

  @Attr({ persist: false }) features: string[];

  @Attr() globalCustomerId: string;

  @Attr() governmentPortfolio: string;

  @Attr() gstExempt: boolean;

  @Attr({ persist: false }) hasCsl: boolean;

  @Attr() helpCenterContactOption: string;

  @Attr() helpMessageOption: string;

  @Attr() industry: string;

  @Attr() intercomVisibility: string;

  @Attr() keyContactEmail: string;

  @Attr() keyContactId: number;

  @Attr() keyContactName: string;

  @Attr() language: string;

  @Attr() legalEntityName: string;

  @Attr({ persist: false }) logoUrl: string;

  @Attr() maxClientsCount: number;

  @Attr() maxDateRange: number;

  @Attr() maxTeamsCount: number;

  @Attr() maxUsersCount: number;

  @Attr() mediaContactEmails: string[];

  @Attr() mentionStreamsCount: number;

  @Attr() movedToCompetitor: boolean;

  @Attr() name: string;

  @Attr() operationalTeamId: number;

  @Attr() organisationContractServices: OrganisationContractService[];

  @Attr() postcode: string;

  @Attr() prAgency: boolean;

  @Attr() region: string;

  @Attr() scheduleArchivedAt: string;

  @Attr() secondaryIndustry: string;

  @Attr() secondaryKeyContactId: number;

  @Attr() serviceLevel: string;

  @Attr() socialHelpCenterContactOption: string;

  @Attr() socialStreamsCount: number;

  @Attr() socialSupportContactOption: string;

  @Attr() state: string;

  @Attr() status: Status;

  @Attr() stockSymbol: string;

  @Attr() suburb: string;

  @Attr() supportContactOption: string;

  @Attr() teamCount: number;

  @Attr() activeUserCount: number;

  @Attr() timeZone: string;

  @Attr() welcomeEmailBody: string;

  @Attr() welcomeEmailOption: string;

  archived() {
    return Boolean(this.archivedAt);
  }

  notArchived() {
    return !this.archivedAt;
  }

  notLive() {
    return !this.live();
  }

  live() {
    return this.status === Status.live;
  }

  isTierOnePlus() {
    return this.serviceLevel === "tier_one_plus";
  }

  isCustomerSuccessTier() {
    return [
      "customer_success_tier_three",
      "customer_success_tier_four",
      "customer_success_tier_five",
    ].includes(this.serviceLevel);
  }

  serviceLevelTitle() {
    let level = "";

    switch (this.serviceLevel) {
      case "tier_one_plus":
        level = "Tier 1+";
        break;
      case "standard_account_management_tier_one":
        level = "Tier 1";
        break;
      case "standard_account_management_tier_two":
        level = "Tier 2";
        break;
      case "standard_account_management_tier_three":
        level = "Tier 3";
        break;
      case "customer_success_tier_three":
        level = "Customer Success - Tier 3";
        break;
      case "customer_success_tier_four":
        level = "Customer Success - Tier 4";
        break;
      case "customer_success_tier_five":
        level = "Customer Success - Tier 5";
        break;
      case "tier_one":
        level = "Tier One";
        break;
      case "tier_two":
        level = "Tier Two";
        break;
      default:
    }

    return level;
  }
}

export default Organisation;

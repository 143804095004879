import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class TvProgramCategory extends ApplicationRecord {
  static jsonapiType = "tv_program_categories";

  @Attr() name: string;

  @Attr() currentAffairs: boolean;

  @Attr() tvCaptionRelevance: boolean;

  @Attr() tvSuperRelevance: boolean;
}

export default TvProgramCategory;

<template>
  <MentionCard
    v-intersection="handleIntersection"
    :title="title"
    :mention="mention"
    :mentions="mentions"
    :stream="stream"
    :action-sheet-component="actionSheetComponent"
    :list-title="listTitle"
    :options="options"
    :product="product"
    :no-highlighting="noHighlighting"
    :class="[
      { pointer: mention.url },
      { youtube: mention.type === 'youtube_video' },
    ]"
    :icon="sourceIcon(mention.type)"
    :show-checkbox="showCheckbox"
    :always-show-keywords="alwaysShowKeywords"
    :selected="selected"
    @click="mentionClicked($event)"
    @mention-removed="removeMention"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #before="{ hideBorderTop }">
      <div
        v-if="showThumbnail && mention.thumbnail_url"
        class="row items-start"
      >
        <img
          :src="mention.thumbnail_url"
          class="thumbnail full-width"
          :class="{ 'social-thumbnail-border': !hideBorderTop }"
          :alt="$t('global.source_logo')"
        />
      </div>
    </template>
    <template #header>
      <template
        v-if="['reddit_post', 'reddit_post_comment'].includes(mention.type)"
      >
        <span>
          <span class="alternate">{{ redditSourceName }}</span>
          <span>
            ·
            <span class="softer">
              <span v-if="mention.type === 'reddit_post_comment'">
                {{ $t("social_mention_card.commented") }}
              </span>
              <TimeAgo :date="mention.timestamp" />
            </span>
          </span>
        </span>
      </template>
      <template v-else-if="['blog_post', 'forum_post'].includes(mention.type)">
        <strong class="bigger2">
          <AuthorWidget
            v-if="mention.author"
            :disable-click="options.disableAuthorClick"
            :mention="mention"
          />
        </strong>
      </template>
      <template v-else>
        <span>
          <strong class="bigger2">
            <AuthorWidget
              v-if="mention.author"
              :disable-click="options.disableAuthorClick"
              :mention="mention"
            />
          </strong>
          <QIcon
            v-if="mention.author_verified"
            name="ion-checkmark-circle"
            class="text-social-verified social-verified"
            size="14px"
          />
          <span
            v-if="mention.author_city"
            class="softer smaller"
          >
            {{ mention.author_city }}
          </span>
          <StreemTooltip
            v-if="showTooltip"
            content-class="transparent"
          >
            <div
              class="card q-pa-md border border-silver"
              style="background-color: rgb(255 255 255 / 94%)"
            >
              <div class="row no-wrap items-center">
                <img
                  v-if="mention.author_avatar_image"
                  :src="mention.author_avatar_image"
                  class="q-mr-smd"
                  style="max-width: 40px; max-height: 40px"
                  :alt="$t('social_mention_card.author_image')"
                />
                <strong
                  >{{ mention.author }} ({{ mention.twitter_handle }})</strong
                >
              </div>
              <div class="q-my-smd">
                {{ mention.profile_description }}
              </div>
              <div class="row no-wrap justify-around smaller">
                <span>
                  <QIcon
                    name="ion-ios-people"
                    size="18px"
                    class="q-mr-xs"
                    style="vertical-align: -2px"
                  />{{ suffix(mention.author_follower_count) }}
                </span>
                <span v-if="mention.author_city">
                  <QIcon
                    name="location_on"
                    size="18px"
                    class="q-mr-xs"
                    style="vertical-align: -2px"
                  />{{ mention.author_city }}
                </span>
              </div>
            </div>
          </StreemTooltip>
        </span>

        <TimeAgo
          :date="mention.timestamp"
          class="softer self-start"
        />
      </template>
    </template>

    <slot />

    <template #afterBody>
      <!-- Display social bar -->
      <div class="soft q-mt-sm flex items-center">
        <span
          v-if="hasViewsCount"
          class="q-mr-smd"
        >
          <QIcon name="ion-ios-people" />
          {{ suffix(viewsCount) }}
          <StreemTooltip>
            {{ suffix(viewsCount) }} {{ viewsTooltip }}
          </StreemTooltip>
        </span>
        <span
          v-if="isTwitter && mention.impact"
          class="q-mr-smd"
        >
          <QIcon name="ion-ios-radio" /> {{ mention.impact }}%
          <StreemTooltip>
            {{ $t("social_mention_card.social_impact_score") }}
          </StreemTooltip>
        </span>
        <span
          v-if="commentsCount"
          class="q-mr-smd"
        >
          <QIcon name="ion-ios-megaphone" />
          {{ suffix(commentsCount) }}
          <StreemTooltip>
            {{
              $t("social_mention_card.comments_count", {
                commentsCount: suffix(commentsCount),
              })
            }}
          </StreemTooltip>
        </span>
        <span
          v-if="mention.score"
          class="q-mr-smd"
        >
          <QIcon name="ion-ios-trending-up" /> {{ mention.score }}
          <StreemTooltip>
            {{ $t("social_mention_card.impact_score_proportional") }}
          </StreemTooltip>
        </span>
        <SentimentWidget
          class="q-mr-smd"
          :mention="mention"
          :stream="stream"
        />
        <ReactScoreWidget
          class="soft"
          :mention="mention"
          :is-parent-visible="isIntersecting"
        />
      </div>
    </template>
  </MentionCard>
</template>

<script>
import AuthorWidget from "shared/components/AuthorWidget.vue";
import { MentionCard, propsExcept } from "shared/components/MentionCard";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import useMention from "shared/composables/useMention";
import { suffix } from "shared/helpers/number";
import { sourceIcon } from "shared/helpers/sources";

export default {
  name: "SocialMentionCard",
  components: {
    MentionCard,
    AuthorWidget,
    SentimentWidget,
    ReactScoreWidget,
  },
  inheritAttrs: false,
  props: {
    ...propsExcept(["title", "listTitle"]),
  },
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const { mentionClicked } = useMention(mentionProps, context);

    return {
      mentionClicked,
    };
  },
  data() {
    return {
      isIntersecting: false,
    };
  },
  computed: {
    listTitle() {
      // has a title
      if (
        ["reddit_post", "youtube_video", "forum_post"].includes(
          this.mention.type
        )
      ) {
        return this.mention.title;
      }

      // custom title
      if (this.mention.type === "reddit_post_comment") {
        return `r/${this.mention.source_name}`;
      }

      if (
        ["tweet", "facebook_post", "instagram_post"].includes(this.mention.type)
      ) {
        return this.mention.author;
      }

      return null;
    },
    title() {
      // has a title
      if (["reddit_post", "youtube_video"].includes(this.mention.type)) {
        return this.mention.title;
      }

      // custom title
      if (this.mention.type === "reddit_post_comment") {
        return `r/${this.mention.source_name}`;
      }

      return null;
    },
    redditSourceName() {
      if (this.mention.type === "reddit_post_comment") {
        return `u/${this.mention.author}`;
      }

      return `r/${this.mention.source_name}`;
    },
    showTooltip() {
      // disabled for now
      // return this.mention.type === 'tweet';
      return false;
    },
    showThumbnail() {
      return ["youtube_video", "instagram_post"].includes(this.mention.type);
    },
    isMeta() {
      return ["facebook_post", "instagram_post"].includes(this.mention.type);
    },
    isTwitter() {
      return this.mention.type === "tweet";
    },
    isYouTube() {
      return this.mention.type === "youtube_video";
    },
    isReddit() {
      return ["reddit_post", "reddit_post_comment"].includes(this.mention.type);
    },
    isBlogPost() {
      return this.mention.type === "blog_post";
    },
    hasViewsCount() {
      if (this.isMeta) return false;

      return true;
    },
    viewsCount() {
      if (this.isTwitter) {
        return this.mention.author_follower_count;
      }

      if (this.isYouTube) {
        return this.mention.view_count;
      }

      if (this.isReddit) {
        return this.mention.source_follower_count;
      }

      return 0;
    },
    viewsTooltip() {
      if (this.isTwitter) {
        return this.$t("social_mention_card.followers");
      }

      if (this.isReddit) {
        return this.$t("social_mention_card.subreddit_followers");
      }

      if (this.isYouTube || this.isBlogPost) {
        return this.$t("social_mention_card.views");
      }

      return "";
    },
    commentsCount() {
      if (this.isReddit) {
        return this.mention.comments_count;
      }

      return 0;
    },
  },
  methods: {
    suffix,
    sourceIcon,
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    handleIntersection({ isIntersecting }) {
      this.isIntersecting = isIntersecting;
    },
  },
};
</script>

<style lang="scss" scoped>
.social-thumbnail-border {
  border-radius: $streem-media-top-border-radius;
}

.social-icon {
  &.ion-checkmark-circle {
    color: rgb(73 208 103 / 50%);
  }

  &.ion-close-circle {
    color: rgb(249 88 88 / 50%);
  }

  &.ion-remove-circle {
    color: rgb(50 159 236 / 50%);
  }
}

.social-verified {
  vertical-align: 0;
  margin-left: 3px;
}
</style>

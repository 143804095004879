import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord, {
  objectDirtyChecker,
} from "shared/services/spraypaint";

import Attachment from "./Attachment";
import Organisation from "./Organisation";
import PodcastEpisode from "./PodcastEpisode";
import ProgramAiring from "./ProgramAiring";
import TranscriptRequestSourceFile from "./TranscriptRequestSourceFile";
import User from "./User";
import ViewedItem from "./ViewedItem";

@Model()
class TranscriptRequest extends ApplicationRecord {
  static jsonapiType = "transcript_requests";

  @BelongsTo() source: any;

  @BelongsTo() user: User;

  @BelongsTo() accountManager: User;

  @BelongsTo() secondaryAccountManager: User;

  @BelongsTo() creator: User;

  @BelongsTo() organisation: Organisation;

  @BelongsTo() programAiring: ProgramAiring;

  @BelongsTo() podcastEpisode: PodcastEpisode;

  @HasMany()
  transcriptRequestSourceFiles: TranscriptRequestSourceFile[];

  @HasMany({ name: "target" }) viewedItems: ViewedItem[];

  @HasMany() attachments: Attachment[];

  @HasMany() manualAttachments: Attachment[];

  @HasMany() transcriptionAttachments: Attachment[];

  @Attr() title: string;

  @Attr() notes: string;

  @Attr() startTime: string;

  @Attr() endTime: string;

  @Attr() completedAt: string;

  @Attr() status: string;

  @Attr() billable: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() sourceName: string;

  @Attr() timeZone: string;

  @Attr() originId: number;

  @Attr() originType: string;

  @Attr({ persist: false }) originLabel: string;

  @Attr() sourceType: string;

  @Attr() sourceId: number;

  @Attr({ persist: false }) thumbnail: string;

  @Attr({ persist: false }) pdfFileUrl: string;

  @Attr({ persist: false }) docxFileUrl: string;

  @Attr() instantDownload: boolean;

  @Attr() turnaroundMinutes: number;

  @Attr() userId: number;

  @Attr() organisationTeamId: number;

  @Attr() accountManagerId: number;

  @Attr() secondaryAccountManagerId: number;

  @Attr() podcastEpisodeId: number;

  @Attr({ persist: false }) playerUrl: string;

  @Attr({ dirtyChecker: objectDirtyChecker }) recipients: any;

  @Attr({ dirtyChecker: objectDirtyChecker }) fileUrl: any;

  @Attr({ dirtyChecker: objectDirtyChecker }) formats: any;

  @Attr() summary: string;

  @Attr() lockVersion: number;

  @Attr() organisationId: number;

  @Attr() templateId: number;

  @Attr() manualTranscriptionNotes: string;

  @Attr() manualTranscriptionStatus: string;

  @Attr() transcriptionMethod: string;

  @Attr({ persist: false }) transcriptionPlatformUrl: string;

  @Attr() body: string;

  @Attr() originalId: number;

  @Attr({ persist: false }) medium: any;

  handlerName() {
    return this.accountManager?.name;
  }

  secondaryHandlerName() {
    return this.secondaryAccountManager?.name;
  }

  requestedByUser() {
    if (this.creator && this.user) {
      return this.creator.id === this.user.id;
    }

    return false;
  }

  inProgress(statusObject: any) {
    const statusSource = statusObject || this;

    return (
      statusSource.status !== "Completed" &&
      ((this.transcriptionMethod === "automated" &&
        statusSource.status !== "Ready") ||
        (this.transcriptionMethod === "professional" &&
          statusSource.manualTranscriptionStatus !== "Delivered"))
    );
  }
}

export default TranscriptRequest;

<template>
  <div
    ref="htmlParent"
    v-intersection="handleIntersection"
    :class="playerParentClass"
  >
    <div
      v-if="playedClip"
      class="player"
    >
      <div
        v-show="showCurrentAffairsRestrictionMessage"
        :style="{ height: playerHeight + 'px' }"
        class="current-affairs-restriction"
      >
        <p class="absolute-center text-help-text full-width q-pa-md">
          {{ $t("inline_player.restriction_message") }}
        </p>
      </div>
      <div v-if="showMediaCardLayout">
        <div
          :class="{
            'media-card-wrapper': $isDesktop,
            'media-card-wrapper-mobile': $isMobile,
          }"
          @click="
            $emit('handle-wrapper-click', {
              isPlaying: isPlaying,
              togglePlay: togglePlay,
            })
          "
        >
          <slot
            name="beforePlayer"
            v-bind="{
              isPlaying,
              firstPlay,
              isAudio,
              isVideo,
              playerHeight,
              canSkipBackward,
              canSkipForward,
              togglePlay,
              fastforward,
              rewind,
            }"
          />
          <video
            v-if="isVideo"
            ref="player"
            :src="mediaSource"
            :height="playerHeight"
            :poster="posterProp"
            playsinline
            webkit-playsinline
            :controls="false"
            preload="metadata"
            class="media-card-video"
            @ended="ended"
            @pause="paused"
            @play="played"
          />
          <video
            v-else
            ref="player"
            :src="mediaSource"
            :height="playerHeight"
            playsInline
            webkit-playsinline
            preload="metadata"
            @ended="ended"
            @pause="paused"
            @play="played"
          />
          <slot
            name="afterPlayer"
            v-bind="{
              firstPlay,
              allowFullscreen,
              clipDuration,
              clock,
              fullScreen,
            }"
          />
        </div>
      </div>
      <div
        v-else-if="overlay"
        :class="firstPlay && 'overlay'"
      >
        <QIcon
          v-if="firstPlay"
          class="overlay-play-btn"
          name="img:https://assets.streem.com.au/icons/play-media.svg"
          :size="overlayPlayBtnSize"
          :style="{ top: playerHeight / 3 + 'px' }"
        />
        <video
          v-if="isVideo"
          ref="player"
          :src="mediaSource"
          :height="playerHeight"
          :poster="posterProp"
          playsinline
          webkit-playsinline
          :controls="showControls"
          preload="metadata"
          @ended="ended"
          @pause="paused"
          @play="played"
          @click="onVideoClick"
        />
        <video
          v-else
          ref="player"
          :src="mediaSource"
          height="0"
          playsinline
          webkit-playsinline
          preload="metadata"
          @ended="ended"
          @pause="paused"
          @play="played"
          @click="onVideoClick"
        />
      </div>
      <div v-else>
        <video
          ref="player"
          :src="mediaSource"
          :height="playerHeight"
          :poster="posterProp"
          playsinline
          webkit-playsinline
          :controls="showControls"
          preload="metadata"
          @ended="ended"
          @pause="paused"
          @play="played"
          @click="onVideoClick"
        />
      </div>
      <div
        v-if="!showControls && !hideSlider"
        class="slider column"
        :class="{ tv: isVideo, radio: !isVideo }"
      >
        <QSlider
          v-model="timeModel"
          color="primary"
          :min="0"
          :max="clipDuration"
          :step="clipStep"
          class="cursor-pointer"
          @update:model-value="updateClocks"
        />
      </div>
      <div
        v-if="!hideButtonBar"
        class="button-bar row items-center"
        :class="{ tv: isVideo, radio: !isVideo }"
      >
        <QBtn
          :disable="!canSkipBackward"
          flat
          icon="ion-ios-rewind"
          :aria-label="$t('inline_player.rewind')"
          @click.stop="rewind"
        />
        <QBtn
          :disable="!canPlay"
          :icon="isPlaying ? 'ion-ios-pause' : 'ion-ios-play'"
          flat
          :aria-label="$t('inline_player.play')"
          @click.stop="togglePlay"
        />
        <QBtn
          :disable="!canSkipForward"
          flat
          icon="ion-ios-fastforward"
          :aria-label="$t('inline_player.forward')"
          @click.stop="fastforward"
        />
        <div class="timer text-center">
          <span v-if="!firstPlay">
            {{ clock }}
          </span>
        </div>
        <QBtn
          v-if="$isMobile && shareButton"
          icon="ion-md-share"
          flat
          :aria-label="$t('inline_player.share_media_item')"
          @click.stop="shareMediaItem"
        />
        <QBtn
          v-if="requestMediaItemButton"
          icon="ion-md-download"
          flat
          :aria-label="$t('inline_player.open_request_media_item')"
          @click.stop="openRequestMediaItemModal"
        >
          <StreemTooltip>{{
            $t("inline_player.request_media_item")
          }}</StreemTooltip>
        </QBtn>
        <QBtn
          v-if="isMobileAndLandscape"
          flat
          icon="ion-md-contract"
          :aria-label="$t('inline_player.go_back')"
          @click.stop="goBack"
        />
        <QBtn
          v-if="downloadLink"
          flat
          icon="ion-md-download"
          :aria-label="$t('global.download')"
          @click.stop="openDownloadLink"
        />
        <QBtn
          v-if="type === 'video' && !firstPlay && allowFullscreen"
          icon="ion-md-expand"
          flat
          :aria-label="$t('inline_player.full_screen')"
          @click.stop="fullScreen"
        />
        <QBtn
          v-if="canRequestMediaItem"
          icon="ion-md-download"
          flat
          :title="$t('inline_player.request_media_item')"
          color="primary"
          class="q-mx-sm"
          @click.stop="$emit('request-media-item', clip)"
        />
        <QSelect
          v-if="$isAdminMode"
          v-model="playbackRate"
          :options="playbackRateOptions"
          :clearable="false"
          :use-input="false"
          option-label="label"
          option-value="value"
          class="q-pb-none q-pr-sm"
          dense
          square
          filled
          emit-value
          map-options
        />
      </div>
    </div>
  </div>
</template>

<script>
import { useMobileStore } from "mobile/src/stores/mobile";
import { storeToRefs } from "pinia";

import { rollbarError } from "shared/boot/rollbar";
import useMediaItemRequest from "shared/composables/useMediaItemRequest";
import {
  formatDate,
  fromCurrentToGivenTimezone,
  shortTimezone,
} from "shared/helpers/date";
import { openSystemBrowser } from "shared/helpers/external";
import { useGlobalStore } from "shared/stores/global";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";
import { useUserStore } from "shared/stores/user";

let uuid = 0;

export default {
  name: "InlinePlayer",
  props: {
    type: {
      type: String,
      required: true,
      validator(value) {
        return ["audio", "video"].includes(value);
      },
    },
    clip: {
      type: Object,
      required: true,
      validator(value) {
        return "media_url" in value && "start_time" in value;
      },
    },
    mention: {
      type: Object,
      default: () => ({}),
    },
    origin: {
      type: Object,
      default: () => ({}),
    },
    aspectRatio: {
      type: Number,
      default: 16 / 9,
    },
    boundaryStartTime: {
      type: Number,
      default: null,
    },
    startTime: {
      type: Number,
      default: null,
    },
    boundaryEndTime: {
      type: Number,
      default: null,
    },
    poster: {
      type: String,
      default: "none",
    },
    downloadLink: {
      type: String,
      default: "",
    },
    timezone: {
      type: String,
      default: "",
    },
    playerParentClass: {
      type: String,
      default: "full-width",
    },
    autoFetchNeighbours: {
      type: Boolean,
    },
    requestMediaItemButton: {
      type: Boolean,
    },
    relativeTime: {
      type: Boolean,
    },
    allowFullscreen: {
      type: Boolean,
    },
    shareButton: {
      type: Boolean,
    },
    hideVideo: {
      type: Boolean,
    },
    showControls: {
      type: Boolean,
    },
    hideButtonBar: {
      type: Boolean,
    },
    autoplay: {
      type: Boolean,
    },
    mediaItemDownload: {
      type: Boolean,
    },
    overlay: {
      type: Boolean,
    },
    hideSlider: {
      type: Boolean,
    },
    showMediaCardLayout: {
      type: Boolean,
    },
    allowNonCurrentAffairsPlayback: {
      type: Boolean,
    },
    hideNavigationButtons: {
      type: Boolean,
    },
  },
  emits: [
    "handle-wrapper-click",
    "request-media-item",
    "media-playing",
    "inlineplayer:play",
    "update-time",
    "change-clip",
    "player-duration",
    "share",
  ],
  setup() {
    const universalPlayerStore = useUniversalPlayerStore();
    const { universalPlayerRef, playerIsPlaying, playerPopoutRef } =
      storeToRefs(universalPlayerStore);

    const { playerShow, playerInsertClip } = universalPlayerStore;

    const userStore = useUserStore();
    const { adminUserEnabled } = storeToRefs(userStore);

    const mobileStore = useMobileStore();
    const { getOrientation } = storeToRefs(mobileStore);

    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    const { openMediaItemRequestModalWithDuplicateCheck } =
      useMediaItemRequest();

    return {
      adminUserEnabled,
      universalPlayerRef,
      playerIsPlaying,
      playerPopoutRef,
      playerShow,
      playerInsertClip,
      getOrientation,
      appConfig,
      openMediaItemRequestModalWithDuplicateCheck,
    };
  },
  data() {
    return {
      loading: false,
      isPlaying: false,
      interval: null,
      currentTime: 0,
      currentTimestamp: 0,
      playedClip: null,
      firstPlay: true,
      cancelSource: null,
      firstClip: null,
      active: false,
      showCurrentAffairsRestrictionMessage: false,
      playbackRate: 1,
      playbackRateOptions: [
        { label: "0.5x", value: 0.5 },
        { label: "1x", value: 1 },
        { label: "1.5x", value: 1.5 },
        { label: "2x", value: 2 },
        { label: "4x", value: 4 },
        { label: "8x", value: 8 },
      ],
    };
  },
  computed: {
    posterProp() {
      if (this.firstPlay) return this.poster;

      return this.$q.platform.is.ios ? "" : "none";
    },
    canRequestMediaItem() {
      return this.clip && this.mediaItemDownload;
    },
    playerRef() {
      return this.$refs.player;
    },
    mediaSource() {
      return this.playedClip.cachedUrl || this.playedClip.media_url;
    },
    clock() {
      if (this.relativeTime) {
        const minutes = Math.floor(this.currentTime / 60);
        const seconds = Math.floor(this.currentTime % 60);

        return `${minutes < 10 ? `0${minutes}` : minutes}:${
          seconds < 10 ? `0${seconds}` : seconds
        }`;
      }

      // eslint-disable-next-line camelcase
      const timezone = this.timezone || this.playedClip.source?.time_zone;
      const timestamp = this.getCurrentTimestamp();

      if (timezone) {
        const isoString = new Date(timestamp).toISOString();
        const timeInTimezone = fromCurrentToGivenTimezone(isoString, timezone);
        const zone = shortTimezone(timeInTimezone, timezone);

        return `${formatDate(timeInTimezone, "h:mm:ssa")} ${zone}`;
      }

      return formatDate(timestamp, "h:mm:ssa");
    },
    hasNextClip() {
      return "next" in this.playedClip;
    },
    hasPreviousClip() {
      return "previous" in this.playedClip;
    },
    isAudio() {
      return this.type !== "video";
    },
    isVideo() {
      return this.type === "video";
    },
    playerHeight() {
      if (this.hideVideo) {
        return 0;
      }

      if (this.isAudio && !this.showMediaCardLayout) {
        return "50";
      }

      return Math.round(this.$refs.htmlParent.offsetWidth / this.aspectRatio);
    },
    isMobileAndLandscape() {
      return this.$isMobile && this.getOrientation === "landscape";
    },
    timeModel: {
      get() {
        return !this.loading && this.playedClip.start_time
          ? this.currentTime
          : 0;
      },
      set(value) {
        this.playerRef.currentTime = value;
        this.currentTime = value;
      },
    },
    clipDuration() {
      let duration = 0.1;

      if (this.playedClip.end_time) {
        duration = this.playedClip.end_time - this.playedClip.start_time;
        const mediaRequestTypes = ["tv_media_request", "radio_media_request"];
        if (mediaRequestTypes.includes(this.playedClip.type)) duration /= 1000;
      }

      return duration;
    },
    clipStep() {
      return this.playedClip ? this.clipDuration / 100 : 0.1;
    },
    restrictAdditionalPlayback() {
      if (this.appConfig.platformName === "CisionOne") return false;

      if (this.isAudio) return false;
      if (this.adminUserEnabled) return false;

      if (
        this.allowNonCurrentAffairsPlayback ||
        (this.playedClip.program_airing &&
          this.playedClip.program_airing.current_affairs)
      ) {
        return false;
      }

      return true;
    },
    overlayPlayBtnSize() {
      return `${30 + (this.playerHeight / 200) * 20}px`;
    },
    canSkipForward() {
      return (
        !this.hideNavigationButtons &&
        !this.firstPlay &&
        (!this.boundaryEndTime ||
          this.playedClip.end_time < this.boundaryEndTime)
      );
    },
    canSkipBackward() {
      return (
        !this.hideNavigationButtons &&
        !this.firstPlay &&
        (!this.boundaryStartTime ||
          this.playedClip.start_time > this.boundaryStartTime)
      );
    },
    canPlay() {
      const currentTimestamp = this.getCurrentTimestamp() / 1000;

      return !this.boundaryEndTime || currentTimestamp < this.boundaryEndTime;
    },
  },
  watch: {
    playerIsPlaying() {
      // pause this player if universal player starts playing
      if (this.playerIsPlaying && this.isPlaying) this.playerRef.pause();
    },
    clip(newClip) {
      this.playedClip = newClip;
      this.firstClip = newClip;
      this.updateClipInformations();

      if (this.isPlaying) {
        this.$nextTick(this.play);
        this.playerRef.playbackRate = this.playbackRate;
      }
    },
    playbackRate(newValue) {
      this.playerRef.playbackRate = newValue;
    },
    autoplay() {
      if (this.startTime) this.updatePlayerStart();

      this.autoplayClip();
    },
  },
  activated() {
    this.active = true;
  },
  deactivated() {
    this.active = false;
    if (this.isVideo || !this.playerOpen) this.playerRef.pause();
    this.isPlaying = false;
  },
  mounted() {
    this.active = true;
    this.playedClip = this.clip;
    this.firstClip = this.clip;

    if (this.startTime) this.updatePlayerStart();

    this.$nextTick().then(() => {
      this.playerRef.playbackRate = this.playbackRate;
      this.setPlayerDurationListener();
    });

    this.autoplayClip();
  },
  unmounted() {
    clearInterval(this.interval);
  },
  created() {
    this.instanceId = uuid.toString();
    uuid += 1;
    this.$emitter.$on("inlineplayer:play", this.pauseOtherPlayers);
  },
  beforeUnmount() {
    this.$emitter.$off("inlineplayer:play", this.pauseOtherPlayers);
  },
  methods: {
    autoplayClip() {
      if (this.clip && this.autoplay) {
        this.firstPlay = false;
        this.updateClipInformations();

        this.$nextTick().then(() => this.togglePlay());
      }
    },
    onVideoClick() {
      if (this.showControls) return;

      this.togglePlay();
    },
    togglePlay() {
      if (!this.active) return;

      if (this.isPlaying) {
        this.pause();
      } else if (this.firstPlay) {
        if (this.isAudio) this.playerRef.volume = 0.5;
        this.loading = true;
        this.play();
        this.loading = false;
      } else {
        if (this.isAudio) this.playerRef.volume = 0.5;
        this.$nextTick(this.play);
      }

      this.updateClocks();
    },
    play() {
      if (this.showCurrentAffairsRestrictionMessage) {
        this.showCurrentAffairsRestrictionMessage = false;

        this.$nextTick().then(() => this.play());

        return;
      }

      if (this.firstPlay) {
        this.firstPlay = false;
        this.$track("Played Clip", { type: this.type });
      }

      this.$emit("media-playing", this.playerRef);
      this.updateClipInformations();

      this.$emitter.$emit("inlineplayer:play", this.instanceId);

      this.playerRef.play().catch((error) => {
        if (error.message.includes("The element has no supported sources")) {
          rollbarError(
            new Error(
              `Error playing clip in InlinePlayer - ${this.mediaSource}`,
              { cause: error }
            )
          );
          this.$error(this.$t("inline_player.play_error"), error);
        }
      });
    },
    pause() {
      this.playerRef.pause();
    },
    played() {
      // pause universal player if its playing
      if (this.playerIsPlaying) this.universalPlayerRef.pause();

      this.isPlaying = true;
      clearInterval(this.interval);
      this.interval = setInterval(this.updateClocks, 500); // eslint-disable-line @typescript-eslint/no-implied-eval
    },
    paused() {
      this.isPlaying = false;
      clearInterval(this.interval);
    },
    resetPlayer() {
      // Reset the player to the begin of the first clip
      this.playerRef.pause();
      this.playerRef.currentTime = 0;
      this.isPlaying = false;

      // If we fetched data, we can't use clip prop because we would lost all fetched data
      this.playedClip = this.firstClip || this.clip;
    },
    showCurrentAffairsMessage() {
      this.pause();
      this.showCurrentAffairsRestrictionMessage = true;
    },
    rewind() {
      // If we should play the previous clip 30 seconds before
      if (this.hasPreviousClip && this.playerRef.currentTime - 30 < 0) {
        this.previousClip();
      } else {
        this.timeBackward(30);
      }

      this.$nextTick().then(() => this.updateClocks());
    },
    fastforward() {
      // If the clip will be finished 30 seconds later
      if (this.playerRef.currentTime + 30 > this.playerRef.duration) {
        if (this.hasNextClip) {
          this.nextClip();
        } else {
          this.resetPlayer();
        }
      } else {
        this.timeForward(30);
      }

      this.$nextTick().then(() => this.updateClocks());
    },
    updateClocks() {
      if (!this.playerRef || !this.isPlaying) {
        return;
      }

      this.currentTime = this.playerRef.currentTime;
      this.currentTimestamp = this.getCurrentTimestamp() / 1000;

      if (
        this.boundaryEndTime &&
        this.currentTimestamp >= this.boundaryEndTime
      ) {
        this.pause();
      } else {
        this.$emit("update-time", this.getCurrentTimestamp());
      }
    },
    timeForward(seconds) {
      this.playerRef.currentTime += seconds;
    },
    timeBackward(seconds) {
      this.playerRef.currentTime -= seconds;
    },
    async nextClip(continuePlaying = false) {
      if (!this.hasNextClip) {
        return;
      }

      if (
        this.restrictAdditionalPlayback &&
        this.playedClip.previous?.id === this.firstClip.id
      ) {
        this.showCurrentAffairsMessage();

        return;
      }

      this.playedClip.next.previous = this.playedClip;
      this.playedClip.next.end_time = this.playedClip.next.start_time + 30;
      this.playedClip = this.playedClip.next;
      this.updateClipInformations();

      // As we change video url, we must replay (there is no autoplay attribute)
      if (this.isPlaying || continuePlaying) {
        await this.$nextTick();
        this.playerRef.play();
        this.$refs.player.playbackRate = this.playbackRate;
      }
    },
    async previousClip() {
      if (!this.hasPreviousClip) {
        return;
      }

      if (
        this.restrictAdditionalPlayback &&
        this.playedClip.next.id === this.firstClip.id
      ) {
        this.showCurrentAffairsMessage();

        return;
      }

      this.playedClip.previous.next = this.playedClip;
      this.playedClip.previous.end_time =
        this.playedClip.previous.start_time + 30;
      this.playedClip = this.playedClip.previous;
      this.updateClipInformations();

      // As we change video url, we must replay (there is no autoplay attribute)
      if (this.isPlaying) {
        await this.$nextTick();
        this.playerRef.play();
        this.$refs.player.playbackRate = this.playbackRate;
      }
    },
    async updateClipInformations(retry = 0) {
      if (!this.autoFetchNeighbours) {
        return;
      }

      if (this.playedClip.fetched) {
        this.$emit("change-clip", this.playedClip);

        return;
      }

      const endpoint = this.$isAdminMode
        ? this.$streemApiAdmin
        : this.$streemApiV1;

      try {
        const response = await endpoint.get(
          `${this.type === "audio" ? "radio" : "tv"}_clips/${
            this.playedClip.id
          }`
        );

        this.playedClip = { ...response.data, ...this.playedClip };
        this.playedClip.type = this.clip.type;
        this.playedClip.fetched = true;

        this.$emit("change-clip", this.playedClip);

        if (this.hasNextClip && !this.playedClip.next.cachedUrl) {
          await this.cacheNextClip();
        }
      } catch (error) {
        // retry with exponential backoff, max 4 attempts.
        if (retry < 4) {
          const retryTimeout = 1000 * 2 ** retry;
          setTimeout(this.updateClipInformation, retryTimeout, retry + 1); // eslint-disable-line @typescript-eslint/no-implied-eval
        }
      }
    },
    fullScreen() {
      this.playerRef[
        this.playerRef.webkitRequestFullscreen
          ? "webkitRequestFullscreen"
          : "webkitEnterFullscreen"
      ]();
    },
    shareMediaItem() {
      this.$emit("share", this.downloadLink);
    },
    goBack() {
      this.$router.go(-1);
    },
    async openRequestMediaItemModal() {
      await this.openMediaItemRequestModalWithDuplicateCheck({
        mention: this.playedClip,
        type: "media_item",
        duplicateCheckMention: this.mention,
        origin: this.origin,
        shouldShowInstantEditButton: false,
      });
    },
    getCurrentTimestamp() {
      return (this.playedClip.start_time + this.currentTime) * 1000;
    },
    updatePlayerStart() {
      this.$nextTick().then(() => {
        let startTime = this.startTime / 1000 - this.clip.start_time;

        if (startTime > this.clip.end_time - this.clip.start_time) {
          const { duration } = this.playerRef;
          startTime =
            Math.min(this.clip.end_time - this.clip.start_time, duration) - 2;
        }

        this.playerRef.currentTime = startTime;
      });
    },
    handleIntersection(el) {
      if (
        !this.$isAdminMode &&
        !el.isIntersecting &&
        this.isPlaying &&
        this.authorizationChecked
      ) {
        this.pause();
      }
    },
    pauseOtherPlayers(instanceId) {
      if (this.instanceId !== instanceId) {
        this.pause();
      }
    },
    ended() {
      if (this.firstPlay) {
        return;
      }

      if (this.hasNextClip) {
        this.nextClip(true);
      } else {
        this.resetPlayer();
      }

      this.$nextTick().then(() => this.updateClocks());
    },
    setPlayerDurationListener() {
      this.playerRef.ondurationchange = () => {
        this.$emit("player-duration", this.playerRef.duration);
      };
    },
    async cacheNextClip() {
      // If there is already a request to cache a clip, cancel it
      if (this.cancelSource) {
        this.cancelSource.cancel();
      }

      this.cancelSource = this.$cacheFetch.cancelToken.source();
      const url = this.playedClip.next.media_url;

      try {
        const response = await this.$cacheFetch.get(url, {
          responseType: "arraybuffer",
          cancelToken: this.cancelSource.token,
          noAuth: true,
        });

        const blob = new Blob([response.data], {
          type: response.headers["content-type"],
        });
        this.playedClip.next.cachedUrl = window.URL.createObjectURL(blob);
      } catch (error) {
        if (!this.$cacheFetch.isCancel(error)) throw error;
      }
    },
    openDownloadLink() {
      this.$track("Inline Player clip download requested", {
        clipId: this.clip.id,
        mediaUrl: this.clip.media_url,
      });
      openSystemBrowser(this.downloadLink);
    },
  },
};
</script>

<style lang="scss" scoped>
.player {
  position: relative;
}

video {
  width: 100%;
  display: block;
  background-color: $hover-background;
  outline: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;

  &.media-card-video {
    object-fit: fill;
  }
}

.button-bar {
  &.tv {
    background-color: $hover-background;
  }

  button {
    color: $primary;
  }
}

:deep(.q-slider) {
  height: 0;
}

.slider {
  padding-top: 5px;
  padding-bottom: 10px;

  :deep(.q-slider__track) {
    background-color: rgb(0 0 0 / 26%);
    border-radius: 0;
  }

  :deep(.q-slider__selection) {
    background-color: $radio;
  }

  &.tv {
    background-color: $hover-background;

    :deep(.q-slider__selection) {
      background-color: $tv;
    }
  }

  :deep(.q-slider__track-container) {
    height: 4px;
    padding: 0;
  }

  :deep(.q-slider__thumb) {
    display: none;
  }

  :deep(.q-slider__focus-ring) {
    height: 0;
  }
}

.timer {
  flex: 1;
}

.current-affairs-restriction {
  position: absolute;
  background-color: rgb(0 0 0 / 85%);
  color: white;
  width: 100%;
}

.overlay {
  opacity: 0.8;
  transition:
    opacity 0.5s,
    filter 0.5s;

  &:hover {
    filter: none;
    opacity: 1;
  }
}

.overlay-play-btn {
  position: absolute;
  z-index: 100000;
  width: auto;
  left: 43%;
}
</style>

import { Attr, BelongsTo, HasOne, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Magazine from "./Magazine";
import Newspaper from "./Newspaper";
import Podcast from "./Podcast";
import RadioStation from "./RadioStation";
import TvChannel from "./TvChannel";
import User from "./User";
import Website from "./Website";

enum Action {
  "create_media_request" = 0,
  "create_transcript_request" = 1,
  "other" = 2,
}

enum Status {
  "pending" = 0,
  "in_progress" = 1,
  "completed" = 2,
  "cancelled" = 3,
}

@Model()
class MentionExpectation extends ApplicationRecord {
  static jsonapiType = "mention_expectations";

  @BelongsTo() user: User;

  @BelongsTo() source:
    | TvChannel
    | RadioStation
    | Website
    | Newspaper
    | Magazine
    | Podcast;

  @HasOne() requestingAccountManager: User;

  @HasOne() handlingAccountManager: User;

  @Attr() action: Action;

  @Attr() comment: string;

  @Attr() sourceType: string;

  @Attr() sourceId: string;

  @Attr() sourceName: string;

  @Attr() targetType: string;

  @Attr() transcriptRequestSource: string;

  @Attr() title: string;

  @Attr() recipients: string[];

  @Attr() contentNotes: string;

  @Attr() contactNotes: string;

  @Attr() keywords: string[];

  @Attr() status: Status;

  @Attr() timeZone: string;

  @Attr() startTime: string;

  @Attr() requestingAccountManagerId: number;

  @Attr() handlingAccountManagerId: number;

  @Attr() userId: number;

  @Attr({ persist: false }) createdAt: string;
}

export default MentionExpectation;

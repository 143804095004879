import { camelCase } from "lodash-es";

import colorTokens from "shared/css/tokens/colors.module.scss";

function getTokensByPrefix(prefix) {
  const tokens = {};

  Object.entries(colorTokens).forEach(([key, value]) => {
    if (key.startsWith(prefix)) {
      tokens[key] = value;
    }
  });

  return tokens;
}

export const lookupColor = (color) =>
  colorTokens[camelCase(color)] || colorTokens[color];

// Color grouping
export const themeColors = [
  colorTokens.primary,
  colorTokens.secondary,
  colorTokens.accent,
];

// Sources
export const sourceColors = {
  magazine: colorTokens.magazine,
  online: colorTokens.online,
  podcast: colorTokens.podcast,
  print: colorTokens.print,
  radio: colorTokens.radio,
  social: colorTokens.social,
  tv: colorTokens.tv,
};

export const sentimentColors = getTokensByPrefix("sentiment-");

// Default Charting Colors
export const chartingColors = [
  "#42AAFF",
  "#EA2E96",
  "#D2ABF7",
  "#FAAE14",
  "#4BA05D",
  "#1D39C3",
  "#FFD6E7",
  "#13C3C3",
  "#B8E6FF",
  "#484C7F",
  "#9254DE",
  "#B9BBCF",
];

export const autoChartingColors = [
  colorTokens["ds-purple-4"],
  colorTokens["ds-cyan-5"],
  colorTokens["daybreak-blue"],
  "#9DF3B0",
  colorTokens["priority-medium"],
  "#F759AB",
  "#FA6F19",
  "#9254DE",
  "#08979C",
  "#55BD6C",
  "#FFE0BD",
  "#FF9143",
  "#FFD6E7",
  "#FF85C0",
  colorTokens["ds-purple-2"],
  "#BAE7FF",
  "#69C0FF",
  "#B5F5EC",
  colorTokens["ds-cyan-4"],

  "#85A5FF",
  "#597EF7",
  "#C3F7CF",
  "#70D687",
  "#FFCCC7",
  colorTokens["ds-red-4"],
];

export const menuColors = getTokensByPrefix("menu-");

export const autoChartColour = (index) =>
  autoChartingColors[index % autoChartingColors.length];

export const trendColors = {
  north_east: "ds-cyan-4",
  east: "warning",
  south_east: "ds-red-5",
};

export default colorTokens;

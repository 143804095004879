import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import InsightsReport from "./InsightsReport";
import type { Chart } from "./types";

@Model()
class InsightsReportChart extends ApplicationRecord {
  static jsonapiType = "insights_report_charts";

  @BelongsTo() insightsReport: InsightsReport;

  @Attr() insightsReportId: number;

  @Attr() chart: Chart;

  @Attr() annotatable: boolean;

  @Attr() position: number;

  @Attr() group: string;
}

export default InsightsReportChart;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import JournalistEnquiry from "./JournalistEnquiry";

@Model()
class JournalistEnquiryTag extends ApplicationRecord {
  static jsonapiType = "journalist_enquiry_tags";

  @BelongsTo() journalistEnquiry: JournalistEnquiry;

  @Attr() label: string;

  @Attr() color: string;

  @Attr() journalistEnquiryId: number;
}

export default JournalistEnquiryTag;

import startOfDay from "date-fns/startOfDay";
import { storeToRefs } from "pinia";
import { computed, ref, toRefs, watch } from "vue";

import { ErrorDialog } from "shared/boot/alert";
import { getLocaleText } from "shared/boot/i18n";
import {
  defaultMentionsFields,
  socialAutoRefreshEnabled,
  streamTypes,
} from "shared/constants";
import { dateToTimestamp, subtractTime } from "shared/helpers/date";
import DateRange from "shared/helpers/DateRange";
import { processMentions } from "shared/helpers/mentions";
import streamMentionsFetchingService from "shared/services/fetching/streamClassic";
import { useGlobalStore } from "shared/stores/global";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useSyndicationStore } from "shared/stores/syndication";
import { useUserStore } from "shared/stores/user";

export const AUTO_REFRESH_MENTIONS_LIMIT = 3;

export default function requestMentions(options = {}) {
  const {
    stream,
    defaultRange = ref(DateRange.lastThirtyDays()),
    fetchingService = ref(streamMentionsFetchingService),
    fetchingOptions = ref({}),
    paginate = ref(false),
    page = ref(0),
    pageSize = ref(10),
    sortBy = ref("timestamp"),
    orderBy = ref("desc"),
    missing = ref("_last"),
    useMilliseconds = ref(true),
    groupTvMentions = ref(true),
    allFields = ref(false),
    fields = ref(defaultMentionsFields),
    transformMentions = ref((mentions) => mentions),
    infiniteScroll = ref(null),
    includeBookmarkSyndication = ref(false),
    allowFutureContent = ref(false),
  } = toRefs(options);

  const { getContentDateCap, getLowestSearcheableDate } =
    storeToRefs(useGlobalStore());

  const defaultRequestOptions = computed(() => ({
    paginate: paginate.value,
    allFields: allFields.value,
    fields: fields.value,
    useMilliseconds: useMilliseconds.value,
    sortingOptions: [
      { sortBy: sortBy.value, orderBy: orderBy.value, missing: missing.value },
    ],
    transformMentions: transformMentions.value,
    ...fetchingOptions.value,
  }));

  const requestFilters = ref({});
  const loading = ref(false);
  const loadingIds = ref(false);
  const loadingError = ref(false);
  const mentions = ref([]);
  const mentionIds = ref([]);
  const totalPages = ref(1);
  const totalMentions = ref(0);
  const queuedLoad = ref(null);
  const receivedLessMentionsThanRequested = ref(false);
  const lastRefreshedRange = ref(null);

  const syndicationStore = useSyndicationStore();

  const { currentUser, organisation } = storeToRefs(useUserStore());
  const { getSyndicationKeysForStream } = storeToRefs(syndicationStore);
  const { clearMentionsCount } = useMentionCountsStore();
  const { resetSyndicationKeys, updateSyndicationKeys } = syndicationStore;

  const rangeLimit = computed(() => {
    const maxDateRangeValue =
      organisation.value.max_date_range || getContentDateCap.value();

    const timestamp =
      maxDateRangeValue.toString().length >= 10
        ? maxDateRangeValue
        : dateToTimestamp(
            startOfDay(subtractTime(new Date(), maxDateRangeValue, "month"))
          );

    const lowestAllowedDate = dateToTimestamp(
      getLowestSearcheableDate.value(new Date())
    );

    return timestamp > lowestAllowedDate ? timestamp : lowestAllowedDate;
  });

  function resetMentionsState(mentionsOptions) {
    mentions.value = [];
    resetSyndicationKeys({ stream: stream.value });

    // We need a timeout because otherwise "stream" watchers in wordCloud and sentiment widget
    // Are called only one time when changing filters (with onlyLastSeenUpdated)
    // Instead of two, one without onlyLastSeenUpdated and one with
    // So they are not updated
    if (stream.value.id && mentionsOptions.clearMentionsCount) {
      setTimeout(
        () => clearMentionsCount({ ...stream.value, resetByGroup: false }),
        100
      );
    }

    // set pagination if enabled
    if (mentionsOptions.paginate) {
      page.value = mentionsOptions.page || 1;
    }
  }

  function getSearchRange(mentionsOptions) {
    const autoRefresh =
      currentUser.value.auto_refresh_streams ||
      (stream.value.type === streamTypes.socialStream &&
        socialAutoRefreshEnabled);

    let lastRefreshedAt = dateToTimestamp(
      (!autoRefresh &&
        !mentionsOptions.forceReload &&
        stream.value.refreshed_at) ||
        new Date()
    );

    // always load the latest bookmarks
    if (stream.value.type === streamTypes.bookmarkStream) {
      lastRefreshedAt = dateToTimestamp(new Date());
    }

    if (mentionsOptions.loadLatestMentions) {
      lastRefreshedAt = dateToTimestamp(new Date());
    }

    const rangeInDays = defaultRange.value.range?.daysBack;

    const rangeFromLastRefresh = defaultRange.value.range
      ? {
          after: dateToTimestamp(
            subtractTime(startOfDay(lastRefreshedAt * 1000), rangeInDays, "day")
          ),
          before: lastRefreshedAt,
        }
      : defaultRange.value;

    const range = {
      ...(requestFilters.value?.range ||
        mentionsOptions.range ||
        rangeFromLastRefresh),
    };

    if (range.after < rangeLimit.value) range.after = rangeLimit.value;

    const currentDate = dateToTimestamp(new Date());
    if (!allowFutureContent.value && range.before > currentDate)
      range.before = currentDate;

    return { after: range.after, before: range.before };
  }

  function setFilters(filters = {}) {
    requestFilters.value = filters;

    return requestFilters.value;
  }

  function getRequestParams(mentionsOptions) {
    const params = { ...mentionsOptions };

    params.range = getSearchRange(mentionsOptions);

    if (params.paginate) {
      const paginateOptions = {
        page: params.page || page.value,
        limit: params.limit || pageSize.value,
      };

      Object.assign(params, { ...paginateOptions });
    }

    if (Object.keys(requestFilters.value).length) {
      params.search = requestFilters.value;
    }

    params.sort_options = params.sortingOptions.map((option) => ({
      sort_by: option.sortBy,
      sort_order: option.orderBy,
      missing: option.missing,
    }));

    const excludedKeys = [
      "fetchingService",
      "defaultRange",
      "transformMentions",
      "sortingOptions",
      "paginate",
    ];

    excludedKeys.forEach((key) => delete params[key]);

    if (stream.value.type === streamTypes.bookmarkStream) {
      params.includeBookmarkSyndication = includeBookmarkSyndication.value;
    }

    return {
      ...params,
      syndicationKeys: getSyndicationKeysForStream.value(stream.value.id),
      timeout: 30000,
    };
  }

  function processAndSetMentions(rawMentions, mentionsOptions, sortOptions) {
    const sortingOptions = {
      sort_options: sortOptions,
      groupTvMentions: groupTvMentions.value,
    };

    const processedMentions = mentionsOptions.transformMentions(
      processMentions(rawMentions, sortingOptions)
    );

    if (
      mentionsOptions.keepAlreadyLoadedMentions &&
      !mentionsOptions.loadLatestMentions
    ) {
      mentions.value.push(...processedMentions);
    } else if (
      mentionsOptions.keepAlreadyLoadedMentions &&
      mentionsOptions.loadLatestMentions
    ) {
      const mentionIdList = mentions.value.map((mention) => mention.id);

      const newMentions = processedMentions.filter(
        (mention) =>
          !mentionIdList.includes(mention.id) &&
          mention.timestamp_milliseconds >
            mentions.value[0].timestamp_milliseconds
      );

      if (newMentions.length) {
        mentions.value.unshift(...newMentions);
        // remove the same number of mentions we're adding so that we avoid
        // Out Of Memory Error in the browser when auto refreshing for a
        // long period of time.
        mentions.value.splice(newMentions.length * -1, newMentions.length);
        setTimeout(() => clearMentionsCount(stream.value), 100);
      }
    } else {
      mentions.value = processedMentions;
    }

    if (!mentionsOptions.paginate) {
      updateSyndicationKeys({
        stream: stream.value,
        mentions: processedMentions,
        options: mentionsOptions,
      });
    }
  }

  async function loadMentionIds(loadOptions = {}) {
    if (!stream.value) return;
    if (loadingIds.value) return;

    loadingIds.value = true;

    const mentionsOptions = { ...defaultRequestOptions.value, ...loadOptions };
    const requestParams = getRequestParams(mentionsOptions);

    try {
      const { data } = await fetchingService.value.getIds({
        stream: stream.value,
        mentions: mentions.value,
        options: requestParams,
      });

      mentionIds.value = [...data];
    } catch (error) {
      ErrorDialog(
        getLocaleText("request_mentions.error_loading_mention_ids"),
        error
      );
    } finally {
      loadingIds.value = false;
    }
  }

  async function loadMentions(loadOptions = {}) {
    if (!stream.value) return;

    if (loading.value) {
      queuedLoad.value = loadOptions;

      return;
    }

    loading.value = true;
    loadingError.value = false;

    const mentionsOptions = { ...defaultRequestOptions.value, ...loadOptions };

    if (
      !mentionsOptions.keepAlreadyLoadedMentions ||
      mentionsOptions.forceReload
    ) {
      resetMentionsState(mentionsOptions);
    }

    const requestParams = getRequestParams(mentionsOptions);
    lastRefreshedRange.value = requestParams.range;

    try {
      const { data, headers } = await fetchingService.value.get({
        stream: stream.value,
        mentions: mentions.value,
        options: requestParams,
      });

      processAndSetMentions(data, mentionsOptions, requestParams.sort_options);

      receivedLessMentionsThanRequested.value =
        data.length < (requestParams.limit || 10);
      totalMentions.value =
        Number(headers["x-total-count"]) || mentions.value.length;
      totalPages.value = Number(headers["x-total-pages"]) || 1;

      if (infiniteScroll.value) {
        if (
          receivedLessMentionsThanRequested.value &&
          !mentionsOptions.loadLatestMentions
        ) {
          infiniteScroll.value.stop();
        } else {
          infiniteScroll.value.resume();
        }
      }
    } catch (error) {
      if (error.code !== "ECONNABORTED") {
        if (infiniteScroll.value) infiniteScroll.value.stop();
        loadingError.value = true;
      }
    } finally {
      loading.value = false;

      if (queuedLoad.value) {
        loadMentions(queuedLoad.value);
        queuedLoad.value = null;
      }
    }
  }

  async function loadPage(pageNumber, loadOptions = {}) {
    if (loading.value) return false;

    await loadMentions({
      page: pageNumber,
      keepAlreadyLoadedMentions: false,
      ...loadOptions,
    });

    return true;
  }

  async function loadPreviousPage(loadOptions = {}) {
    if (totalPages.value > 1 && page.value > 1) {
      page.value -= 1;
      await loadPage(page.value, loadOptions);
    }
  }

  async function loadNextPage(loadOptions = {}) {
    if (totalPages.value > 1 && page.value + 1 <= totalPages.value) {
      page.value += 1;
      await loadPage(page.value, loadOptions);
    }
  }

  async function loadLatestMentions() {
    if (loading.value) return false;

    await loadMentions({
      keepAlreadyLoadedMentions: true,
      loadLatestMentions: true,
      paginate: true,
      limit: AUTO_REFRESH_MENTIONS_LIMIT,
    });

    return true;
  }

  async function refresh(loadOptions = {}) {
    await loadMentions({
      keepAlreadyLoadedMentions: false,
      ...loadOptions,
    });
  }

  watch(
    () => currentUser.value.currency_code,
    () => {
      refresh();
    }
  );

  return {
    loading,
    loadingIds,
    loadingError,
    queuedLoad,
    receivedLessMentionsThanRequested,
    rangeLimit,
    lastRefreshedRange,
    mentions,
    mentionIds,
    page,
    pageSize,
    totalPages,
    totalMentions,
    setFilters,
    loadMentions,
    loadPreviousPage,
    loadNextPage,
    loadPage,
    loadLatestMentions,
    refresh,
    loadMentionIds,
  };
}

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Journalist from "./Journalist";
import JournalistMailingList from "./JournalistMailingList";

@Model()
class JournalistMailingListsJournalist extends ApplicationRecord {
  static jsonapiType = "journalist_mailing_lists_journalists";

  @BelongsTo() journalist: Journalist;

  @BelongsTo() journalistMailingList: JournalistMailingList;

  @Attr() journalistId: number;

  @Attr() journalistMailingListId: number;
}

export default JournalistMailingListsJournalist;

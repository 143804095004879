import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Contact from "./Contact";
import ContactList from "./ContactList";

@Model()
class ContactListsContact extends ApplicationRecord {
  static jsonapiType = "contact_lists_contacts";

  @BelongsTo() contactList: ContactList;

  @BelongsTo() contact: Contact;

  @Attr() contactListId: number;

  @Attr() contactId: number;

  @Attr() contactType: string;

  @Attr() avatarUrl: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default ContactListsContact;

import { Attr, HasMany, HasOne, Model } from "spraypaint";

import { contractTypes } from "shared/constants";
import ApplicationRecord from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import ContractsFeature from "./ContractsFeature";
import Organisation from "./Organisation";
import OrganisationContractService from "./OrganisationContractService";
import User from "./User";

enum ContractType {
  sla = 1,
  additional_daily_email_report = 2,
  additional_transcripts = 3,
  additional_media_item_downloads = 4,
  additional_print_allowance = 5,
  additional_users = 6,
  additional_media_analysis_reporting = 7,
  additional_scope = 8,
  additional_social_monitoring_influencer = 9,
  additional_social_monitoring_engage_only = 10,
  additional_social_monitoring = 11,
  additional_keywords_or_clients = 12,
  additional_service = 13,
}

@Model()
class OrganisationContract extends ApplicationRecord {
  static jsonapiType = "organisation_contracts";

  static SLADescending() {
    return this.order({ contractStart: "desc" }).where({
      contractType: ContractType.sla,
    });
  }

  static activeSLA() {
    return this.order({ contractStart: "desc" }).where({
      contractType: ContractType.sla,
      active: { eq: true },
    });
  }

  static notExpiredSLA() {
    return this.order({ contractStart: "desc" }).where({
      contractType: 1,
      expired: { eq: false },
    });
  }

  static active() {
    return this.where({ active: { eq: true } });
  }

  static notExpired() {
    return this.where({ expired: { eq: false } });
  }

  @HasOne() organisation: Organisation;

  @HasOne() seller: User;

  @HasMany() attachments: ActiveStorageAttachment[];

  @HasMany()
  organisationContractServices: OrganisationContractService[];

  @HasMany() features: ContractsFeature[];

  @Attr() organisationId: number;

  @Attr() sellerId: number;

  @Attr() contractStart: string;

  @Attr() contractExpiry: string;

  @Attr() billingCycle: number;

  @Attr() matchSlaBillingCycle: boolean;

  @Attr() pricePerMonth: number;

  @Attr() taxAmount: number;

  @Attr() priceIncludingTax: number;

  @Attr() tier: number;

  @Attr() tierDescription: string;

  @Attr() copyrightTier: string;

  @Attr() notes: string;

  @Attr() maxUsers: number;

  @Attr() contractType: ContractType;

  @Attr() customerSla: string;

  @Attr() parentId: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) onlineContentRevenueShare: number;

  @Attr() allowRolling: boolean;

  @Attr() rollingContract: boolean;

  @Attr() rollingContractExpiry: string;

  @Attr() billMonthInAdvance: boolean;

  @Attr() reviewed: boolean;

  @Attr() invoiceDescription: string;

  @Attr() maxClients: number;

  @Attr() effectiveExpiryDate: string;

  @Attr() expired: boolean;

  @Attr() excludeFromArr: boolean;

  @Attr() invoiceSeparately: boolean;

  @Attr() externalSource: string;

  isSLA() {
    return this.contractType === contractTypes.SLA;
  }
}

export default OrganisationContract;

import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import User from "./User";
import UserShift from "./UserShift";

@Model()
class Roster extends ApplicationRecord {
  static jsonapiType = "rosters";

  @BelongsTo() user: User;

  @HasMany() userShifts: UserShift[];

  @Attr() date: string;

  @Attr() startTime: string;

  @Attr() endTime: string;

  @Attr() notes: string;
}

export default Roster;

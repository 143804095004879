import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class FacebookPage extends ApplicationRecord {
  static jsonapiType = "facebook_pages";

  @Attr() name: string;

  @Attr() url: string;

  @Attr() username: string;

  @Attr() enabled: boolean;

  @Attr() categoryId: number;

  @Attr() externalId: string;

  @Attr() label: string;

  @Attr() notes: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default FacebookPage;

import { track } from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";

const handleErrors = true;

export default {
  setStream({ id, data }) {
    this.streams = this.streams.map((stream) =>
      stream.id === id ? { ...data } : stream
    );
  },
  putStream({ id, data }) {
    this.streams = this.streams.map((stream) =>
      stream.id === id ? { ...stream, ...data } : stream
    );
  },
  pushStream(data) {
    this.streams = [...this.streams, data];
  },
  filterStream({ id }) {
    this.streams = [...this.streams.filter((stream) => stream.id !== id)];
  },
  setStreamFilters({ streamId, filters }) {
    this.filters[streamId] = filters;
  },
  getStreams() {
    return $streemApiV1.get("streams", { handleErrors }).then((response) => {
      this.streams = response.data;
    });
  },
  getStream(id) {
    return $streemApiV1
      .get(`streams/${id}`, { handleErrors })
      .then((response) => {
        this.setStream({ id, data: response.data });

        return response;
      });
  },
  replaceStreams(data) {
    const hash = {};

    this.streams.forEach((stream) => {
      hash[stream.id] = stream;
    });

    data.forEach((stream) => {
      hash[stream.id] = stream;
    });

    this.streams = Object.values(hash);
  },
  updateStream({ id, params }) {
    track("Updated Stream");

    return $streemApiV1
      .put(`streams/${id}`, { params, handleErrors })
      .then((response) => {
        this.setStream({ id, data: response.data });

        return response;
      });
  },
  // If the position of some stream have changed, then all the other
  // stream positions in the same group too, that's why the getStreams
  updateStreamPosition({ id, newPosition, newUserPosition }) {
    track("Updated Stream position");

    return $streemApiV1
      .put(`streams/${id}`, {
        params: {
          position: newPosition,
          user_position: newUserPosition,
        },
        handleErrors,
      })
      .then(() => {
        this.getStreams();
      });
  },
  updateStreamFilters({ id, filters }) {
    track("Set Stream Filters");

    return $streemApiV1
      .post(`streams/${id}/filters`, { params: { filters }, handleErrors })
      .then((response) => this.setStream({ id, data: response.data }));
  },
  addStream({ params }) {
    track("Created Stream");

    return $streemApiV1
      .post("streams", { params, handleErrors })
      .then((response) => {
        this.pushStream(response.data);

        return response;
      });
  },
  removeStream({ id }) {
    track("Deleted Stream");

    return $streemApiV1.delete(`streams/${id}`, { handleErrors }).then(() => {
      this.filterStream({ id });
    });
  },
  archiveStream({ id }) {
    track("Archived Stream");

    return $streemApiV1
      .put(`streams/${id}/archive`, { handleErrors })
      .then(() => {
        this.filterStream({ id });
      });
  },
};

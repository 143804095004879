<template>
  <div class="autocomplete">
    <QChip
      v-for="(item, index) in modelValue"
      :key="index"
      :color="itemColor(item)"
      :class="{ 'bg-default-tag-color': filterType === 'tags' }"
      class="q-mr-xs q-mb-xs text-white"
      :style="labelStyle(item)"
      removable
      :disable="disabled"
      @remove="removeItem(item)"
    >
      <EllipseContent>
        {{ item.name }}
        <span
          v-if="showLocation(item)"
          class="location"
        >
          {{ item.location }}
        </span>
      </EllipseContent>
    </QChip>
    <div
      :class="{ 'q-mt-xs': $isMobile }"
      class="relative-container"
    >
      <div :class="{ 'filter-container flex items-center': $isMobile }">
        <QIcon
          v-if="placeholder"
          name="ion-search"
          size="21px"
          class="q-ml-sm search-icon"
        />
        <div :class="{ 'flex-1': $isMobile }">
          <InputText
            ref="input"
            v-model="q"
            :placeholder="placeholder"
            :disabled="disabled"
            :class="{ disabled: disabled }"
            autocomplete="off"
            new-design
            @keydown.up.prevent="decrementSearchIndex"
            @keydown.down.prevent="incrementSearchIndex"
            @keydown.enter.prevent="onKeyEnterOrTab"
            @keydown.tab.prevent="onKeyEnterOrTab"
            @blur="onTextBlur"
            @focus="onTextFocus"
          />
        </div>
      </div>
      <a
        v-if="!loading && q.length"
        class="input-close"
        @click="clear"
      >
        <QIcon name="ion-close" />
      </a>
      <div
        v-if="loading"
        class="loading"
      >
        <QSpinner />
      </div>
    </div>
    <div
      v-if="dropdownVisible && !loading"
      :class="{ 'has-items': indexedItems.length || q.length }"
      class="autocomplete-list"
      @mousedown.prevent
    >
      <i18n-t
        v-if="
          !items.length && q.length >= minimumCharacters && shouldApplyToSources
        "
        scope="global"
        tag="div"
        keypath="filters_autocomplete.no_results_found_for"
        class="no-results"
      >
        <template #search>
          <em>{{ q }}</em>
        </template>
      </i18n-t>
      <div
        v-else-if="q.length > 0 && q.length < minimumCharacters"
        class="no-results"
      >
        {{ $t("filters_autocomplete.type_at_least", { minimumCharacters }) }}
      </div>
    </div>
    <div
      v-if="indexedItems.length > 0"
      :class="{ 'has-items': indexedItems.length || q.length }"
      class="autocomplete-list dropdown"
    >
      <QInfiniteScroll
        ref="infiniteScroll"
        class="full-height scroll"
        @load="loadMoreItems"
      >
        <div
          v-for="(item, index) in indexedItems"
          :key="index"
          :class="{ focus: item.focus }"
          class="autocomplete-item"
          @mousedown="preventDropdownClose"
          @mouseup="allowDropdownClose"
          @click="selectItem(item)"
        >
          <span>{{ item.name }}</span>
          <small v-if="shouldApplyToSources">{{ sourceLabel(item) }}</small>
          <div
            v-if="!isSourceGroupMediumLocation(item)"
            class="soft location"
          >
            {{ item.location }}
          </div>
        </div>
      </QInfiniteScroll>
    </div>
  </div>
</template>

<script>
import { debounce, snakeCase } from "lodash-es";
import { storeToRefs } from "pinia";

import { EllipseContent, InputText } from "shared/components/base";
import { streamTypes } from "shared/constants";
import { getSourceByClass } from "shared/helpers/sources";
import { capitalize } from "shared/helpers/string";
import { Author } from "shared/resources";
import { useStreamsStore } from "shared/stores/streams";

const INCLUDE_LOCATION = ["tv_channel", "radio_station"];

export default {
  name: "FiltersAutocomplete",
  components: {
    EllipseContent,
    InputText,
  },
  props: {
    minimumCharacters: {
      type: Number,
      default: 3,
    },
    modelValue: {
      type: Array,
      required: true,
    },
    stream: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
    },
    filterType: {
      type: String,
      required: true,
      validator: (value) =>
        ["author", "sources", "tags", "all_sources"].includes(value),
    },
    media: {
      type: Array,
      default: () => [],
    },
    placeholder: {
      type: String,
      default: "",
    },
    keepExpanded: {
      type: Boolean,
    },
  },
  emits: ["update:modelValue"],
  setup() {
    const streamsStore = useStreamsStore();
    const { mediaBookmarkStreams, socialBookmarkStreams } =
      storeToRefs(streamsStore);

    return {
      mediaBookmarkStreams,
      socialBookmarkStreams,
    };
  },
  data() {
    return {
      loading: false,
      items: [],
      q: "",
      dropdownVisible: false,
      allowDropdownToClose: true,
      itemIndex: 0,
      page: 1,
    };
  },
  computed: {
    bookmarkStreams() {
      return this.stream.type === streamTypes.socialStream ||
        this.stream.social_bookmarks_stream
        ? this.socialBookmarkStreams
        : this.mediaBookmarkStreams;
    },
    modelValueKeys() {
      return this.modelValue.map((item) => `${item.type}-${item.id}`);
    },
    filteredItems() {
      return this.items.filter(
        (item) => !this.modelValueKeys.includes(`${item.type}-${item.id}`)
      );
    },
    indexedItems() {
      return this.filteredItems.map((item, index) => ({
        ...item,
        focus: this.itemIndex === index,
      }));
    },
    shouldApplyToSources() {
      return ["all_sources", "sources"].includes(this.filterType);
    },
  },
  watch: {
    q() {
      if (this.q.length >= this.minimumCharacters) {
        this.items = [];
        this.page = 1;
        this.debouncedSearch();
      } else if (this.items) {
        this.items = [];
        this.itemIndex = 0;
      }
    },
  },
  created() {
    this.debouncedSearch = debounce(this.search, 250);
  },
  methods: {
    async loadMoreItems(index, done) {
      if (this.loading) {
        this.loading = false;
        this.$refs?.infiniteScroll.stop();

        return done();
      }

      try {
        await this.search();
      } finally {
        done();
      }

      return done();
    },
    async search() {
      if (!this.q) return;

      this.itemIndex = 0;
      this.loading = true;
      this.showDropdown();

      if (this.filterType === "author") {
        if (import.meta.env.VITE_AUTHOR_SEARCH === "influencers") {
          const influencers = await Author.where({
            must: {
              match: {
                type: "influencer",
              },
              nested: {
                path: "outlets",
                query: {
                  bool: {
                    must: {
                      terms: {
                        "outlets.monitoringOnlyFlag": false,
                      },
                    },
                  },
                },
              },
              matchPhrase: {
                nameAnalysed: this.q,
              },
            },
          })
            .order({ popularity: "desc" })
            .all();
          this.items = influencers.data.map(({ id, name }) => ({ id, name }));
        } else {
          this.sourceSearch(this.$streemApiV1, "streams/journalists");
        }
      } else if (this.filterType === "tags") {
        const bookmarkStreams = this.bookmarkStreams.filter((bookmark) =>
          bookmark.label.match(RegExp(this.q, "i"))
        );

        if (bookmarkStreams) {
          this.items = bookmarkStreams.map((bookmark) => ({
            id: bookmark.id,
            name: bookmark.label,
            color: bookmark.color,
          }));
        }
      } else if (this.$isAdminMode && !this.stream.id) {
        await this.sourceSearch(this.$streemApiAdmin, "sources", {
          media: this.media,
        });
      } else if (!this.stream.id) {
        await this.sourceSearch(this.$streemApiV1, "sources", {
          media: this.media,
        });
      } else {
        await this.sourceSearch(
          this.$streemApiV1,
          `streams/${this.stream.id}/sources`,
          {
            ...(this.filterType === "all_sources" && { all_sources: true }),
          }
        );
      }

      this.page += 1;
      this.loading = false;
    },
    async sourceSearch(api, endpoint, extraParams) {
      const result = await api.get(endpoint, {
        params: {
          q: this.q,
          page: this.page,
          limit: 50,
          ...extraParams,
        },
      });
      const { data, headers } = result;

      if (this.page >= headers["x-total-pages"]) {
        this.$refs.infiniteScroll?.stop();
      }

      this.items.push(...data);
    },
    itemColor(item) {
      if (this.isSourceGroupMediumLocation(item)) {
        return null;
      }

      const value = item.type || snakeCase(item.target_type);

      return ["author", "tags"].includes(this.filterType)
        ? "online"
        : getSourceByClass(value).field;
    },
    sourceLabel(item) {
      if (this.isSourceGroupMediumLocation(item)) {
        return "Source Group";
      }

      return getSourceByClass(item.type).label;
    },
    showLocation(item) {
      if (this.isSourceGroupMediumLocation(item)) {
        return false;
      }

      const value = item.type || snakeCase(item.target_type);

      if (this.filterType === "author") return false;

      return INCLUDE_LOCATION.includes(value);
    },
    selectItem(item) {
      this.$emit("update:modelValue", [...this.modelValue, { ...item }]);

      if (!this.keepExpanded) {
        this.hideDropdown();
      }
    },
    removeItem(item) {
      const itemIndex = this.modelValue.indexOf(item);
      const newValue = [...this.modelValue];
      newValue.splice(itemIndex, 1);
      this.$emit("update:modelValue", newValue);
    },
    clear() {
      this.hideDropdown();
    },
    hideDropdown() {
      this.dropdownVisible = false;
      this.itemIndex = 0;
      this.page = 1;
      this.q = "";
    },
    showDropdown() {
      this.dropdownVisible = true;
    },
    onTextBlur() {
      if (!this.allowDropdownToClose) return;

      this.hideDropdown();
    },
    onTextFocus() {
      this.showDropdown();
    },
    decrementSearchIndex() {
      if (this.itemIndex - 1 >= 0) {
        this.itemIndex -= 1;
        this.scrollOptionIntoView();
      }
    },
    incrementSearchIndex() {
      if (this.itemIndex + 1 < this.filteredItems.length) {
        this.itemIndex += 1;
        this.scrollOptionIntoView();
      }
    },
    scrollOptionIntoView() {
      this.$nextTick(() => {
        const el = this.$refs[`item-${this.itemIndex}`][0];

        if (!el) return;

        if (el.scrollIntoViewIfNeeded !== undefined) {
          el.scrollIntoViewIfNeeded(false);
        } else if (el.scrollIntoView !== undefined) {
          el.scrollIntoView({ block: "nearest" });
        }
      });
    },
    onKeyEnterOrTab() {
      let item = {};

      if (this.itemIndex >= 0) {
        item = this.filteredItems[this.itemIndex];
      }

      if (this.itemIndex === -1 && this.filterType === "author") {
        item.name = capitalize(this.q);
      }

      if (!item.name) return;
      this.selectItem(item);
      this.showDropdown();
    },
    labelStyle(item) {
      if (this.filterType !== "tags") return {};

      if (item.color) {
        return { background: item.color };
      }

      return {};
    },
    preventDropdownClose() {
      this.allowDropdownToClose = false;
    },
    allowDropdownClose() {
      this.allowDropdownToClose = true;
    },
    isSourceGroupMediumLocation(item) {
      return item.type === "source_group_medium_location";
    },
  },
};
</script>

<style lang="scss" scoped>
.search-icon {
  color: #9b9b9b;
}

.filter-container {
  border-bottom: 1px solid #ddd;
  background-color: #eee;
}

.autocomplete {
  position: relative;

  .relative-container {
    position: relative;

    .loading {
      position: absolute;
      right: 10px;
      top: 8px;
    }

    .input-close {
      position: absolute;
      display: block;
      top: 0;
      right: 2px;
      padding: 9px 11px 11px 9px;
      cursor: pointer;
    }
  }

  :deep(.q-chip) {
    cursor: pointer;
    padding-right: 20px;

    .q-chip__content {
      display: block;
      margin-right: 5px;
    }

    .location {
      font-size: 80%;
    }
  }
}

.dropdown {
  height: 300px;
  position: relative;
}

.autocomplete-list {
  width: 100%;
  background: #fff;
  position: absolute;
  z-index: 9999;
  max-height: 300px;

  &.has-items {
    border-left: 1px solid $hover-background;
    border-right: 1px solid $hover-background;
    border-bottom: 1px solid $hover-background;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
  }

  .no-results {
    padding: 10px;
  }

  .autocomplete-item {
    padding: 10px;
    cursor: pointer;
    user-select: none;

    .location {
      font-size: 80%;
    }

    small {
      float: right;
    }

    &:hover,
    &.focus {
      background: #f3f3f3;
    }
  }
}
</style>

import { Attr, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Journalist from "./Journalist";

@Model()
class JournalistTopic extends ApplicationRecord {
  static jsonapiType = "journalist_topics";

  @HasMany() journalists: Journalist[];

  @Attr() name: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default JournalistTopic;

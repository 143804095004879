import { Attr, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import ViewedItem from "./ViewedItem";

@Model()
class FeatureTeaser extends ApplicationRecord {
  static jsonapiType = "feature_teasers";

  @Attr() featureName: string;

  @Attr() featureId: number;

  @HasMany({ name: "target" }) viewedItems: ViewedItem[];

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default FeatureTeaser;

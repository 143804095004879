import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";
import Version from "./Version";

@Model()
class DistributionList extends ApplicationRecord {
  static jsonapiType = "distribution_lists";

  @BelongsTo() organisation: Organisation;

  @HasMany() versions: Version;

  @Attr() name: string;

  @Attr() recipients: string[];

  @Attr() organisationId: number;

  @Attr() restricted: boolean;

  @Attr({ persist: false }) referenceEmail: string;
}

export default DistributionList;

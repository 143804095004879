import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";
import OutreachCampaignRecipient from "./OutreachCampaignRecipient";
import OutreachRecipientListRecipient from "./OutreachRecipientListRecipient";
import User from "./User";

@Model()
class OutreachRecipientList extends ApplicationRecord {
  static jsonapiType = "outreach_recipient_lists";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() user: User;

  @Attr() name: string;

  @Attr() organisationId: number;

  @Attr() userId: number;

  @Attr() private: boolean;

  @Attr() recipientsCount: number;

  @HasMany()
  outreachRecipientListRecipients: OutreachRecipientListRecipient[];

  @HasMany() outreachCampaignRecipients: OutreachCampaignRecipient[];

  @HasMany() recipients: OutreachRecipientListRecipient[];
}

export default OutreachRecipientList;

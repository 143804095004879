import { sortBy } from "lodash-es";

import { streamTypes } from "shared/constants";
import features from "shared/services/features";
import { useUserStore } from "shared/stores/user";

function sortGroups(groups, userId) {
  return sortBy(groups, [
    (group) => (group.locked ? 0 : 1),
    (group) => group.user_id !== userId,
    "position",
  ]);
}

export default {
  getStreamGroupsByStreamType(state) {
    return (type, product = "media") => {
      if (!features.has("has_team_workspaces")) {
        return state.streamGroups.filter(
          (streamGroup) => streamGroup.stream_type === type
        );
      }

      const currentOrganisationTeamId =
        useUserStore().preferences[`selected_team_${product}`];

      return state.streamGroups.filter(
        (streamGroup) =>
          streamGroup.stream_type === type &&
          (streamGroup.organisation_team_id === currentOrganisationTeamId ||
            !streamGroup.organisation_team_id)
      );
    };
  },
  mentionStreamGroups() {
    return (product = "media") => {
      const userId = useUserStore().currentUser.id;
      const sortedBriefGroups = sortGroups(
        this.getStreamGroupsByStreamType(
          streamTypes.organisationBrief,
          product
        ),
        userId
      );

      return [
        ...sortedBriefGroups,
        ...this.traditionalStreamGroups(product),
        ...this.traditionalBookmarkGroups(),
      ];
    };
  },
  traditionalStreamGroups() {
    return (product = "media") => {
      const userId = useUserStore().currentUser.id;

      return sortGroups(
        this.getStreamGroupsByStreamType(streamTypes.mentionStream, product),
        userId
      );
    };
  },
  traditionalStreamGroupsWithoutLock() {
    return this.traditionalStreamGroups().filter(
      (streamGroup) => !streamGroup.locked
    );
  },
  socialStreamGroups() {
    return (product = "social") => [
      ...this.socialOwnedStreamGroups(product),
      ...this.socialBookmarkGroups(),
    ];
  },
  socialOwnedStreamGroups() {
    return (product = "social") => {
      const userId = useUserStore().currentUser.id;

      return sortGroups(
        this.getStreamGroupsByStreamType(streamTypes.socialStream, product),
        userId
      );
    };
  },
  traditionalBookmarkGroups() {
    const streamGroups = this.getStreamGroupsByStreamType(
      streamTypes.bookmarkStream,
      "media"
    );
    const filteredGroups = streamGroups.filter(
      (streamGroup) => !streamGroup.social_bookmarks_stream
    );

    return () => sortGroups(filteredGroups, useUserStore().currentUser.id);
  },
  socialBookmarkGroups() {
    const streamGroups = this.getStreamGroupsByStreamType(
      streamTypes.bookmarkStream,
      "social"
    );
    const filteredGroups = streamGroups.filter(
      (streamGroup) => streamGroup.social_bookmarks_stream === true
    );

    return () => sortGroups(filteredGroups, useUserStore().currentUser.id);
  },
  mentionStreamGroupsWithoutLocked() {
    return this.mentionStreamGroups("media").filter(
      (streamGroup) => !streamGroup.locked
    );
  },
  socialStreamGroupsWithoutLocked() {
    return this.socialStreamGroups("social").filter(
      (streamGroup) => !streamGroup.locked
    );
  },
  socialOwnedStreamGroupsWithoutLocked() {
    return this.socialOwnedStreamGroups("social").filter(
      (streamGroup) => !streamGroup.locked
    );
  },
  traditionalBookmarkGroupsWithoutLocked() {
    return this.traditionalBookmarkGroups().filter(
      (streamGroup) => !streamGroup.locked
    );
  },
  socialBookmarkGroupsWithoutLocked() {
    return this.socialBookmarkGroups().filter(
      (streamGroup) => !streamGroup.locked
    );
  },
  nextMentionStreamGroupPosition() {
    return (
      (this.mentionStreamGroups().reduce(
        (acc, current) => Math.max(acc, current.position),
        0
      ) || 0) + 1
    );
  },
  nextSocialStreamGroupPosition() {
    return (
      (this.socialStreamGroups().reduce(
        (acc, current) => Math.max(acc, current.position),
        0
      ) || 0) + 1
    );
  },
  nextTraditionalBookmarkGroupPosition() {
    return (
      (this.traditionalBookmarkGroups().reduce(
        (acc, current) => Math.max(acc, current.position),
        0
      ) || 0) + 1
    );
  },
  nextSocialBookmarkGroupPosition() {
    return (
      (this.socialBookmarkGroups().reduce(
        (acc, current) => Math.max(acc, current.position),
        0
      ) || 0) + 1
    );
  },
  getStreamGroupBySlug() {
    return (slug) =>
      this.streamGroups.find((streamGroup) => streamGroup.slug === slug);
  },
  getStreamGroupByGroupId() {
    return (groupId) =>
      this.streamGroups.find((streamGroup) => streamGroup.id === groupId);
  },
  mediaTeamMentionStreamGroups() {
    const currentOrganisationTeamId =
      useUserStore().preferences.selected_team_media;

    return this.streamGroups.filter(
      (streamGroup) =>
        streamGroup.stream_type === streamTypes.mentionStream &&
        streamGroup.organisation_team_id === currentOrganisationTeamId
    );
  },
};

import { $streemApiV1 } from "shared/boot/api";
import { getRange } from "shared/helpers/date";
import { getMediaForStream, getTypesForMedia } from "shared/helpers/sources";

export default {
  get({ stream, mentions, options = { range: getRange(365) } }) {
    const { search, types, excludedMedia } = options;
    const range = { ...options.range };
    const endpoint = options.endpoint || "search";

    let timestampProp = "timestamp";

    // convert timestamps to milliseconds if required
    if (options.useMilliseconds) {
      timestampProp = "timestamp_milliseconds";
      range.before *= 1000;
      range.after *= 1000;
    }

    // exclude media
    let media = getMediaForStream(stream);

    if (excludedMedia) {
      media = media.filter((medium) => !excludedMedia.includes(medium));
    }

    // return no results if no media selected
    if (!media.length && !options.skipMediaValidation) {
      return Promise.resolve({ data: [], headers: { "x-total-count": 0 } });
    }

    // override types
    let searchTypes = getTypesForMedia(media);

    if (types) {
      searchTypes = types;
    }

    // send journalist search query as is without parsing
    const query =
      endpoint === "search/journalists"
        ? stream.keywords
        : this.parseQuery(stream.keywords);

    const requestOptions = {
      params: {
        query,
        after: range.after,
        before: options.keepAlreadyLoadedMentions
          ? mentions.slice(-1)[0][timestampProp]
          : range.before,
        collapse_syndicated: true,
        excerpt_radius: 150,
        limit: 10,
        media,
        types: searchTypes,
        ...(options.page && { page: options.page }),
        ...(options.syndicationKeys && {
          excluded_syndication_keys: options.syndicationKeys,
        }),
        ...(options.useMilliseconds && {
          timestamps_in_milliseconds: options.useMilliseconds,
        }),
        ...(options.stream_ids && { stream_ids: options.stream_ids }),
        ...(stream.filters && {
          category_ids: stream.filters.map(({ filter }) => filter.id),
        }),
        ...(search && search.media ? { media: search.media } : {}),
        ...(options.source_keys && { source_keys: options.source_keys }),
        ...(options.keyword && { keyword: options.keyword }),
      },
    };

    return $streemApiV1.post(endpoint, requestOptions);
  },

  parseQuery(query) {
    const regexp = "(AND NOT|AND|OR|NOT|UNLESS|NEAR/|ONEAR/|FIRST/|ATLEAST/)";

    if (query.match(new RegExp(regexp))) {
      return query;
    }

    const keywords = [];

    query
      .replace(/"/g, "")
      .split(/,(?!(\d))/)
      .forEach((word) => {
        if (word) keywords.push(`"${word.trim()}"`);
      });

    return keywords.join(",");
  },
};

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord, {
  objectDirtyChecker,
} from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class ContentLicense extends ApplicationRecord {
  static jsonapiType = "content_licenses";

  @BelongsTo() organisation: Organisation;

  @Attr() externalSource: string;

  @Attr() externalSourceId: string;

  @Attr() licenseType: string;

  @Attr({ dirtyChecker: objectDirtyChecker }) access: string[];

  @Attr() externalOrgId: string;

  @Attr() externalOrgName: string;

  @Attr() isAllowed: boolean;

  @Attr() excludeFromReporting: boolean;

  @Attr() agencyContact: string;

  @Attr() serviceStatus: string;

  @Attr() serviceStartDate: string;

  @Attr() serviceEndDate: string;
}

export default ContentLicense;

import { type Store } from "pinia";
import { MiddlewareStack, Model, SpraypaintBase } from "spraypaint";
import { type Router } from "vue-router";

import { adminBaseUrl, isAdminMode, v1BaseUrl } from "shared/constants";
import {
  connectionError,
  forbiddenError,
  unauthorizedError,
} from "shared/helpers/errors";
import StorageService from "shared/services/StorageService";

@Model()
class ApplicationRecord extends SpraypaintBase {
  static baseUrl = isAdminMode ? adminBaseUrl : v1BaseUrl;

  static apiNamespace = "";

  async saveResource(...args: any[]): Promise<any> {
    const success = await this.save(...args);

    if (!success) {
      const message = Object.values(this.errors)
        .map((error) => `${error?.title}: ${error?.fullMessage}`)
        .join("\n");

      const error = new Error("Spraypaint Validation Error");
      error.message = message;
      throw error;
    }

    return success;
  }

  async destroyResource(): Promise<any> {
    const success = await this.destroy();

    if (!success) {
      const message = Object.values(this.errors)
        .map((error) => `${error?.title}: ${error?.fullMessage}`)
        .join("\n");

      const error = new Error("Spraypaint Validation Error");
      error.message = message;

      throw error;
    }

    return success;
  }

  dupWithDirtyTracking(): any {
    const dup = this.dup();

    // dup() on graphiti resource resets dirty tracking; which breaks saving
    // this method will retain the dirty states
    // @ts-ignore
    dup._originalAttributes = { ...this._originalAttributes }; // eslint-disable-line no-underscore-dangle

    return dup;
  }

  resetAttribute(attribute: string) {
    // @ts-ignore
    this._originalAttributes[attribute] = this._attributes[attribute]; // eslint-disable-line no-underscore-dangle
  }

  async saveAttributes(attributes: any, ...args: any[]) {
    const dup = this.dupWithDirtyTracking();
    dup.rollback();

    attributes.forEach((attribute: any) => {
      // @ts-ignore
      dup[attribute] = this[attribute];
    });

    await dup.saveResource(...args);

    attributes.forEach((attribute: any) => {
      // @ts-ignore
      this[attribute] = dup[attribute];
      this.resetAttribute(attribute);
    });
  }

  saveAssociation(association: any): any {
    return this.saveAttributes([association], { with: association });
  }

  markAttributeAsDirty(attribute: any) {
    // @ts-ignore
    this._originalAttributes[attribute] = null; // eslint-disable-line no-underscore-dangle
  }
}

const middleware = new MiddlewareStack();

middleware.beforeFilters.push(async (_, options) => {
  const token = await StorageService.get("token");
  // @ts-ignore
  Object.assign(options.headers, { Authorization: `Bearer ${token}` });
});

ApplicationRecord.middlewareStack = middleware;

export function initializeSpraypaintMiddleware({
  router,
  store,
  userStore,
}: {
  router: Router;
  store: Store;
  userStore: Store;
}) {
  middleware.afterFilters.push(async (response, json: any) => {
    if (response.status === 422 && json?.errors) return;

    if (
      !json ||
      json?.errors?.length ||
      json.error === true ||
      !response.ok ||
      !response.status
    ) {
      const error = new Error("abort") as any;
      error.response = Object.assign(response, { data: json });

      if (typeof json.error === "string") {
        error.response.data.message = json.error;
      }

      switch (response.status) {
        case null:
        case undefined:
          connectionError({ error, router, store });
          break;
        case 401:
          unauthorizedError({ error, router, store, userStore });
          break;
        case 403:
          forbiddenError({ error });
          break;
        default:
      }

      throw error;
    }
  });
}

export function objectDirtyChecker(oldValue: any, newValue: any) {
  return JSON.stringify(oldValue) !== JSON.stringify(newValue);
}

export default ApplicationRecord;

import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import ContactListsContact from "./ContactListsContact";
import CoreVersion from "./CoreVersion";
import Organisation from "./Organisation";
import User from "./User";

enum ExternalType {
  "mygorkana" = "mygorkana",
  "outreach_v1" = "outreach_v1",
  "ngc3" = "ngc3",
  "c3" = "c3",
}

@Model()
class ContactList extends ApplicationRecord {
  static jsonapiType = "contact_lists";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() user: User;

  @BelongsTo() updatedByUser: User;

  @HasMany() contactListsContacts: ContactListsContact[];

  @HasMany({ name: "taggable" }) tags: any[];

  @Attr() name: string;

  @Attr() description: string;

  @Attr() organisationId: number;

  @Attr() organisationTeamId: number;

  @Attr() userId: number;

  @Attr() private: boolean;

  @Attr() externalId: string;

  @Attr() externalType: ExternalType;

  @Attr({ persist: false }) contactListsContactsCount: number;

  @Attr({ persist: false }) mediaReleasesContactListsCount: number;

  @Attr({ persist: false }) pitchesContactListsCount: number;

  @Attr() updatedByUserId: number;

  @Attr() latestVersion: CoreVersion;

  @Attr() archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default ContactList;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import OrganisationReportSpec from "./OrganisationReportSpec";

@Model()
class OrganisationReportSpecProduct extends ApplicationRecord {
  static jsonapiType = "organisation_report_spec_products";

  @BelongsTo() organisationReportSpec: OrganisationReportSpec;

  @Attr() product: string;
}

export default OrganisationReportSpecProduct;

import { keyBy, sortBy } from "lodash-es";

import { streamTypes } from "shared/constants";
import { has } from "shared/helpers/object";
import features from "shared/services/features";
import { useStreamGroupsStore } from "shared/stores/streamGroups";
import { useUserStore } from "shared/stores/user";

function sortStreams(streams, groups) {
  const keyedGroups = keyBy(groups, "id");

  return sortBy(streams, [
    (stream) => (keyedGroups[stream.group_id]?.locked ? 0 : 1),
    (stream) => (stream.primary ? 0 : 1),
    (stream) => keyedGroups[stream.group_id]?.position,
    (stream) => (stream.locked ? 0 : 1),
    "position",
  ]);
}

function groupStreams(streams, groups, menuGroups = false) {
  const sortedGroups = groups.map((group) => ({ ...group, streams: [] }));

  const groupsLookup = {};
  sortedGroups.forEach((group, index) => {
    groupsLookup[group.id] = index;
  });

  const primaryStreamGroup = {
    label: "Primary Mention Stream",
    color: "",
    streams: [],
  };

  streams.forEach((stream) => {
    if (!menuGroups && stream.primary) {
      primaryStreamGroup.streams.push(stream);
    } else if (sortedGroups[groupsLookup[stream.group_id]]) {
      sortedGroups[groupsLookup[stream.group_id]].streams.push(stream);
    }
  });

  if (!menuGroups) sortedGroups.unshift(primaryStreamGroup);

  return sortedGroups;
}

export default {
  getStreamsByType(state) {
    return (type) => state.streams.filter((stream) => stream.type === type);
  },
  getSharedStreamsByType() {
    return (type) =>
      this.getStreamsByType(type).filter(
        (stream) =>
          stream.type === type &&
          stream.shared &&
          !stream.primary &&
          stream?.group?.user_id !== useUserStore().currentUser.id
      );
  },
  mentionStreams() {
    const mentionStreams = this.getStreamsByType(streamTypes.mentionStream)
      .concat(this.getStreamsByType(streamTypes.organisationBrief))
      .filter((stream) => has(stream, "primary"));

    if (!features.has("has_team_workspaces")) {
      return () => mentionStreams;
    }

    return (product = "media") =>
      mentionStreams.filter((stream) =>
        useStreamGroupsStore()
          .mentionStreamGroups(product)
          .some(
            (streamGroup) =>
              streamGroup.id === stream.group_id || !stream.group_id
          )
      );
  },
  managedBriefs() {
    return this.mentionStreams().filter((stream) => stream.locked);
  },
  bookmarkStreams() {
    return this.getStreamsByType(streamTypes.bookmarkStream);
  },
  mediaBookmarkStreams() {
    if (!features.has("has_team_workspaces")) {
      return this.bookmarkStreams
        .filter((stream) => !stream.social_bookmarks_stream)
        .slice()
        .sort((streamA, streamB) => (streamA.label > streamB.label ? 1 : -1));
    }

    const currentOrganisationTeamId =
      useUserStore().preferences.selected_team_media;

    return this.bookmarkStreams
      .filter(
        (stream) =>
          !stream.social_bookmarks_stream &&
          (stream.organisation_team_id === currentOrganisationTeamId ||
            !stream.organisation_team_id)
      )
      .slice()
      .sort((streamA, streamB) => (streamA.label > streamB.label ? 1 : -1));
  },
  socialBookmarkStreams() {
    if (!features.has("has_team_workspaces")) {
      return this.bookmarkStreams
        .filter((stream) => stream.social_bookmarks_stream)
        .slice()
        .sort((streamA, streamB) => (streamA.label > streamB.label ? 1 : -1));
    }

    const currentOrganisationTeamId =
      useUserStore().preferences.selected_team_social;

    return this.bookmarkStreams
      .filter(
        (stream) =>
          stream.social_bookmarks_stream &&
          (stream.organisation_team_id === currentOrganisationTeamId ||
            !stream.organisation_team_id)
      )
      .slice()
      .sort((streamA, streamB) => (streamA.label > streamB.label ? 1 : -1));
  },
  socialStreams() {
    const socialStreams = this.getStreamsByType(streamTypes.socialStream);

    if (!features.has("has_team_workspaces")) {
      return () => socialStreams;
    }

    return (product = "social") =>
      socialStreams.filter((stream) =>
        useStreamGroupsStore()
          .socialStreamGroups(product)
          .some(
            (streamGroup) =>
              streamGroup.id === stream.group_id || !stream.group_id
          )
      );
  },
  reportingStreamsDashboardEnabled() {
    const streams = this.getStreamsByType(streamTypes.organisationBrief)
      .concat(this.getStreamsByType(streamTypes.mentionStream))
      .filter((stream) => stream.dashboard_enabled);

    const teamId = useUserStore().currentUser.primary_organisation_team_id;

    if (teamId) {
      return streams.filter(
        (stream) => !stream.team_ids || stream.team_ids.includes(teamId)
      );
    }

    return streams;
  },
  dashboardEnabledUserAccessibleStreams() {
    const user = useUserStore().currentUser;

    const streams = this.mentionStreams().filter(
      (stream) => stream.dashboard_enabled
    );

    if (user.organisation_team_id) {
      const teamId = user.organisation_team_id;

      return streams.filter(
        (stream) => !stream.team_ids || stream.team_ids.includes(teamId)
      );
    }

    return streams;
  },
  reportingStreamsReportEnabled() {
    const streams = this.getStreamsByType(streamTypes.organisationBrief)
      .concat(this.getStreamsByType(streamTypes.mentionStream))
      .filter((stream) => stream.report_enabled);

    const teamId = useUserStore().currentUser.primary_organisation_team_id;

    if (teamId) {
      return streams.filter(
        (stream) => !stream.team_ids || stream.team_ids.includes(teamId)
      );
    }

    return streams.sort(
      (streamA, streamB) =>
        streamA.group.position - streamB.group.position ||
        streamA.position - streamB.position
    );
  },
  primaryStreams() {
    return this.mentionStreams().filter((stream) => stream.primary);
  },
  sharedMentionStreams() {
    return this.mentionStreams().filter(
      (stream) =>
        stream.shared &&
        !stream.primary &&
        stream.user_id !== useUserStore().currentUser.id
    );
  },
  sharedSocialStreams() {
    return this.socialStreams().filter(
      (stream) =>
        stream.shared &&
        !stream.primary &&
        stream.user_id !== useUserStore().currentUser.id
    );
  },
  getVisibleStreamById() {
    return (id) =>
      [...this.sortedTraditionalStreams, ...this.sortedSocialStreams].find(
        (stream) => stream.id === id
      );
  },
  getStreamById() {
    return (id) => this.streams.find((stream) => stream.id === id);
  },
  getStreamBySlug() {
    return (slug) => this.streams.find((stream) => stream.slug === slug);
  },
  getStreamsByGroupId() {
    return (groupId) =>
      this.streams.filter(
        (stream) => stream.group_id === groupId && !stream.primary
      );
  },
  sortedTraditionalStreams() {
    const sortedMentionStreams = sortStreams(
      this.mentionStreams(),
      useStreamGroupsStore().mentionStreamGroups()
    );

    return [...sortedMentionStreams, ...this.mediaBookmarkStreams];
  },
  groupedSocialBookmarkStreams() {
    return () =>
      groupStreams(
        this.sortedSocialStreams,
        useStreamGroupsStore().getStreamGroupsByStreamType(
          streamTypes.bookmarkStream,
          "social"
        ),
        false
      );
  },
  groupedMentionStreams() {
    return ({ menuGroups = false } = {}) =>
      groupStreams(
        this.getStreamsByType(streamTypes.mentionStream).concat(
          this.getStreamsByType(streamTypes.organisationBrief)
        ),
        useStreamGroupsStore().mentionStreamGroups(),
        menuGroups
      ).filter((group) => group.streams.length);
  },
  groupedTraditionalStreams() {
    return ({ menuGroups = false, product = "media" } = {}) =>
      groupStreams(
        this.sortedTraditionalStreams,
        useStreamGroupsStore().mentionStreamGroups(product),
        menuGroups
      );
  },
  groupedBookmarkStreams() {
    return ({ menuGroups = false } = {}) =>
      groupStreams(
        this.getStreamsByType(streamTypes.bookmarkStream),
        useStreamGroupsStore().mentionStreamGroups(),
        menuGroups
      ).filter(
        (group) =>
          group.streams.length &&
          group.stream_type === streamTypes.bookmarkStream
      );
  },
  sortedSocialStreams() {
    const sortedMentionStreams = sortStreams(
      this.socialStreams(),
      useStreamGroupsStore().socialStreamGroups()
    );

    return [...sortedMentionStreams, ...this.socialBookmarkStreams];
  },
  groupedSocialStreams() {
    return ({ menuGroups = false } = {}) =>
      groupStreams(
        this.sortedSocialStreams,
        useStreamGroupsStore().socialStreamGroups(),
        menuGroups
      );
  },
  getStreamFilters() {
    return (streamId) => this.filters[streamId];
  },
  mediaTeamMentionStreamsCount() {
    const currentOrganisationTeamId =
      useUserStore().preferences.selected_team_media;

    const bookmarkStreamsCount = this.bookmarkStreams.filter(
      (stream) =>
        !stream.social_bookmarks_stream &&
        stream.organisation_team_id === currentOrganisationTeamId
    ).length;

    const mentionStreamsCount = this.getStreamsByType(
      streamTypes.mentionStream
    ).filter((stream) =>
      useStreamGroupsStore().mediaTeamMentionStreamGroups.some(
        (streamGroup) => streamGroup.id === stream.group_id
      )
    ).length;

    return mentionStreamsCount + bookmarkStreamsCount;
  },
};

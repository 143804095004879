import { Attr, HasMany, HasOne, Model } from "spraypaint";

import { instantInsightsPlusPendingStatuses } from "shared/constants";
import ApplicationRecord from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import DistributionList from "./DistributionList";
import EmailTracking from "./EmailTracking";
import InsightsQuery from "./InsightsQuery";
import InsightsReportChart from "./InsightsReportChart";
import InsightsReportGroup from "./InsightsReportGroup";
import InsightsReportKeyInsight from "./InsightsReportKeyInsight";
import Organisation from "./Organisation";
import ReportFile from "./ReportFile";

type ReportType = "qualitative" | "quantitative" | "insights_plus";

@Model()
class InsightsReport extends ApplicationRecord {
  static jsonapiType = "insights_reports";

  @HasMany() activeStorageAttachments: ActiveStorageAttachment[];

  @HasMany() insightsQueries: InsightsQuery[];

  @HasMany() insightsReportCharts: InsightsReportChart[];

  @HasMany() insightsReportKeyInsights: InsightsReportKeyInsight[];

  @HasMany() reportFiles: ReportFile[];

  @HasOne() reportFile: ReportFile;

  @HasOne() distributionList: DistributionList;

  @HasOne() emailTracking: EmailTracking;

  @HasOne() organisation: Organisation;

  @HasOne() group: InsightsReportGroup;

  @Attr() after: number;

  @Attr() archivedAt: string;

  @Attr() articleRankingSourceIds: number[];

  @Attr() before: number;

  @Attr() billable: boolean;

  @Attr() brandProfileId: number;

  @Attr() bundleBroadcast: boolean;

  @Attr({ persist: false }) queriesStatusCount: string;

  @Attr({ persist: false }) processingErrors: string;

  @Attr() dateInterval: string;

  @Attr() distributionListId: number;

  @Attr({ persist: false }) exportedAt: string;

  @Attr() includeSyndication: boolean;

  @Attr() ignoreMentionExclusions: boolean;

  @Attr() ignoreNoTopicMentions: boolean;

  @Attr() includeExternalLinks: boolean;

  @Attr() label: string;

  @Attr() mentionsCsvUrl: string;

  @Attr() mentionsAggregationCsvUrl: string;

  @Attr() multipleTopics: boolean;

  @Attr() socialCsvUrl: string;

  @Attr() notes: string;

  @Attr() organisationId: number;

  @Attr() rateInfluence: boolean;

  @Attr() refreshQueries: boolean;

  @Attr() reportType: ReportType;

  @Attr() reportLevelRatings: boolean;

  @Attr() selectedQueries: number[];

  @Attr() selectedTopics: number[];

  @Attr() insightsPlusMainQueryId: number;

  @Attr({ persist: false }) filterSets: any;

  @Attr() recipients: string[];

  @Attr() status: string;

  @Attr({ persist: false }) sentAt: string;

  @Attr() topNAggregationCsvUrl: string;

  @Attr() topNCsvUrl: string;

  @Attr() secondaryLabel: string;

  @Attr({ persist: false }) recipientsList: string[];

  @Attr({ persist: false }) deliveredRecipients: string[];

  @Attr() groupId: number;

  @Attr() coverImageUrl: string;

  @Attr() secondaryCoverImageUrl: string;

  isQualitativeReport() {
    return this.reportType === "qualitative";
  }

  isQuantitativeReport() {
    return this.reportType === "quantitative";
  }

  isInstantInsightsPlusReport() {
    return this.reportType === "insights_plus";
  }

  isProcessing() {
    return instantInsightsPlusPendingStatuses.includes(this.status);
  }

  isCompleted() {
    return this.status === "building_completed";
  }

  isHtmlCompleted() {
    return this.status === "html_completed";
  }
}

export default InsightsReport;

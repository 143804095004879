import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import MdmLocation from "./MdmLocation";
import Organisation from "./Organisation";

@Model()
class OrganisationMdmLocationExclusion extends ApplicationRecord {
  static jsonapiType = "organisation_mdm_location_exclusions";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() mdmLocation: MdmLocation;

  @Attr() organisationId: number;

  @Attr() mdmLocationId: number;
}

export default OrganisationMdmLocationExclusion;

import { track } from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";

const handleErrors = true;

import { useStreamsStore } from "shared/stores/streams";

/* Mention Stream Groups */

export default {
  setCurrentMentionStreamGroupIndex({
    streamGroupIndex,
    streamGroups,
    router,
  }) {
    this.currentMentionStreamGroupIndex = streamGroupIndex;

    router
      .push({
        name: "stream",
        params: {
          groupSlug: streamGroups[streamGroupIndex].slug,
        },
      })
      .catch(() => {});
  },
  getStreamGroups() {
    return $streemApiV1
      .get("stream_groups", { handleErrors })
      .then((response) => {
        this.streamGroups = response.data;
      });
  },
  addStreamGroup({ params }) {
    track("Created Group");

    return $streemApiV1
      .post("stream_groups", { params, handleErrors })
      .then((response) => {
        this.streamGroups = [...this.streamGroups, response.data];

        return response;
      });
  },
  updateStreamGroup({ id, params }) {
    track("Updated Group");

    return $streemApiV1
      .put(`stream_groups/${id}`, { params, handleErrors })
      .then((response) => {
        this.streamGroups = this.streamGroups.map((streamGroup) =>
          streamGroup.id === id ? response.data : streamGroup
        );

        return response;
      });
  },
  shareStreamGroup({ id, params }) {
    track("Stream Group Shared");

    return $streemApiV1
      .put(`stream_groups/${id}/share`, { params, handleErrors })
      .then((response) => {
        const streamGroup = response.data;
        this.streamGroups = this.streamGroups.map((group) =>
          group.id === id ? streamGroup : group
        );

        const groupStreamsLookup = streamGroup.streams.reduce(
          (obj, stream) => Object.assign(obj, { [stream.id]: stream }),
          {}
        );

        // Update all included streams with the new 'shared' status
        const streamsStore = useStreamsStore();
        streamsStore.streams.forEach((stream) => {
          if (groupStreamsLookup[stream.id]) {
            streamsStore.setStream({
              id,
              data: {
                ...stream,
                shared: groupStreamsLookup[stream.id].shared,
              },
            });
          }
        });
      });
  },
  deleteStreamGroup({ id }) {
    track("Removed Group");

    return $streemApiV1
      .delete(`stream_groups/${id}`, { handleErrors })
      .then(() =>
        Promise.all([this.getStreamGroups(), useStreamsStore().getStreams()])
      );
  },
  archiveStreamGroup({ id }) {
    track("Archived Group");

    return $streemApiV1
      .put(`stream_groups/${id}/archive`, { handleErrors })
      .then(() =>
        Promise.all([this.getStreamGroups(), useStreamsStore().getStreams()])
      );
  },
  // get the previous mention stream group that have been selected
  previousMentionStreamGroupIndex({ router }) {
    const streamGroups = this.mentionStreamGroups().filter(
      (group) => group.streams.length
    );

    const streamGroupIndex =
      this.currentMentionStreamGroupIndex === 0
        ? 0
        : this.currentMentionStreamGroupIndex - 1;

    if (this.currentMentionStreamGroupIndex !== streamGroupIndex) {
      this.setCurrentMentionStreamGroupIndex({
        streamGroupIndex,
        streamGroups,
        router,
      });
    }
  },
  // get the next mention stream group that have been selected
  nextMentionStreamGroupIndex({ router }) {
    const streamGroups = this.mentionStreamGroups().filter(
      (group) => group.streams.length
    );

    const streamGroupIndex =
      this.currentMentionStreamGroupIndex >= streamGroups.length - 1
        ? streamGroups.length - 1
        : this.currentMentionStreamGroupIndex + 1;

    if (this.currentMentionStreamGroupIndex !== streamGroupIndex) {
      this.setCurrentMentionStreamGroupIndex({
        streamGroupIndex,
        streamGroups,
        router,
      });
    }
  },
  // get the previous mention stream group that have been selected
  previousSocialStreamGroupIndex() {
    const streamGroups = this.socialStreamGroups().filter(
      (group) => group.streams.length
    );

    const streamGroupIndex =
      this.currentSocialStreamGroupIndex === 0
        ? 0
        : this.currentSocialStreamGroupIndex - 1;

    if (this.currentSocialStreamGroupIndex !== streamGroupIndex) {
      this.setCurrentSocialStreamGroupIndex({
        streamGroupIndex,
        streamGroups,
      });
    }
  },
  // get the next mention stream group that have been selected
  nextSocialStreamGroupIndex() {
    const streamGroups = this.socialStreamGroups().filter(
      (group) => group.streams.length
    );

    const streamGroupIndex =
      this.currentSocialStreamGroupIndex >= streamGroups.length - 1
        ? streamGroups.length - 1
        : this.currentSocialStreamGroupIndex + 1;

    if (this.currentSocialStreamGroupIndex !== streamGroupIndex) {
      this.setCurrentSocialStreamGroupIndex({
        streamGroupIndex,
        streamGroups,
      });
    }
  },
};

import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import ActiveStorageAttachment from "./ActiveStorageAttachment";
import RadioStation from "./RadioStation";
import TvChannel from "./TvChannel";
import User from "./User";

@Model()
class ManualBroadcastUpload extends ApplicationRecord {
  static jsonapiType = "manual_broadcast_uploads";

  @BelongsTo() source: TvChannel | RadioStation;

  @BelongsTo() user: User;

  @HasMany() mediaFiles: ActiveStorageAttachment[];

  @Attr() startTime: string;

  @Attr() sourceId: number;

  @Attr() sourceType: string;

  @Attr() sourceName: string;

  @Attr() timeZone: string;

  @Attr() userId: number;

  @Attr() userName: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default ManualBroadcastUpload;

import { Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class MentionReview extends ApplicationRecord {
  static jsonapiType = "mention_reviews";
}

export default MentionReview;

<template>
  <div class="row full-width tags-holder">
    <QSpinnerDots
      v-if="loading"
      :size="22"
      class="spinner"
    />
    <template v-else>
      <TransitionGroup
        v-if="bookmarks.length"
        :name="computedTransition"
        tag="span"
      >
        <Tag
          v-for="bookmarkStream in bookmarks"
          :key="bookmarkStream.label"
          :color="labelColor(bookmarkStream)"
          :label="bookmarkStream.label"
          :palette="palette"
          @click.stop.prevent="bookmarkStreamClicked(bookmarkStream)"
          @remove="removeTag(bookmarkStream)"
        />
      </TransitionGroup>
    </template>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { Tag } from "shared/components/base";
import { defaultBookmarkPalette, tagPalette } from "shared/constants";
import { useBookmarkStreamsStore } from "shared/stores/bookmarkStreams";
import { useStreamsStore } from "shared/stores/streams";

export default {
  name: "BookmarkLabels",
  components: {
    Tag,
  },
  props: {
    bookmarks: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
    },
  },
  emits: ["tag-deleted"],
  setup() {
    const streamsStore = useStreamsStore();
    const { getStreamById } = storeToRefs(streamsStore);

    const bookmarkStreamsStore = useBookmarkStreamsStore();

    const { removeFromBookmarkStream } = bookmarkStreamsStore;

    return { getStreamById, removeFromBookmarkStream };
  },
  data() {
    return {
      withTransition: false,
    };
  },
  computed: {
    computedTransition() {
      return this.withTransition ? "list" : "none";
    },
    palette() {
      return { ...tagPalette, ...defaultBookmarkPalette };
    },
  },
  updated() {
    this.withTransition = true;
  },
  methods: {
    async removeTag(bookmark) {
      const bookmarkStream = { id: bookmark.stream_id, label: bookmark.label };
      const mention = { bookmark_id: bookmark.id };

      await this.removeFromBookmarkStream({
        bookmarkStream,
        mention,
      });

      this.$emit("tag-deleted", bookmark.id);
    },
    labelColor(bookmark) {
      return bookmark.color || Object.keys(defaultBookmarkPalette)[0];
    },
    bookmarkStreamRoute(stream) {
      return stream.social_bookmarks_stream
        ? "socialBookmarkStream"
        : "bookmarkStream";
    },
    bookmarkStreamClicked(stream) {
      const bookmarkStream = this.getStreamById(stream.stream_id);

      this.$safeRouterPush({
        name: this.bookmarkStreamRoute(bookmarkStream),
        params: {
          label: stream.label,
          groupSlug: bookmarkStream.group?.slug,
        },
      }).catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.tags-holder {
  margin-top: 15px;

  > * {
    display: flex;
    gap: var(--s-spacing-md);
    flex-wrap: wrap;
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.1s;
}

.list-enter-from,
.list-leave-to {
  opacity: 0;
  transform: translateY(30px);
}

:deep(.tag) {
  border-radius: 30px;

  .tag-label {
    max-width: 35ch;
  }
}
</style>

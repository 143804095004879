import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Website from "./Website";

@Model()
class WebsiteDomain extends ApplicationRecord {
  static jsonapiType = "website_domains";

  @Attr() domain: string;

  @Attr() primary: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @BelongsTo() website: Website;
}

export default WebsiteDomain;

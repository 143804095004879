import { BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import MdmTopic from "./MdmTopic";
import Outlet from "./Outlet";

@Model()
class OutletsTopic extends ApplicationRecord {
  static jsonapiType = "outlets_topics";

  @BelongsTo() outlet: Outlet;

  @BelongsTo() mdmTopic: MdmTopic;
}

export default OutletsTopic;

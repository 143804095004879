import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import InsightsQuery from "./InsightsQuery";

@Model()
class InsightsQueryFilter extends ApplicationRecord {
  static jsonapiType = "insights_query_filters";

  @BelongsTo() insightsQuery: InsightsQuery;

  @Attr() objectId: number;

  @Attr() targetId: number;

  @Attr() objectType: string;

  @Attr() targetType: string;

  @Attr() scope: string;

  @Attr() action: string;
}

export default InsightsQueryFilter;

import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

type Medium = "tv" | "radio";

@Model()
class BroadcastRecorder extends ApplicationRecord {
  static jsonapiType = "broadcast_recorders";

  @Attr() host: string;

  @Attr() medium: Medium;

  @Attr({ persist: false }) createdAt: string;
}

export default BroadcastRecorder;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import SocialEngagePost from "./SocialEngagePost";
import User from "./User";

@Model()
class SocialEngagePostEvent extends ApplicationRecord {
  static jsonapiType = "social_engage_post_events";

  @BelongsTo() socialEngagePost: SocialEngagePost;

  @BelongsTo() user: User;

  @Attr() socialEngagePostId: number;

  @Attr() userId: number;

  @Attr() eventType: string;

  @Attr() metadata: Record<string, any>;

  @Attr() username: string;

  @Attr({ persist: false }) createdAt: string;
}

export default SocialEngagePostEvent;

<template>
  <div class="row">
    <div
      v-for="(column, index) in platformsColumns"
      :key="index"
      class="flex"
      :class="{
        [`col-md-${12 / columns} col-sm-12`]: !inline,
        'full-width': inline,
      }"
    >
      <div>
        <div
          v-for="(platform, key) in column"
          :key="key"
          :class="{
            'row full-width justify-between': !inline,
            'col-md-auto': inline,
          }"
        >
          <Checkbox
            v-model="platformsInput[platform.platform]"
            class="primary"
            :disabled="disablePlatform(platform.platform)"
            new-design
          >
            <QIcon
              :name="`img:${platform.icon}`"
              size="20px"
              class="q-mx-md"
            />
            <span>{{ platform.label }}</span>

            <StreemTooltip
              v-if="disablePlatform(platform.platform)"
              anchor="center right"
              self="center left"
            >
              {{
                $t("social_platforms_expandable.platform_cannot_be_selected", {
                  platformLabel: platform.label,
                })
              }}
            </StreemTooltip>
          </Checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getEnabledSocialPlatformsForStream } from "shared/helpers/sources";

const ALLOWED_IMPACT_FILTER_PLATFORMS = ["facebook", "twitter", "instagram"];

export default {
  name: "SocialPlatformsExpandable",
  props: {
    stream: {
      type: Object,
      required: true,
    },
    platforms: {
      type: Array,
      default: () => [],
    },
    refreshPlatforms: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
    contentStyle: {
      type: String,
      default: "row",
    },
    minimumFollowerCountEnabled: {
      type: Boolean,
    },
    verifiedEnabled: {
      type: Boolean,
    },
    columns: {
      type: [Number, String],
      default: 2,
      validator(value) {
        return 12 % Number(value) === 0;
      },
    },
    inline: {
      type: Boolean,
    },
  },
  emits: ["search", "update:platforms", "update:refreshPlatforms"],
  data() {
    return {
      platformsInput: null,
      platformsInputCopy: null,
    };
  },
  computed: {
    enabledPlatforms() {
      return getEnabledSocialPlatformsForStream(this.stream);
    },
    platformsColumns() {
      const columns = this.inline ? 1 : this.columns;

      return this.splitToChunks([...this.enabledPlatforms], columns);
    },
    enabledSocialImpactFilter() {
      return this.minimumFollowerCountEnabled || this.verifiedEnabled;
    },
  },
  watch: {
    enabledSocialImpactFilter: {
      handler() {
        let platforms = { ...this.platformsInputCopy };

        if (this.enabledSocialImpactFilter) {
          platforms = { ...this.platformsInput };
          this.platformsInputCopy = { ...this.platformsInput };
        }

        this.updatePlatformsInputs(platforms);
        this.$emit("search");
      },
    },
    platformsInput: {
      deep: true,
      handler() {
        const platforms = this.platformsInputToParams();

        if (!this.refreshPlatforms) {
          this.$emit("update:platforms", platforms);
          this.$emit("search");
        } else {
          this.$emit("update:refreshPlatforms", false);
        }
      },
    },
    platforms: {
      immediate: true,
      deep: true,
      handler() {
        if (!this.platformsInput || this.refreshPlatforms) {
          const sources = this.platforms.length
            ? this.platforms
            : this.enabledPlatforms.map(({ platform }) => platform);

          this.platformsInput = this.platformsToObject(sources);
        }
      },
    },
  },
  methods: {
    splitToChunks(array, chunks) {
      const result = [];

      for (let index = chunks; index > 0; index -= 1) {
        result.push(array.splice(0, Math.ceil(array.length / index)));
      }

      return result;
    },
    platformsToObject(platforms) {
      return platforms.reduce((acc, platform) => {
        acc[platform] = true;

        return acc;
      }, {});
    },
    platformsInputToParams() {
      const currentPlatforms = Object.keys(this.platformsInput).filter((key) =>
        Boolean(this.platformsInput[key])
      );

      if (this.enabledSocialImpactFilter && !currentPlatforms.length) {
        return ALLOWED_IMPACT_FILTER_PLATFORMS;
      }

      return currentPlatforms;
    },
    disablePlatform(platform) {
      if (!this.enabledSocialImpactFilter) return false;

      return !ALLOWED_IMPACT_FILTER_PLATFORMS.includes(platform);
    },
    updatePlatformsInputs(platforms) {
      Object.keys(platforms).forEach((platform) => {
        if (!ALLOWED_IMPACT_FILTER_PLATFORMS.includes(platform)) {
          this.platformsInput[platform] = this.enabledSocialImpactFilter
            ? false
            : this.platformsInputCopy[platform];
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.info-icon {
  margin-bottom: 2px;
}
</style>

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class Tag extends ApplicationRecord {
  static jsonapiType = "tags";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() taggable: any;

  @Attr() label: string;

  @Attr() color: string;

  @Attr() organisationId: number;

  @Attr() taggableId: number;

  @Attr() taggableType: string;

  @Attr() organisationTeamId: number;
}

export default Tag;

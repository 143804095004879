import { uniqBy } from "lodash-es";
import { Attr, BelongsTo, Model } from "spraypaint";

import { getLocaleText } from "shared/boot/i18n";
import {
  MEDIA_RELEASE_TYPE,
  MEDIA_RELEASE_TYPES,
  PITCH_TYPE,
} from "shared/constants";
import ApplicationRecord from "shared/services/spraypaint";

import { getMediaReleaseStatuses } from "./MediaRelease";
import Organisation from "./Organisation";
import {
  getOutreachCommunicationStatuses,
  getOutreachCommunicationTypes,
} from "./OutreachCommunication";
import OutreachCommunicationUser from "./OutreachCommunicationUser";
import User from "./User";

interface OutreachActivityStatus {
  label: string;
  value: string;
  backgroundColor?: string;
  color: string;
}

export function outreachActivityStatus(): OutreachActivityStatus[] {
  return uniqBy(
    [...getOutreachCommunicationStatuses(), ...getMediaReleaseStatuses()],
    "value"
  );
}

export function outreachActivityTypes() {
  return [
    {
      label: getLocaleText("outreach.media_release.media_release_title"),
      value: MEDIA_RELEASE_TYPE,
      color: "#13C3C3",
    },
    {
      value: PITCH_TYPE,
      label: getLocaleText("outreach.media_release.pitch_title"),
      color: "#9154de",
    },
    ...getOutreachCommunicationTypes(),
  ];
}

@Model()
class OutreachActivity extends ApplicationRecord {
  static jsonapiType = "outreach_activities";

  @BelongsTo() activity: any;

  @BelongsTo() user: User;

  @BelongsTo() organisation: Organisation;

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() organisationTeamId: number;

  @Attr() activityId: number;

  @Attr() activityType: string;

  type() {
    if (this.isMediaRelease()) {
      return outreachActivityTypes().find(
        (type) => type.value === this.activity.mediaReleaseType
      );
    }

    return outreachActivityTypes().find(
      (type) => type.value === this.activity.communicationType
    );
  }

  title() {
    if (this.isMediaRelease()) {
      if (this.activity.title) {
        return this.activity.title;
      }

      if (this.activity.subject) {
        return this.activity.subject;
      }

      return this.activity.mediaReleaseType === PITCH_TYPE
        ? getLocaleText("outreach.media_release.pitch_title")
        : getLocaleText("outreach.media_release.media_release_title");
    }

    return this.activity.title;
  }

  assignees() {
    if (this.isMediaRelease()) {
      return [this.activity.user.name];
    }

    return this.activity.outreachCommunicationUsers
      .filter(
        (communicationUser: OutreachCommunicationUser) => communicationUser.user
      )
      .map(
        (communicationUser: OutreachCommunicationUser) =>
          communicationUser.user.name
      );
  }

  contactsCount() {
    if (this.isMediaRelease()) {
      return this.activity.recipientCount;
    }

    return this.activity.outreachCommunicationContactsCount;
  }

  status() {
    return outreachActivityStatus().find(
      (status: OutreachActivityStatus) => status.value === this.activity.status
    );
  }

  date() {
    if (this.isMediaRelease()) {
      if (this.isMediaReleaseSent()) {
        return this.activity.sentAt;
      }

      if (this.activity.scheduledAt) {
        return this.activity.scheduledAt;
      }

      return null;
    }

    return this.activity.sentAt;
  }

  isMediaRelease() {
    return MEDIA_RELEASE_TYPES.includes(this.activity?.mediaReleaseType);
  }

  isPitch() {
    return (
      this.isMediaRelease() && this.activity.mediaReleaseType === PITCH_TYPE
    );
  }

  isMediaReleaseSent() {
    return this.isMediaRelease() && this.activity.isSent();
  }
}

export default OutreachActivity;

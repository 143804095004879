import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import OrganisationContract from "./OrganisationContract";
import type { Features } from "./types";

@Model()
class ContractsFeature extends ApplicationRecord {
  static jsonapiType = "contracts_features";

  @BelongsTo() contract: OrganisationContract;

  @Attr() contractId: number;

  @Attr() enabled: boolean;

  @Attr() monthlyCredits: number;

  @Attr() unlimited: boolean;

  @Attr() cost: number;

  @Attr() feature: Features;

  @Attr({ persist: false }) billable: boolean;

  @Attr({ persist: false }) flaggable: boolean;

  @Attr({ persist: false }) name: string;

  @Attr({ persist: false }) label: string;

  @Attr({ persist: false }) position: number;

  @Attr({ persist: false }) description: string;

  @Attr({ persist: false }) tag: string;

  @Attr({ persist: false }) parentFeatureId: number;

  @Attr({ persist: false }) locked: boolean;

  @Attr({ persist: false }) accessLevel: any;
}

export default ContractsFeature;

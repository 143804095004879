import { useSideBarStore } from "desktop/src/stores/sideBar";
import LogRocket from "logrocket";

import {
  newAnalyticsProxy,
  reset,
  track,
  updateIntercom,
} from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";
import { appInstance } from "shared/boot/app";
import { setLocale } from "shared/boot/i18n";
import {
  cachedToken,
  cacheOrganisationToken,
  clearCachedTokens,
  updateUserToken,
} from "shared/helpers/authorization";
import OrganisationAggregationMultiplier from "shared/resources/OrganisationAggregationMultiplier";
import Permissions from "shared/services/Permissions";
import StorageService from "shared/services/StorageService";

import { useUniversalPlayerStore } from "../universalPlayer";

const handleErrors = true;

import initialState from "./state";

export default {
  login({ emailOrPhone, pin, platform, twoFactorCode }) {
    this.pending = true;

    return $streemApiV1
      .post("sessions", {
        params: {
          email_or_phone: emailOrPhone,
          password: pin,
          platform,
          two_factor_code: twoFactorCode,
        },
        handleErrors: false,
      })
      .then(async (response) => {
        const result = response.data;
        const { auth_token: token } = result;

        this.loginSuccess(token);

        this.setEnforceTwoFactor(Boolean(result.enforce_two_factor));
        this.identityProvider = "streem";

        StorageService.set("login", emailOrPhone);
        StorageService.set("identityProvider", "streem");

        await clearCachedTokens();

        return updateUserToken(token);
      })
      .catch((error) => {
        this.pending = false;

        return Promise.reject(error);
      });
  },
  async logout(clearAll = false) {
    if (!this.isAdminUser) {
      track("Signed Out");
      reset();
    }

    sessionStorage.clear();

    await Promise.all([
      StorageService.remove("identityProvider"),
      clearCachedTokens(),
    ]);

    useUniversalPlayerStore().closeAndStop();
    useSideBarStore().hideSideBar();

    if (clearAll) {
      await StorageService.remove("login");
    }

    this.$patch({ ...initialState() });
    this.setEnforceTwoFactor(false);

    this.identityProvider = null;

    updateIntercom({
      hide_default_launcher: false,
      alignment: "right",
    });
  },
  async switchOrganisation(organisationId, platform) {
    let token = await cachedToken(organisationId);

    if (!token) {
      const { data } = await $streemApiV1.post("sessions/switch", {
        params: {
          id: organisationId,
          platform,
        },
      });

      token = data.auth_token;

      await cacheOrganisationToken(token, organisationId);
    }

    await Promise.all([
      StorageService.remove("organisationId", sessionStorage),
      StorageService.set("newOrgId", organisationId, sessionStorage),
    ]);

    window.location.replace("/");
  },
  setEnforceTwoFactor(enforceTwoFactor) {
    this.enforceTwoFactor = this.isAdminUser ? false : enforceTwoFactor;
  },
  async adminUserLogin({ token, accountManager }) {
    await StorageService.set(
      "accountManager",
      JSON.stringify(accountManager),
      sessionStorage
    );
    await updateUserToken(token);

    this.adminUserOn(accountManager);

    await this.getUser();

    const permissions = new Permissions();

    if (permissions.has("has_advanced_access")) {
      await newAnalyticsProxy(appInstance, { isAdminUser: true });

      if (LogRocket) {
        LogRocket.identify(this.currentUser.id, {
          accountManager: accountManager.name,
        });
      }
    } else {
      this.adminUserOff();
    }

    this.loginSuccess(token);
  },
  loginSuccess(token) {
    this.token = token;
    this.isLoggedIn = true;
    this.pending = false;
  },
  loginFailure() {
    this.pending = false;
  },
  authenticated() {
    this.loadError = null;
  },
  unauthorized(error) {
    this.loadError = error;
  },
  async sudo({ pin, permission, idToken, provider = "streem" }) {
    track("Sudo");

    const response = await $streemApiV1.post("sessions/sudo", {
      params: {
        pin,
        permission,
        provider,
        id_token: idToken,
      },
    });

    const result = response.data;
    const token = result.auth_token;

    if (token) {
      this.loginSuccess(token);

      await updateUserToken(token);

      return true;
    }

    return false;
  },
  async getUser() {
    const response = await $streemApiV1.get("user");

    const user = response.data;
    const { preferences } = user;

    this.$patch({
      currentUser: user,
      preferences,
    });

    const token = await StorageService.get("token");
    updateUserToken(token, user.organisation.id);

    this.setEnforceTwoFactor(user.enforce_two_factor);
    this.setUserLocale(user);

    this.authenticated();
  },
  async getOrganisation() {
    const response = await $streemApiV1.get(
      `organisations/${this.currentUser.organisation?.id}`,
      {
        handleErrors,
      }
    );

    this.organisation = response.data;
  },
  async getMultiplier() {
    const response = await OrganisationAggregationMultiplier.includes([
      "aveMultiplierLastUpdatedBy",
      "audienceMultiplierLastUpdatedBy",
    ])
      .where({ organisationId: this.currentUser.organisation?.id })
      .all();

    if (response.data.length) {
      [this.multiplier] = response.data;
    }
  },
  async getTeam() {
    const response = await $streemApiV1.get("user/team", { handleErrors });

    this.team = response.data;
  },
  async updateUser(data) {
    const response = await $streemApiV1.put("user", {
      params: { user: data },
    });

    this.currentUser = response.data;
    this.setUserLocale(response.data);
    this.setEnforceTwoFactor(response.data.enforce_two_factor);
  },
  async updateUserPreferences({ id, attribute, value }) {
    this.preferences[attribute] = value;

    await $streemApiV1.put(`users/${id}/update_preferences`, {
      params: { attribute, value },
      handleErrors,
    });
  },
  toggleShowExcerpts() {
    this.showFullExcerpts = !this.showFullExcerpts;
  },
  toggleCustomerView() {
    this.customerViewEnabled = !this.customerViewEnabled;
  },
  toggleBetaFeatures() {
    this.betaFeaturesEnabled = !this.betaFeaturesEnabled;
  },
  microsoftAuthCallback(idToken, platform) {
    return $streemApiV1
      .post("sessions", {
        params: {
          provider: "microsoft",
          id_token: idToken,
          platform,
        },
        handleErrors: false,
      })
      .then(async (response) => {
        const token = response.data.auth_token;

        StorageService.set("identityProvider", "microsoft");
        await updateUserToken(token);

        this.loginSuccess({ token, userId: response.data.user_id });
        this.identityProvider = "microsoft";
      })
      .catch((error) => {
        this.loginFailure();

        throw error;
      });
  },
  googleAuthCallback({ idToken, platform, client }) {
    return $streemApiV1
      .post("sessions", {
        params: {
          provider: "google",
          id_token: idToken,
          platform,
          client,
        },
        handleErrors: false,
      })
      .then(async (response) => {
        const token = response.data.auth_token;

        StorageService.set("identityProvider", "google");
        await updateUserToken(token);

        this.loginSuccess({ token, userId: response.data.user_id });
        this.identityProvider = "google";
      })
      .catch((error) => {
        this.loginFailure();

        throw error;
      });
  },
  async refreshGoogleSsoCallback({ idToken, platform, client }) {
    const response = await $streemApiV1.post("sessions", {
      params: {
        provider: "google",
        refresh: true,
        id_token: idToken,
        platform,
        client,
        resume: true,
      },
      handleErrors: false,
    });

    const token = response.data.auth_token;

    await updateUserToken(token);

    this.identityProvider = "google";
    this.loginSuccess({ token, userId: response.data.user_id });
  },
  async refreshMicrosoftSsoCallback(idToken, platform) {
    const response = await $streemApiV1.post("sessions", {
      params: {
        provider: "microsoft",
        refresh: true,
        id_token: idToken,
        platform,
        resume: true,
      },
      handleErrors: false,
    });

    const token = response.data.auth_token;

    await updateUserToken(token);

    this.identityProvider = "microsoft";
    this.loginSuccess({ token, userId: response.data.user_id });
  },
  adminUserOff() {
    this.isAdminUser = false;
  },
  adminUserOn(accountManager) {
    this.isAdminUser = true;

    if (accountManager) {
      this.accountManager = accountManager;
    }
  },
  setOrganisation(organisation) {
    this.organisation = organisation;
  },
  setUserLocale(user) {
    setLocale(user.locale);
  },
  isSuperRole(currentRouteProductMenu) {
    if (currentRouteProductMenu === "social") {
      return this.currentUser.social_role === "super";
    }

    if (currentRouteProductMenu === "outreach") {
      return this.currentUser.outreach_role === "super";
    }

    return this.currentUser.role === "super";
  },
};

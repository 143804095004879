import LogRocket from "logrocket";
import Rollbar from "rollbar";

let rollbar;

const MESSAGES_TO_IGNORE = [
  // https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062/22
  "Object Not Found Matching Id:",
];

export default ({ app }) => {
  if (!import.meta.env.VITE_ROLLBAR_TOKEN) return;

  rollbar = new Rollbar({
    accessToken: import.meta.env.VITE_ROLLBAR_TOKEN,
    captureUncaught: true,
    captureUnhandledRejections: true,
    enabled: true,
    source_map_enabled: true,
    payload: {
      environment: import.meta.env.VITE_ENV,
      client: {
        javascript: {
          source_map_enabled: true,
          code_version: "__commit_hash__",
          guess_uncaught_frames: true,
        },
      },
    },
    checkIgnore(isUncaught, args, payload) {
      return MESSAGES_TO_IGNORE.some(
        (message) =>
          (payload.body.trace?.exception?.message || "").includes(message) ||
          (payload.body.trace?.exception?.description || "").includes(message)
      );
    },
    transform(payload) {
      Object.assign(payload, { sessionURL: LogRocket.sessionURL });

      // replace trace domain with GCP hosted copy
      const { trace } = payload.body;

      const locationRegexp =
        /^(https?|capacitor):\/\/([a-zA-Z0-9._-]+\.)?(streem.com.au|cision.one|localhost)(.*)/;

      if (trace && trace.frames) {
        for (let index = 0; index < trace.frames.length; index += 1) {
          const { filename } = trace.frames[index];

          if (filename) {
            const match = filename.match(locationRegexp);
            trace.frames[index].filename =
              `__gcp_bucket_base_href__${match.pop()}`;
          }
        }
      }

      return payload;
    },
  });

  // eslint-disable-next-line no-param-reassign
  app.config.globalProperties.$rollbar = rollbar;
};

function identify(data) {
  if (!import.meta.env.VITE_ROLLBAR_TOKEN) return;

  rollbar.configure({
    payload: {
      person: data,
    },
  });
}

function rollbarError(error) {
  if (rollbar) rollbar.error(error);
}

export { identify, rollbarError };

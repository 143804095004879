import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import OrganisationContract from "./OrganisationContract";
import OrganisationsFeature from "./OrganisationsFeature";
import Version from "./Version";

@Model()
class OrganisationFeatureAllowance extends ApplicationRecord {
  static jsonapiType = "organisation_feature_allowances";

  @BelongsTo() organisationContract: OrganisationContract;

  @BelongsTo() organisationsFeature: OrganisationsFeature;

  @HasMany() versions: Version[];

  @Attr() monthlyCredits: number;

  @Attr() blockInvoice: boolean;

  @Attr() blockInvoiceReason: string;

  @Attr() unlimited: boolean;

  @Attr() organisationContractId: number;

  @Attr() organisationsFeatureId: number;
}

export default OrganisationFeatureAllowance;

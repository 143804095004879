import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import StreamsExternalItem from "./StreamsExternalItem";
import User from "./User";

@Model()
class ExternalItem extends ApplicationRecord {
  static jsonapiType = "external_items";

  @BelongsTo() user: User;

  @HasMany() streamsExternalItems: StreamsExternalItem[];

  @Attr() userId: number;

  @Attr() title: string;

  @Attr() url: string;

  @Attr() timeZone: string;

  @Attr() medium: string;

  @Attr() sourceName: string;

  @Attr() audience: number;

  @Attr() summary: string;

  @Attr() archivedAt: string;

  @Attr() publishedAt: string;
}

export default ExternalItem;

<template>
  <div
    v-if="showSyndications"
    class="publishers-list"
    :class="{ 'q-pa-md publishers-list-dropdown': isDropdown }"
  >
    <a
      v-for="(syndicated, key) in syndications"
      :key="key"
      :class="{
        'cursor-pointer': !isRadio,
        'no-shrink': !isDropdown,
        block: isDropdown,
      }"
      @click.stop="syndicationClicked(syndicated)"
    >
      <div
        class="syndication-matches-stream"
        :class="{ 'syndication-matches-stream-dropdown': isDropdown }"
      >
        <span v-if="syndicationsMatchingStream[syndicated.id]">
          ✓
          <StreemTooltip>
            {{ $t("syndications.syndicated_item_tooltip") }}
          </StreemTooltip>
        </span>
      </div>
      <span class="bold">
        {{ syndicated.source_name }}
      </span>
      <span class="muted float-right syndication-time">
        {{ formatSyndicatedDate(syndicated) }}
      </span>
    </a>
  </div>
  <div
    v-else-if="loading"
    class="publishers-list"
    :class="{ 'q-pa-md publishers-list-dropdown': isDropdown }"
  >
    <QSpinner
      :size="30"
      class="full-width q-my-sm"
    />
  </div>
</template>

<script>
import { dateInTimeZone, parseDate, shortTimezone } from "shared/helpers/date";
import { sortedSyndications } from "shared/helpers/mentions";
import MatchingSyndications from "shared/services/fetching/matchingSyndications";

export default {
  name: "Syndications",
  inject: {
    filters: {
      from: "filters",
      default() {
        return () => null;
      },
    },
  },
  props: {
    expanded: {
      type: Boolean,
    },
    mention: {
      type: Object,
      required: true,
    },
    isDropdown: {
      type: Boolean,
    },
    stream: {
      type: Object,
      default: () => ({}),
    },
  },
  emits: ["syndication-clicked"],
  data() {
    return {
      syndicationsMatchingStream: {},
      loading: false,
    };
  },
  computed: {
    syndications() {
      return sortedSyndications(this.mention);
    },
    isTvOrRadio() {
      return this.isTv || this.isRadio;
    },
    isRadio() {
      return this.mention.type === "radio_clip";
    },
    isTv() {
      return ["tv_super", "tv_caption", "tv_logo_appearance"].includes(
        this.mention.type
      );
    },
    showSyndications() {
      return this.expanded && this.mention.syndications.length && !this.loading;
    },
  },
  watch: {
    expanded: {
      immediate: true,
      handler() {
        if (this.expanded) {
          this.loadSyndicationMatches();
        }
      },
    },
  },
  methods: {
    formatSyndicatedDate(syndicated) {
      const timestamp = this.isTvOrRadio
        ? syndicated.start_time
        : syndicated.published_at;
      const timezone = shortTimezone(
        parseDate(timestamp),
        syndicated.source.time_zone
      );
      const timeString = dateInTimeZone(
        timestamp,
        "h:mmaaa",
        syndicated.source.time_zone
      );

      return `${timeString} ${timezone}`;
    },
    syndicationClicked(syndication) {
      if (this.isTv) {
        const mergedObject = {
          ...syndication,
          excerpts: this.mention.excerpts,
          type: this.mention.type,
        };
        this.$emit("syndication-clicked", mergedObject);
      } else if (!this.isRadio || syndication.tveyes_player_url?.length) {
        this.$emit("syndication-clicked", {
          ...syndication,
          type: this.mention.type,
        });
      }
    },
    async loadSyndicationMatches() {
      this.loading = true;

      try {
        if (this.stream.id && this.filters) {
          const options = this.filters.requestFilters();
          const response = await MatchingSyndications.get({
            stream: this.stream,
            mention: this.mention,
            options,
          });
          this.syndicationsMatchingStream =
            response.syndications_matching_stream;
        }
      } catch (error) {
        this.$q.notify(this.$t("syndications.unable_to_load_syndications"));
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.publishers-list-dropdown {
  width: 300px;
}

.syndication-matches-stream-dropdown {
  display: inline-block;
  width: 0.8em;
}

.muted {
  font-size: 80%;
  opacity: 0.5;
}
</style>

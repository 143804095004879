import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import FacebookPage from "./FacebookPage";

@Model()
class FacebookPost extends ApplicationRecord {
  static jsonapiType = "facebook_posts";

  @BelongsTo() facebookPage: FacebookPage;

  @Attr() articleId: number;

  @Attr() postId: number;

  @Attr() postLinks: string;

  @Attr() author: string;

  @Attr() body: string;

  @Attr() url: string;

  @Attr() publishedAt: string;

  @Attr() facebookPageId: number;

  @Attr() language: string;

  @Attr({ persist: false }) createdAt: string;
}

export default FacebookPost;

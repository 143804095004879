import { Attr, HasMany, HasOne, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import FilteredSpecStream from "./FilteredSpecStream";
import OrganisationReportSpec from "./OrganisationReportSpec";

@Model()
class OrganisationReportFilteredSpec extends ApplicationRecord {
  static jsonapiType = "organisation_report_filtered_specs";

  @Attr() subject: string;

  @Attr() enabled: boolean;

  @Attr() distributionListId: number;

  @Attr() recipients: any[];

  @Attr() deliverAt: number;

  @Attr() deliverWithReport: boolean;

  @Attr() organisationBrandId: number;

  @Attr() prefillEmptyFolders: boolean;

  @HasOne() specification: OrganisationReportSpec;

  @Attr() sources: any[];

  @Attr() filters: any[];

  @HasMany() folders: FilteredSpecStream[];

  @Attr({ persist: false }) archivedAt: string;
}

export default OrganisationReportFilteredSpec;

import { Attr, HasOne, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";

@Model()
class TranscriptTemplate extends ApplicationRecord {
  static jsonapiType = "transcript_templates";

  @HasOne() organisation: Organisation;

  @Attr() organisationId: number;

  @Attr() name: string;

  @Attr() url: string;

  @Attr() filename: string;

  @Attr() exampleUrl: string;

  @Attr() exampleFilename: string;

  @Attr() styleGuide: string;
}

export default TranscriptTemplate;

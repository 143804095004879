export default () => ({
  accountManager: {},
  betaEnabled: JSON.parse(sessionStorage.getItem("betaEnabled") || false),
  enforceTwoFactor: false,
  isLoggedIn: false,
  isAdminUser: false,
  loadError: null,
  pending: false,
  preferences: {},
  token: "",
  identityProvider: "",
  currentUser: {},
  showFullExcerpts: false,
  customerViewEnabled: false,
  organisation: {},
  multiplier: {},
  team: [],
});

import { debounce } from "lodash-es";
import { Notify } from "quasar";

import { getLocaleText } from "shared/boot/i18n";
import LoadError from "shared/pages/LoadError.vue";

const ONE_SECOND = 1000;

const showLoadErrorPage = (error) => ({
  ...LoadError,
  error,
});

const unauthorizedError = debounce(async ({ error, router, userStore }) => {
  // Received 401, logout the user
  await userStore.logout();

  if (error?.response?.data) {
    userStore.unauthorized(error.response.data.message);
  }

  if (router.currentRoute.value.path !== "/sign-in") {
    router.replace({
      path: "/sign-in",
      query: {
        redirect: router.currentRoute.value.fullPath,
      },
    });
  }

  return error;
}, ONE_SECOND);

const connectionError = debounce(({ error, router }) => {
  Notify.create({
    message: getLocaleText("helpers.errors.connection_error_message"),
    type: "negative",
    icon: "wifi",
    detail: getLocaleText("helpers.errors.connection_error_detail"),
    position: "top",
    actions: [
      {
        label: getLocaleText("global.retry"),
        icon: "sync",
        noDismiss: true,
        handler: () => router.go(router.currentRoute.value),
      },
    ],
  });

  return error;
}, ONE_SECOND);

const forbiddenError = debounce(({ error }) => {
  Notify.create({
    message: getLocaleText("helpers.errors.forbidden_message"),
    type: "negative",
    icon: "lock",
    position: "top",
  });

  return error;
}, ONE_SECOND);

const serverError = debounce(({ error, router }) => {
  Notify.create({
    message: getLocaleText("helpers.errors.server_error_message"),
    type: "negative",
    icon: "error",
    detail: getLocaleText("helpers.errors.server_error_detail"),
    position: "top",
    actions: [
      {
        label: getLocaleText("global.retry"),
        icon: "sync",
        noDismiss: true,
        handler: () => router.go(router.currentRoute.value),
      },
    ],
  });

  return error;
}, ONE_SECOND);

export {
  showLoadErrorPage,
  unauthorizedError,
  connectionError,
  serverError,
  forbiddenError,
};

import { computed, getCurrentInstance } from "vue";

import features from "shared/services/features";
import StorageService from "shared/services/StorageService";

export default function useContentWarning() {
  const root = getCurrentInstance().proxy;
  const showContentWarning = computed(() =>
    features.hasAny([
      "has_mediatrack_nla_regional",
      "has_nla_eclips_print",
      "has_uk_clipped_content_cla",
      "has_uk_clipped_content_nla",
      "has_wise_cla",
      "has_wise_nla",
    ])
  );

  async function openContentWarningModal(view, props = {}) {
    const storageKey = `showContentWarning${view}`;
    const hideModal = await StorageService.get(storageKey);

    return new Promise((resolve) => {
      if (!showContentWarning.value || hideModal) {
        resolve();

        return;
      }

      root.$modals.open("ContentWarningModal", {
        props,
        events: {
          confirm: (hideWarning) => {
            if (props.canIgnore) {
              StorageService.set(storageKey, hideWarning);
            }

            resolve();
          },
          close: () => {
            resolve();
          },
        },
      });
    });
  }

  function openAddMentionStreamContentWarningModal() {
    return openContentWarningModal("AddMentionStream", {
      offsetMenu: true,
      canIgnore: true,
    });
  }

  function openSearchCoverageContentWarningModal() {
    return openContentWarningModal("SearchCoverage", {
      canIgnore: true,
    });
  }

  function openShareMentionStreamContentWarningModal() {
    return openContentWarningModal("ShareMentionStream", {
      offsetMenu: true,
      canIgnore: true,
    });
  }

  function openShareMentionContentWarningModal() {
    return openContentWarningModal("ShareMention", {
      offsetMenu: true,
    });
  }

  function openScheduledReportContentWarningModal() {
    return openContentWarningModal("ScheduledReport", {
      offsetMenu: true,
      canIgnore: true,
    });
  }

  function openNotificationContentWarningModal() {
    return openContentWarningModal("Notification", {
      offsetMenu: true,
      canIgnore: true,
    });
  }

  return {
    showContentWarning,
    openContentWarningModal,
    openAddMentionStreamContentWarningModal,
    openSearchCoverageContentWarningModal,
    openShareMentionStreamContentWarningModal,
    openShareMentionContentWarningModal,
    openScheduledReportContentWarningModal,
    openNotificationContentWarningModal,
  };
}

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import User from "./User";

@Model()
class CoreVersion extends ApplicationRecord {
  static jsonapiType = "core_versions";

  @BelongsTo() user: User;

  @Attr() event: string;

  @Attr() objectChanges: Record<string, any>;

  @Attr() object: Record<string, any>;

  @Attr() itemId: number;

  @Attr() itemType: string;

  @Attr() itemSubtype: string;

  @Attr({ persist: false }) createdAt: string;
}

export default CoreVersion;

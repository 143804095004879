import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Article from "./Article";

@Model()
class ArticleEdition extends ApplicationRecord {
  static jsonapiType = "article_editions";

  @BelongsTo() article: Article;

  @Attr() articleId: number;

  @Attr() publishedAt: string;

  @Attr() title: string;

  @Attr() body: string;

  @Attr() originalLanguage: string;

  @Attr() translatedLanguage: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default ArticleEdition;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import JournalistEnquiry from "./JournalistEnquiry";
import type { ErrorWithResponse } from "./types";
import User from "./User";

@Model()
class JournalistEnquiryEvent extends ApplicationRecord {
  static jsonapiType = "journalist_enquiry_events";

  @BelongsTo() journalistEnquiry: JournalistEnquiry;

  @BelongsTo() user: User;

  @BelongsTo() event: Event;

  @Attr() eventAction: string;

  @Attr({ persist: false }) createdAt: string;
}

JournalistEnquiryEvent.afterFetch = async (
  response,
  json: JSON & { message?: string }
) => {
  if (response.status === 401 && json.message === "sudo access required") {
    const error: ErrorWithResponse = new Error("sudo access required");
    error.response = Object.assign(response, { data: json });

    throw error;
  } else {
    ApplicationRecord.middlewareStack.afterFetch(response, json);
  }
};

export default JournalistEnquiryEvent;

<template>
  <div v-if="visible">
    <div
      v-if="isListView"
      class="pointer"
      @click="onClick"
    >
      <QIcon
        name="ion-md-refresh"
        class="q-mr-sm"
        size="20px"
      />
      <i18n-t
        scope="global"
        tag="span"
        keypath="mention_stream_notification_watcher.refresh_new_mentions_button"
      >
        <template #mentionCount>
          <strong>{{ formattedMentionCount }}</strong>
        </template>
      </i18n-t>
    </div>
    <div
      v-if="!isListView && mentionCount(stream.id)"
      class="no-shrink pointer bg-warning row justify-center items-center q-pa-smd bold smaller"
      @click="onClick"
    >
      <QIcon
        name="ion-flash"
        class="q-mr-sm"
        size="23px"
      />
      <span v-if="!currentUser.auto_refresh_streams">
        {{
          $t(
            "mention_stream_notification_watcher.new_mention_count_with_duration_button",
            {
              mentionCount: formattedMentionCount,
              duration: parseDate,
            }
          )
        }}
      </span>
      <span v-else>
        {{
          $t("mention_stream_notification_watcher.new_mention_count_button", {
            mentionCount: formattedMentionCount,
          })
        }}
      </span>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import { fromNow } from "shared/helpers/date";
import { prettyNumber } from "shared/helpers/number";
import { useMentionCountsStore } from "shared/stores/mentionCounts";
import { useUserStore } from "shared/stores/user";

export default {
  name: "MentionStreamNotificationWatcher",
  props: {
    stream: {
      type: Object,
      required: true,
    },
    isListView: {
      type: Boolean,
    },
  },
  emits: ["auto-update", "update"],
  setup() {
    const mentionCountsStore = useMentionCountsStore();
    const { mentionCount, streams } = storeToRefs(mentionCountsStore);

    const userStore = useUserStore();
    const { currentUser } = storeToRefs(userStore);

    return {
      mentionCount,
      streams,
      currentUser,
    };
  },
  data() {
    return {
      canUpdate: true,
    };
  },
  computed: {
    formattedMentionCount() {
      return prettyNumber(this.mentionCount(this.stream.id));
    },
    visible() {
      return (
        this.isListView ||
        (!this.isListView && this.mentionCount(this.stream.id))
      );
    },
    parseDate() {
      if (this.mentionCount(this.stream.id)) {
        return fromNow(this.stream.refreshed_at);
      }

      return null;
    },
  },
  watch: {
    streams: {
      deep: true,
      handler() {
        // If auto refresh is on, automatically load mentions when count > 0
        if (
          this.canUpdate &&
          this.currentUser.auto_refresh_streams &&
          this.mentionCount(this.stream.id)
        ) {
          this.$emit("auto-update");

          // Prevent updating while this update is not ended
          this.canUpdate = false;
        }
      },
    },
    stream: {
      deep: true,
      handler() {
        this.canUpdate = true;
      },
    },
  },
  methods: {
    onClick() {
      const { isListView, stream } = this;
      this.$track("Clicked To See New Mentions", {
        isListView,
        streamId: stream.id,
      });
      this.$emit("update");
    },
  },
};
</script>

import { $streemApiV1 } from "shared/boot/api";
import { defaultMentionsFields } from "shared/constants";
import { getRange } from "shared/helpers/date";
import serializeParamsForRequest from "shared/helpers/request";
import { getTypesForStream } from "shared/helpers/sources";
import { useSyndicationStore } from "shared/stores/syndication";

const buildRequestOptions = ({
  stream,
  mentions,
  options = { range: getRange(365) },
}) => {
  if (!getTypesForStream(stream).length) {
    return Promise.resolve({
      data: [],
      headers: { "x-total-count": 0, "x-total-pages": 0 },
    });
  }

  const range = { ...options.range };

  const fields = options.allFields
    ? null
    : options.fields || defaultMentionsFields;
  let timestampProp = "timestamp";

  // convert timestamps to milliseconds if required
  if (options.useMilliseconds) {
    timestampProp = "timestamp_milliseconds";
    range.before *= 1000;
    range.after *= 1000;
  }

  const localMentions = mentions || [];
  const latestMention = options.loadLatestMentions
    ? localMentions[0]
    : localMentions.slice(-1)[0];

  return {
    ...(options.timeout ? { timeout: options.timeout } : {}),
    data: {
      ...(options.filter_most_read_sources
        ? { filter_most_read_sources: options.filter_most_read_sources }
        : {}),
      after: range.after || undefined,
      // use the last mention timestamp unless we are paginating or loading fresh
      before:
        options.keepAlreadyLoadedMentions &&
        !options.page &&
        !options.loadLatestMentions &&
        localMentions.length
          ? latestMention[timestampProp]
          : range.before,
      excerpt_radius: options.excerpt_radius || 60,
      ...(options.collapse_syndicated ? { collapse_syndicated: true } : {}),
      ...(options.bundle_broadcast ? { bundle_broadcast: true } : {}),
      ...(fields ? { fields } : {}),
      limit: options.limit ? options.limit : 10,
      ...(options.sort_options ? { sort_options: options.sort_options } : {}),
      ...(options.sortBy ? { sort_by: options.sortBy } : {}),
      ...(options.sortOrder ? { sort_order: options.sortOrder } : {}),
      ...(options.page ? { page: options.page } : {}),
      ...(options.media ? { media: options.media } : {}),
      ...(options.case_sensitive !== undefined
        ? { case_sensitive: options.case_sensitive }
        : {}),
      ...(options.syndicationKeys
        ? { excluded_syndication_keys: options.syndicationKeys }
        : {}),
      ...(options.useMilliseconds
        ? { timestamps_in_milliseconds: options.useMilliseconds }
        : {}),
      types: getTypesForStream(stream),
      ...(options.types ? { types: options.types } : {}),
      ...(options.includeBookmarkSyndication !== undefined
        ? { include_bookmark_syndication: options.includeBookmarkSyndication }
        : {}),
      ...(options.search ? options.search : {}),
    },
  };
};

const streamClassic = {
  get({ stream, mentions, options }) {
    const requestOptions = buildRequestOptions({ stream, mentions, options });

    return $streemApiV1.post(`streams/${stream.id}/mentions`, requestOptions);
  },
  aggregate(stream, options, range) {
    const params = {
      after: range.after,
      before: range.before,
      ...(options.collapseSyndicated ? { collapse_syndicated: true } : {}),
      ...(options.media ? { media: options.media } : {}),
      ...(options.field ? { field: options.field } : {}),
      types: getTypesForStream(stream),
      ...(options.types ? { types: options.types } : {}),
      ...(options.search ? options.search : {}),
    };

    const serializedParams = serializeParamsForRequest(params);

    return $streemApiV1
      .get(`streams/${stream.id}/aggregate?${serializedParams}`)
      .then((response) => response.data);
  },
  mentionCount({ stream, after, before, search }) {
    const { streamSyndicationKeys } = useSyndicationStore();
    const syndicationKeys = streamSyndicationKeys[stream.id] || [];

    return $streemApiV1.get(`streams/${stream.id}/mention_count`, {
      params: {
        after,
        before,
        limit: 0,
        syndicationKeys,
        ...search,
      },
      handleErrors: false,
    });
  },
  getIds({ stream, mentions, options }) {
    const requestOptions = buildRequestOptions({ stream, mentions, options });

    return $streemApiV1.post(
      `streams/${stream.id}/mention_ids`,
      requestOptions
    );
  },
};

export default streamClassic;

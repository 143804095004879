import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import PodcastEpisode from "./PodcastEpisode";

@Model()
class PodcastEpisodeFragment extends ApplicationRecord {
  static jsonapiType = "podcast_episode_fragments";

  @BelongsTo() podcastEpisode: PodcastEpisode;

  @Attr() text: string;

  @Attr() podcastEpisodeId: number;

  @Attr() speaker: string;

  @Attr() startTimeMs: number;

  @Attr() endTimeMs: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default PodcastEpisodeFragment;

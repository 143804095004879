import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";
import OrganisationBrand from "./OrganisationBrand";
import OrganisationReportSpec from "./OrganisationReportSpec";
import User from "./User";

@Model()
class OrganisationReportSpecGroup extends ApplicationRecord {
  static jsonapiType = "organisation_report_spec_groups";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() userAccount: User;

  @BelongsTo() organisationBrand: OrganisationBrand;

  @HasMany() specifications: OrganisationReportSpec[];

  @Attr() onMonday: boolean;

  @Attr() onTuesday: boolean;

  @Attr() onWednesday: boolean;

  @Attr() onThursday: boolean;

  @Attr() onFriday: boolean;

  @Attr() onSaturday: boolean;

  @Attr() onSunday: boolean;

  @Attr() weekendDeliverAt: number;

  @Attr() weekdayDeliverAt: number;

  @Attr() subject: string;

  @Attr() timeZone: string;

  @Attr() recipients: any[];

  @Attr() useDistributionList: boolean;

  @Attr() organisationId: number;

  @Attr() userAccountId: number;

  @Attr() distributionListId: number;

  @Attr() organisationBrandId: number;
}

export default OrganisationReportSpecGroup;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";
import User from "./User";

@Model()
class EmailSignature extends ApplicationRecord {
  static jsonapiType = "email_signatures";

  @BelongsTo() organisation: Organisation;

  @BelongsTo() user: User;

  @Attr() content: string;

  @Attr() name: string;

  @Attr() organisationId: number;

  @Attr() userId: number;
}

export default EmailSignature;

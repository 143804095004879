import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class Location extends ApplicationRecord {
  static jsonapiType = "locations";

  @BelongsTo() parent: Location;

  @Attr() parentId: number;

  @Attr() type: string;

  @Attr() name: string;

  @Attr() latitude: number;

  @Attr() longitude: number;

  @Attr() visible: boolean;

  @Attr() timeZone: string;

  @Attr() adminVisible: boolean;
}

export default Location;

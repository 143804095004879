import Permissions from "shared/services/Permissions";

export default {
  hasAdvancedAccess(state) {
    const permissions = new Permissions();

    return Boolean(
      permissions.has("has_advanced_access") && !state.customerViewEnabled
    );
  },
  adminUserEnabled(state) {
    return Boolean(state.isAdminUser && !state.customerViewEnabled);
  },
  organisationId(state) {
    return state.currentUser.organisation?.id;
  },
  role(state) {
    return state.currentUser.role;
  },
  primaryOrganisationTeam() {
    return this.currentUser.organisation_teams.find((team) => team.primary);
  },
  sortedUserOrganisationTeams() {
    const primaryOrganisationTeams = [this.primaryOrganisationTeam].filter(
      (team) => team
    );

    const sortedSecondaryTeams = this.currentUser.organisation_teams
      .filter((organisationTeam) => !organisationTeam.primary)
      .sort((secondaryTeamA, secondaryTeamB) =>
        secondaryTeamA.name > secondaryTeamB.name ? 1 : -1
      );

    return primaryOrganisationTeams.concat(sortedSecondaryTeams);
  },
  sortedTeam(state) {
    return state.team
      .slice()
      .sort((userA, userB) => (userA.first_name > userB.first_name ? 1 : -1));
  },
  sortedAllOrganisationTeams() {
    return this.organisation.organisation_teams.sort((teamA, teamB) =>
      teamA.name > teamB.name ? 1 : -1
    );
  },
  sortedOrganisations(state) {
    return [
      state.currentUser.organisation,
      ...(state.currentUser.other_organisations || []),
    ];
  },
};

import endOfDay from "date-fns/endOfDay";
import endOfMonth from "date-fns/endOfMonth";
import startOfDay from "date-fns/startOfDay";
import startOfMonth from "date-fns/startOfMonth";
import subMonths from "date-fns/subMonths";

import { getLocaleText } from "shared/boot/i18n";
import {
  dateToTimestamp,
  formatDate,
  formatIntlDate,
  parseDate,
  subtractTime,
} from "shared/helpers/date";

const rangeOptions = {
  today: {
    label: "Today",
    localeKey: "date.ranges.today",
    daysBack: 1,
    includeToday: true,
  },
  yesterday: {
    label: "Yesterday",
    localeKey: "date.ranges.yesterday",
    daysBack: 2,
    includeToday: false,
  },
  threeDays: {
    label: "Last 3 Days",
    localeKey: "date.ranges.last_3_days",
    daysBack: 3,
    includeToday: true,
  },
  sevenDays: {
    label: "Last 7 Days",
    localeKey: "date.ranges.last_7_days",
    daysBack: 7,
    includeToday: true,
  },
  thirtyDays: {
    label: "Last 30 Days",
    localeKey: "date.ranges.last_30_days",
    daysBack: 30,
    includeToday: true,
  },
  ninetyDays: {
    label: "Last 90 Days",
    localeKey: "date.ranges.last_90_days",
    daysBack: 90,
    includeToday: true,
  },
  oneHundredEightyDays: {
    label: "Last 6 Months",
    localeKey: "date.ranges.last_180_days",
    daysBack: 180,
    includeToday: true,
  },
  currentMonth: {
    label: "This Month",
    localeKey: "date.ranges.this_month",
    monthsBack: 0,
  },
  lastMonth: {
    label: "Last Month",
    localeKey: "date.ranges.last_month",
    monthsBack: 1,
  },
  lastYear: {
    label: "Last 365 Days",
    localeKey: "date.ranges.last_year",
    daysBack: 365,
    includeToday: true,
  },
};

const ONE_HOUR = 3600;
const ONE_DAY = 86400;

const QUARTER_MONTHS = [12, 9, 6, 3];

function asDateString(timestamp, format = "dd MMM yyyy HH:mm") {
  return formatDate(parseDate(timestamp), format);
}

function asTimestamp(dateObject) {
  return dateToTimestamp(dateObject);
}

function getStartOfDay() {
  return startOfDay(new Date());
}

function getEndOfDay() {
  return endOfDay(new Date());
}

/**
 * This class is designed to represent a date range.
 */
export default class DateRange {
  constructor({ before, after, range = null } = {}) {
    this.before = before;
    this.after = after;

    if (range) {
      this.range = range;

      if (range.localeKey) {
        this.range.localeLabel = getLocaleText(range.localeKey);
      }

      this.calculateRange();
    }
  }

  static today() {
    return new DateRange({ range: rangeOptions.today });
  }

  static yesterday() {
    return new DateRange({ range: rangeOptions.yesterday });
  }

  static lastThreeDays() {
    return new DateRange({ range: rangeOptions.threeDays });
  }

  static lastSevenDays() {
    return new DateRange({ range: rangeOptions.sevenDays });
  }

  static lastThirtyDays() {
    return new DateRange({ range: rangeOptions.thirtyDays });
  }

  static lastNinetyDays() {
    return new DateRange({ range: rangeOptions.ninetyDays });
  }

  static lastOneHundredEightyDays() {
    return new DateRange({ range: rangeOptions.oneHundredEightyDays });
  }

  static lastYear() {
    return new DateRange({ range: rangeOptions.lastYear });
  }

  static currentMonth() {
    return new DateRange({ range: rangeOptions.currentMonth });
  }

  static lastMonth() {
    return new DateRange({ range: rangeOptions.lastMonth });
  }

  static lastQuarter() {
    const month = new Date().getMonth() + 1;
    let year = new Date().getFullYear();
    let lastMonthOfQuarter;

    if (month <= 3) {
      year -= 1;
      lastMonthOfQuarter = 12;
    } else {
      lastMonthOfQuarter = QUARTER_MONTHS.find((qMonth) => month > qMonth);
    }

    const before = new Date(new Date(year, lastMonthOfQuarter, 1) - 1);
    const after = new Date(year, lastMonthOfQuarter - 3, 1);

    return DateRange.fromDates(after, before);
  }

  static rangeForLabel(label) {
    switch (label) {
      case "Today":
        return DateRange.today();
      case "Yesterday":
        return DateRange.yesterday();
      case "Last 3 Days":
        return DateRange.lastThreeDays();
      case "Last 7 Days":
        return DateRange.lastSevenDays();
      case "Last 30 Days":
        return DateRange.lastThirtyDays();
      case "Last 90 Days":
        return DateRange.lastNinetyDays();
      case "Last 180 Days":
        return DateRange.lastOneHundredEightyDays();
      case "This Month":
        return DateRange.currentMonth();
      case "Last Month":
        return DateRange.lastMonth();
      case "Last 365 Days":
        return DateRange.lastYear();
      default:
        return DateRange.today();
    }
  }

  static fromDates(after, before) {
    return new DateRange({
      after: asTimestamp(after),
      before: asTimestamp(before),
    });
  }

  static clone(dateRange) {
    return new DateRange(dateRange.attributes());
  }

  get span() {
    return this.before - this.after;
  }

  get interval() {
    return this.span <= 24 * ONE_HOUR ? "1h" : "1d";
  }

  get rangeText() {
    const { span } = this;

    if (span < ONE_HOUR)
      return getLocaleText("date.pretty_ranges.last_minutes", {
        span: Math.round(span / 60),
      });
    if (span < ONE_DAY)
      return getLocaleText("date.pretty_ranges.last_hours", {
        span: Math.round(span / ONE_HOUR),
      });
    if (span === ONE_DAY) return getLocaleText("date.pretty_ranges.last_day");

    return getLocaleText("date.pretty_ranges.last_days", {
      span: Math.round(span / ONE_DAY),
    });
  }

  get prettyRangeText() {
    const label = this.range?.label;
    const localeLabel = this.range?.localeLabel;

    switch (label) {
      case "Today":
      case "Yesterday":
        return getLocaleText("date.pretty_ranges.today_yesterday");
      case "Last 3 Days":
      case "Last 7 Days":
      case "Last 30 Days":
      case "Last 90 Days":
      case "Last 180 Days":
        return localeLabel.toLowerCase();
      case "This Month": {
        const days = Math.round(this.span / ONE_DAY);

        return getLocaleText("date.pretty_ranges.this_month", { days });
      }
      case "Last Month":
        return getLocaleText("date.pretty_ranges.last_month");
      case "Last 365 Days":
        return getLocaleText("date.pretty_ranges.last_year");
      default:
        return getLocaleText("date.pretty_ranges.default", {
          rangeText: this.rangeText,
        });
    }
  }

  get spanIntervals() {
    return {
      minutes: Math.ceil(this.span / 60),
      hours: Math.ceil(this.span / ONE_HOUR),
      days: Math.ceil(this.span / (24 * ONE_HOUR)),
    };
  }

  attributes() {
    return {
      before: this.before,
      after: this.after,
      range: this.range,
    };
  }

  equals(dateRange) {
    if (!(dateRange instanceof DateRange)) return false;

    return (
      JSON.stringify(this.attributes()) ===
      JSON.stringify(dateRange.attributes())
    );
  }

  toString(format = "d MMM") {
    return this.range
      ? this.range.localeLabel
      : getLocaleText("date.pretty_ranges.range", {
          after: this.afterAsString(format),
          before: this.beforeAsString(format),
        });
  }

  toIntlString(options = {}) {
    return this.range
      ? this.range.localeLabel
      : getLocaleText("date.pretty_ranges.range", {
          after: formatIntlDate(this.after, options),
          before: formatIntlDate(this.before, options),
        });
  }

  beforeAsString(format = "dd MMM yyyy HH:mm") {
    return asDateString(this.before, format);
  }

  afterAsString(format = "dd MMM yyyy HH:mm") {
    return asDateString(this.after, format);
  }

  calculateRange() {
    if (this.range.monthsBack !== undefined) {
      const endOfCurrentDay = endOfDay(new Date());
      const after = subMonths(
        startOfMonth(getStartOfDay()),
        this.range.monthsBack
      );
      let before = endOfDay(endOfMonth(after));

      if (before > endOfCurrentDay) before = endOfCurrentDay;

      this.after = asTimestamp(after);
      this.before = asTimestamp(before);
    } else if (this.range.fromNow) {
      const now = new Date();
      this.before = asTimestamp(now);
      this.after = asTimestamp(subtractTime(now, this.range.daysBack, "day"));
    } else {
      this.after = asTimestamp(
        subtractTime(getStartOfDay(), this.range.daysBack - 1, "day")
      );
      this.before = asTimestamp(
        this.range.includeToday ? getEndOfDay() : getStartOfDay()
      );
    }
  }

  /*
    In order to enure chart labels display properly, justifyRange will round the
    range up to the nearest interval when there are an odd number of intervals
    to ensure that the labels can be spread evenly across a range. This will
    ensure the return of an odd number of buckets which will spread evenly.
  */
  justifyRange(interval) {
    const { span } = this;
    const intervalType = interval[interval.length - 1];
    const intervalSize = interval.slice(0, interval.length - 1);

    const intervalSeconds = {
      s: 1,
      m: 60,
      h: 3600,
      d: 86400,
    };
    const secondsPerInterval = intervalSize * intervalSeconds[intervalType];
    const intervalsOdd = Number(span / secondsPerInterval) % 2 !== 0;

    const leftOverFromRange = span % secondsPerInterval;
    const secondsToAdd = secondsPerInterval - leftOverFromRange;

    if (intervalsOdd) {
      this.before += secondsToAdd;
    }
  }
}

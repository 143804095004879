import { defineStore } from "pinia";

import { $streemApiV1 } from "shared/boot/api";
import { getCurrentISODate, getCurrentTimestamp } from "shared/helpers/date";
import { Stream } from "shared/resources";

import useGlobalStore from "../global";
import useStreamsStore from "../streams";
import { useUserStore } from "../user";

export type MentionCountStreams = {
  [key: string]: {
    count: number;
    refreshAt: number;
  };
};

export type UnreadMentionCounts = {
  [key: string]: { unreadCount: number; streamRefreshedAt: number };
};

interface RootState {
  streams: MentionCountStreams;
}

const sum = (left: number, right: number) => left + right;

export const useMentionCountsStore = defineStore("mentionCountsStore", {
  state: (): RootState => ({
    streams: {},
  }),

  getters: {
    totalMentionsCount(state: RootState) {
      Object.keys(state.streams)
        .map((id) => state.streams[id].count)
        .reduce(sum, 0);
    },
    mentionCount: (state: RootState) => (id: string) => {
      const stream = state.streams[id];

      return stream ? stream.count : 0;
    },
  },
  actions: {
    fetchMentionCounts(params: { streamIds: string[] }) {
      if (!useUserStore().isLoggedIn || useGlobalStore().technicalServerError) {
        return;
      }

      $streemApiV1
        .get<UnreadMentionCounts>("streams/unread_mention_counts", {
          headers: {
            "Key-Inflection": "camel",
          },
          params,
        })
        .then(({ data }) => {
          Object.entries(data).forEach(([id, value]) => {
            const count = Number.isInteger(value)
              ? Number(value)
              : value.unreadCount;
            const refreshedAt = value?.streamRefreshedAt;

            this.setMentionCount({ stream: { id }, count });

            if (refreshedAt) {
              useStreamsStore().putStream({
                id,
                data: {
                  refreshed_at: refreshedAt,
                  onlyLastSeenUpdated: true,
                },
              });
            }
          });
        })
        .catch((error) => error);
    },

    clearMentionsCount(stream: Partial<Stream> & { resetByGroup: boolean }) {
      this.setMentionCount({ stream, count: 0 });

      useStreamsStore().putStream({
        id: stream.id,
        data: {
          refreshed_at: getCurrentISODate(),
          onlyLastSeenUpdated: true,
          resetByGroup: stream.resetByGroup,
        },
      });

      return $streemApiV1.put<Stream>(`streams/${stream.id}`, {
        params: {
          refreshed_at: getCurrentTimestamp(),
        },
      });
    },
    clearStreamsMentionsCount(streams: Stream[]) {
      streams.forEach((stream) => {
        if (this.mentionCount(stream.id!) > 0) {
          this.clearMentionsCount({ ...stream, resetByGroup: true });
        }
      });
    },
    setMentionCount({
      stream,
      count,
      refreshAt = Date.now(),
    }: {
      stream: Partial<Stream>;
      count: number;
      refreshAt?: number;
    }) {
      this.streams = {
        ...this.streams,
        [stream.id!]: {
          ...this.streams[stream.id!],
          count,
          refreshAt,
        },
      };
    },
  },
});

export default useMentionCountsStore;

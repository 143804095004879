import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Organisation from "./Organisation";
import SourceRecord from "./SourceRecord";

@Model()
class SourceRestriction extends ApplicationRecord {
  static jsonapiType = "source_restrictions";

  @BelongsTo() source: SourceRecord;

  @HasMany() organisations: Organisation[];

  @Attr() sourceId: string;

  @Attr() sourceType: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default SourceRestriction;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import ContactList from "./ContactList";
import MediaRelease from "./MediaRelease";

@Model()
class MediaReleasesContactList extends ApplicationRecord {
  static jsonapiType = "media_releases_contact_lists";

  @BelongsTo() mediaRelease: MediaRelease;

  @BelongsTo() contactList: ContactList;

  @Attr() mediaReleaseId: number;

  @Attr() contactListId: number;
}

export default MediaReleasesContactList;

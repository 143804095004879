import { HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import QuerySnippet from "./QuerySnippet";

@Model()
class FilterSet extends ApplicationRecord {
  static jsonapiType = "filter_sets";

  @HasMany() querySnippets: QuerySnippet[];
}

export default FilterSet;

import { BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Influencer from "./Influencer";
import MdmTopic from "./MdmTopic";

@Model()
class InfluencersTopic extends ApplicationRecord {
  static jsonapiType = "influencers_topics";

  @BelongsTo() influencer: Influencer;

  @BelongsTo() mdmTopic: MdmTopic;
}

export default InfluencersTopic;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import BriefSet from "./BriefSet";
import Stream from "./Stream";

@Model()
class BriefSetsStreams extends ApplicationRecord {
  static jsonapiType = "brief_sets_streams";

  @BelongsTo() stream: Stream;

  @BelongsTo() briefSet: BriefSet;

  @Attr() primary: boolean;

  @Attr() streamId: number;

  @Attr() briefSetId: number;
}

export default BriefSetsStreams;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Contact from "./Contact";
import ContactList from "./ContactList";
import ContactMailingList from "./ContactMailingList";
import MediaRelease from "./MediaRelease";

@Model()
class MediaReleaseRecipient extends ApplicationRecord {
  static jsonapiType = "media_release_recipients";

  @BelongsTo() contact: Contact;

  @BelongsTo() mediaRelease: MediaRelease;

  @BelongsTo() contactList: ContactList;

  @BelongsTo() contactMailingList: ContactMailingList;

  @Attr() mediaReleaseId: number;

  @Attr() contactListId: number;

  @Attr() contactMailingListId: number;

  @Attr() contactId: number;

  @Attr() contactType: string;

  @Attr() subject: string;

  @Attr() message: string;

  @Attr({ persist: false }) personalised: boolean;

  @Attr({ persist: false }) name: string;

  @Attr({ persist: false }) email: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default MediaReleaseRecipient;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import AccountManager from "./AccountManager";
import Organisation from "./Organisation";

@Model()
class OrganisationsManager extends ApplicationRecord {
  static jsonapiType = "organisations_managers";

  static notArchived() {
    return this.where({ archived: { eq: false } });
  }

  @BelongsTo() organisation: Organisation;

  @BelongsTo() manager: AccountManager;

  @Attr() managerId: number;

  @Attr() managerType: string;

  @Attr({ persist: false }) name: string;

  @Attr() primary: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default OrganisationsManager;

<template>
  <div
    v-if="single"
    class="tw-flex"
  >
    <QRadio
      v-for="source in availableMedia"
      :key="source.field"
      v-model="localMedium"
      :val="source.field"
      :label="source.label"
      :color="source.field"
      :style="inlineStyle"
      keep-color
      @update:model-value="radioToggled(source, $event)"
    />
  </div>
  <div
    v-else
    class="tw-flex tw-flex-wrap"
  >
    <Checkbox
      v-for="source in availableMedia"
      :key="source.field"
      v-model="localStream[source.property]"
      :label="source.label"
      :class="'color-' + source.field"
      :style="inlineStyle"
      :new-design="newDesign"
      :dense="dense"
      @update:model-value="checkboxToggled(source.label, $event)"
    />
  </div>
</template>

<script>
import { getSources, getSourcesForStream } from "shared/helpers/sources";

export default {
  name: "MediumSelector",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    excludedMedia: {
      type: Array,
      default: () => [],
    },
    columnsPerRow: {
      type: Number,
      default: 3,
    },
    single: {
      type: Boolean,
    },
    newDesign: {
      type: Boolean,
    },
    dense: {
      type: Boolean,
    },
  },
  emits: ["checkbox-toggled", "update:modelValue"],
  data() {
    return {
      sources: getSources()
        .filter(({ filterEnabled }) => filterEnabled)
        .map(({ field, label, color }) => ({
          field,
          label,
          color,
          property: `${field}_content`,
        })),
      localStream: JSON.parse(JSON.stringify(this.modelValue)),
      localMedium: getSourcesForStream(this.modelValue)[0]?.field,
    };
  },
  computed: {
    availableMedia() {
      return this.sources.filter(
        ({ medium }) => !this.excludedMedia.includes(medium)
      );
    },
    inlineStyle() {
      return { width: `${100 / this.columnsPerRow}%` };
    },
  },
  watch: {
    modelValue: {
      deep: true,
      immediate: true,
      handler() {
        this.localStream = JSON.parse(JSON.stringify(this.modelValue));
      },
    },
  },
  methods: {
    checkboxToggled(medium, value) {
      this.$emit("checkbox-toggled", { medium, value });
      this.$emit("update:modelValue", this.localStream);
    },
    radioToggled(toggledSource) {
      this.availableMedia.forEach((source) => {
        this.localStream[source.property] =
          source.field === toggledSource.field;
      });
      this.$emit("update:modelValue", this.localStream);
    },
  },
};
</script>

<template>
  <div>
    <div
      v-for="media in availableMedia"
      :key="media.field"
      class="row"
    >
      <Checkbox
        v-model="stream[`${media.field}_content`]"
        :label="media.label"
        :class="['color-' + media.field]"
        class="checkbox bold text-uppercase"
      />
    </div>
  </div>
</template>

<script>
import { getSources } from "shared/helpers/sources";

export default {
  name: "UniversalSearchMediaTypeFilter",
  props: {
    modelValue: {
      type: Object,
      required: true,
    },
    excludedMedia: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      sources: getSources(),
    };
  },
  computed: {
    stream: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
    availableMedia() {
      return this.sources.filter(
        ({ medium }) => !this.excludedMedia.includes(medium)
      );
    },
  },
  mounted() {
    this.sources = getSources();
  },
};
</script>

<style lang="scss" scoped>
.desktop .checkbox {
  margin: 4px 0;
}
</style>

import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class ProgramAiring extends ApplicationRecord {
  static jsonapiType = "program_airings";

  @Attr() name: string;

  @Attr() description: string;

  @Attr() startTime: number;

  @Attr() endTime: number;
}

export default ProgramAiring;

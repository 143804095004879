import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import OrganisationReportSpec from "./OrganisationReportSpec";

@Model()
class OrganisationReportSpecDateRange extends ApplicationRecord {
  static jsonapiType = "organisation_report_spec_date_ranges";

  @BelongsTo() specification: OrganisationReportSpec;

  @Attr() dateFrom: string;

  @Attr() dateTo: string;
}

export default OrganisationReportSpecDateRange;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import BroadcastRecorder from "./BroadcastRecorder";
import TvChannel from "./TvChannel";

@Model()
class TvChannelsBroadcastRecorder extends ApplicationRecord {
  static jsonapiType = "tv_channels_broadcast_recorders";

  @BelongsTo() tvChannel: TvChannel;

  @BelongsTo() broadcastRecorder: BroadcastRecorder;

  @Attr() primary: boolean;
}

export default TvChannelsBroadcastRecorder;

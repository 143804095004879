import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import MediaRelease from "./MediaRelease";
import Organisation from "./Organisation";
import User from "./User";

@Model()
class MediaReleaseAdhocRecipient extends ApplicationRecord {
  static jsonapiType = "media_release_adhoc_recipients";

  @BelongsTo() mediaRelease: MediaRelease;

  @BelongsTo() user: User;

  @BelongsTo() organisation: Organisation;

  @Attr() mediaReleaseId: number;

  @Attr() userId: number;

  @Attr() organisationId: number;

  @Attr() salutation: string;

  @Attr() firstName: string;

  @Attr() lastName: string;

  @Attr() email: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default MediaReleaseAdhocRecipient;

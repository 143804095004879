<template>
  <div
    :class="{ active }"
    class="no-shrink"
  >
    <div class="q-gutter-y-sm">
      <label
        v-if="!hideSources"
        class="block"
      >
        <span
          v-if="label"
          class="block bold q-mb-sm"
        >
          {{ label }}
        </span>

        <div v-if="excludedSources.length">
          {{ $t("sources_expandable.sources_not_available") }}
        </div>

        <FiltersAutocomplete
          v-else
          v-model="handleSources"
          keep-expanded
          :stream="stream"
          :media="media"
          :filter-type="sourceFilterType"
        />
      </label>

      <label
        v-if="!hideExcludedSources"
        class="block"
      >
        <span class="block bold q-mb-sm">{{
          $t("sources_expandable.excluded_sources")
        }}</span>

        <div v-if="sources.length">
          {{ $t("sources_expandable.excluded_sources_not_available") }}
        </div>

        <FiltersAutocomplete
          v-else
          v-model="handleExcludedSources"
          keep-expanded
          :stream="stream"
          :media="media"
          :filter-type="sourceFilterType"
        />
      </label>

      <label
        v-if="!hideAuthors"
        class="block"
      >
        <span class="bold block q-mb-sm">{{
          $t("sources_expandable.authors")
        }}</span>

        <FiltersAutocomplete
          v-model="handleAuthors"
          :stream="stream"
          filter-type="author"
        />
      </label>

      <div v-if="showTiers">
        <label class="bold block q-py-sm">
          {{ $t("sources_expandable.tiers") }}
        </label>
        <TierFilters
          v-model="handleTiers"
          :stream="stream"
        />
      </div>

      <div v-if="showCategories">
        <label class="bold block q-py-sm">
          {{ $t("sources_expandable.categories") }}
        </label>
        <QCheckbox
          v-model="handleCategories"
          val="National"
          :label="$t('sources_expandable.national')"
          class="bold text-uppercase color-primary"
          color="white"
        />
        <QCheckbox
          v-model="handleCategories"
          val="Metro"
          :label="$t('sources_expandable.metro')"
          class="bold text-uppercase color-primary"
          color="white"
        />
        <QCheckbox
          v-model="handleCategories"
          val="Regional"
          :label="$t('sources_expandable.regional')"
          class="bold text-uppercase color-primary"
          color="white"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FiltersAutocomplete from "shared/components/core/forms/FiltersAutocomplete.vue";
import TierFilters from "shared/components/core/forms/TierFilters.vue";

export default {
  name: "SourcesExpandable",
  components: {
    FiltersAutocomplete,
    TierFilters,
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
    media: {
      type: Array,
      default: () => [],
    },
    sources: {
      type: Array,
      default: () => [],
    },
    excludedSources: {
      type: Array,
      default: () => [],
    },
    authors: {
      type: Array,
      default: () => [],
    },
    categories: {
      type: Array,
      default: () => [],
    },
    tiers: {
      type: Array,
      default: () => [],
    },
    hideAuthors: {
      type: Boolean,
    },
    showCategories: {
      type: Boolean,
    },
    showTiers: {
      type: Boolean,
    },
    hideSources: {
      type: Boolean,
    },
    hideExcludedSources: {
      type: Boolean,
    },
    active: {
      type: Boolean,
    },
    label: {
      type: String,
      default: "",
    },
    searchAllSources: {
      type: Boolean,
    },
  },
  emits: [
    "update:sources",
    "search",
    "update:excludedSources",
    "update:authors",
    "update:categories",
    "update:tiers",
  ],
  computed: {
    handleSources: {
      get() {
        return this.sources;
      },
      set(val) {
        this.$emit("update:sources", val);
        this.$emit("search");
      },
    },
    handleExcludedSources: {
      get() {
        return this.excludedSources;
      },
      set(val) {
        this.$emit("update:excludedSources", val);
        this.$emit("search");
      },
    },
    handleAuthors: {
      get() {
        return this.authors;
      },
      set(val) {
        this.$emit("update:authors", val);
        this.$emit("search");
      },
    },
    handleCategories: {
      get() {
        return this.categories;
      },
      set(val) {
        this.$emit("update:categories", val);
        this.$emit("search");
      },
    },
    handleTiers: {
      get() {
        return this.tiers;
      },
      set(val) {
        this.$emit("update:tiers", val);
        this.$emit("search");
      },
    },
    sourceFilterType() {
      return this.searchAllSources ? "all_sources" : "sources";
    },
  },
};
</script>

<style lang="scss" scoped>
.label-extra {
  font-weight: normal;
  color: $ds-denim-7;
}

.text-input {
  border: 1px solid $silver-white;
  width: 100%;
}

.active {
  background-color: #fff;
}

:deep(.q-checkbox__bg) {
  height: 20px;
  width: 20px;
  min-height: 20px;
  min-width: 20px;
  background-color: #ebebeb;
  border-radius: 4px;
  cursor: pointer;
  border: none;
}

:deep(.q-checkbox__svg) {
  padding: 4px;
}

:deep(.q-checkbox__inner) {
  font-size: 35px;
}

:deep(.q-checkbox__inner::before) {
  background: none !important;
}

:deep(.q-checkbox__label) {
  font-size: 14px;
}
</style>

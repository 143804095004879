<template>
  <span
    :style="tagStyles"
    class="tag"
    :class="{ 'cursor-pointer': !hideCursor }"
    @click="$emit('click', $event)"
  >
    <EllipseContent class="tag-label">
      {{ label }}
    </EllipseContent>

    <slot name="close-button">
      <span
        v-if="!hideRemoveButton"
        class="tag-icon"
      >
        <BaseIcon
          icon="close"
          class="cursor-pointer"
          @click.stop.prevent="$emit('remove')"
        />
      </span>
    </slot>

    <slot />
  </span>
</template>

<script setup lang="ts">
import { computed } from "vue";

import BaseIcon from "shared/components/base/BaseIcon.vue";
import EllipseContent from "shared/components/base/EllipseContent.vue";
import { defaultTagColor, tagPalette } from "shared/constants";

interface ColorPallete {
  fontColor: string;
}

interface ColorPalletes {
  [key: string]: ColorPallete;
}

interface Props {
  label: string;
  color?: string;
  palette?: ColorPalletes;
  hideRemoveButton?: boolean;
  hideCursor?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  color: defaultTagColor,
  palette: () => tagPalette,
});

defineEmits(["click", "remove"]);

const colorPalette = computed<ColorPalletes>(() => props.palette);

const tagStyles = computed(() => ({
  "background-color": props.color || defaultTagColor,
  color: colorPalette.value[props.color]?.fontColor || "white",
}));
</script>

<style lang="scss" scoped>
.tag {
  background: var(--s-color-primary);
  color: var(--s-color-font-contrast);
  max-width: 100%;
  border-radius: 3px;
  padding: 0.3em 0.7em;
  font-size: 13px;
  width: fit-content;
  user-select: none;
  white-space: nowrap;
  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  gap: var(--s-spacing-sm);

  &-icon {
    height: 1em;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-size: inherit;

    &:hover {
      opacity: 0.5;
    }
  }
}
</style>

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import BriefSet from "./BriefSet";
import User from "./User";

@Model()
class BriefSetsUser extends ApplicationRecord {
  static jsonapiType = "brief_sets_users";

  @BelongsTo() briefSet: BriefSet;

  @BelongsTo() user: User;

  @Attr() userId: number;

  @Attr() briefSetId: number;

  @Attr({ persist: false }) archivedAt: string;
}

export default BriefSetsUser;

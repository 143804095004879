import { Attr, HasMany, Model } from "spraypaint";

import { $streemApiV1 } from "shared/boot/api";
import ApplicationRecord from "shared/services/spraypaint";

import AllowedSenderEmailAddress from "./AllowedSenderEmailAddress";
import type { EmailDomainDnsRecord } from "./types";

@Model()
class EmailDomain extends ApplicationRecord {
  static jsonapiType = "email_domains";

  @HasMany() allowedSenderEmailAddresses: AllowedSenderEmailAddress[];

  @Attr() cname: EmailDomainDnsRecord;

  @Attr() externalId: string;

  @Attr() dkim: EmailDomainDnsRecord[];

  @Attr() mx: EmailDomainDnsRecord[];

  @Attr() name: string;

  @Attr() organisationId: BigInteger;

  @Attr() organisationTeamId: BigInteger;

  @Attr() spf: EmailDomainDnsRecord;

  @Attr() status: string;

  async archive() {
    await $streemApiV1.put(`email_domains/${this.id}/archive`);
  }

  async verify() {
    await $streemApiV1.post(`email_domains/${this.id}/verify`);
  }
}

export default EmailDomain;

import { HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Version from "./Version";

@Model()
class QualityControlRule extends ApplicationRecord {
  static jsonapiType = "quality_control_rule";

  @HasMany() versions: Version[];
}

export default QualityControlRule;

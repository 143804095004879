import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import User from "./User";

@Model()
class SocialAnalyseReport extends ApplicationRecord {
  static jsonapiType = "social_analyse_reports";

  @BelongsTo() user: User;

  @Attr() userId: number;

  @Attr() organisationTeamId: number;

  @Attr() name: string;

  @Attr() rangeLabel: string;

  @Attr() before: number;

  @Attr() after: number;

  @Attr() socialStreamIds: number[];

  @Attr() selectedSocialContent: string[];

  @Attr() selectedWidgets: any[];

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default SocialAnalyseReport;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import User from "./User";

@Model()
class TaggedMentionsReport extends ApplicationRecord {
  static jsonapiType = "tagged_mentions_reports";

  @BelongsTo() user: User;

  @Attr() subject: string;

  @Attr() before: number;

  @Attr() after: number;

  @Attr() recipients: string[];

  @Attr() content: string[];

  @Attr() fileUrl: string;

  @Attr() htmlUrl: string;

  @Attr() format: string;

  @Attr() status: string;

  @Attr() createdAt: string;
}

export default TaggedMentionsReport;

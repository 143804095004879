import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import InstagramHandle from "./InstagramHandle";

@Model()
class InstagramPost extends ApplicationRecord {
  static jsonapiType = "instagram_posts";

  @BelongsTo() instagramHandle: InstagramHandle;

  @Attr() url: string;

  @Attr() body: string;

  @Attr() imageUrl: string;

  @Attr() videoUrl: string;

  @Attr() userName: string;

  @Attr() publishedAt: string;

  @Attr() instagramHandleId: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default InstagramPost;

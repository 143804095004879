<template>
  <div>
    <span
      class="block q-pa-sm cursor-pointer hover-active"
      outline
      @click="open"
    >
      <span class="bold">{{
        $t("universal_search_actionsheet_select_source_filter.sources")
      }}</span>
      {{ selectedSourcesLabel }}
    </span>

    <QDialog
      v-model="show"
      position="bottom"
      square
    >
      <QCard class="full-width overflow-hidden">
        <QCardSection class="header row justify-between q-pa-md">
          <QBtn
            :label="
              $t('universal_search_actionsheet_select_source_filter.back')
            "
            size="12px"
            dense
            flat
            @click="close"
          />
          <span class="title bold block">{{
            $t("universal_search_actionsheet_select_source_filter.sources")
          }}</span>
          <QBtn
            :label="
              $t('universal_search_actionsheet_select_source_filter.done')
            "
            size="12px"
            dense
            flat
            @click="saveAndClose"
          />
        </QCardSection>

        <FiltersAutocomplete
          v-model="localSources"
          class="bottom-space"
          keep-expanded
          :stream="localStream"
          :media="searchEnabledMedia"
          :placeholder="displayPlaceholder"
          filter-type="sources"
        />
      </QCard>
    </QDialog>
  </div>
</template>

<script>
import FiltersAutocomplete from "shared/components/core/forms/FiltersAutocomplete.vue";
import { getMediaForStream } from "shared/helpers/sources";

export default {
  name: "UniversalSearchActionsheetSelectSourceFilter",
  components: {
    FiltersAutocomplete,
  },
  props: {
    modelValue: {
      type: Array,
      required: true,
    },
    stream: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      show: false,
      localSources: [...this.modelValue],
      localStream: this.stream,
    };
  },
  computed: {
    displayPlaceholder() {
      return this.$isMobile
        ? this.$t("universal_search_actionsheet_select_source_filter.sources")
        : "";
    },
    selectedSourcesLabel() {
      if (this.localSources.length) return `(${this.localSources.length})`;

      return "";
    },
    searchEnabledMedia() {
      return getMediaForStream(this.stream);
    },
  },
  watch: {
    modelValue: {
      deep: true,
      handler() {
        this.localSources = [...this.modelValue];
      },
    },
  },
  methods: {
    open() {
      this.localSources = [...this.modelValue];
      this.show = true;
    },
    close() {
      this.show = false;
    },
    saveAndClose() {
      this.$emit("update:modelValue", this.localSources);
      this.close();
    },
  },
};
</script>

<style lang="scss" scoped>
.header {
  border-bottom: 1px solid #ddd;
}

.title {
  font-size: 19px;
}

.bottom-space {
  height: 550px;
  max-height: 550px;
}
</style>

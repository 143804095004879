import { flatten, get, orderBy, uniqBy } from "lodash-es";

import { getLocaleText } from "shared/boot/i18n";
import { sentimentOptions } from "shared/components/core/pickers/SentimentPicker";
import { getSource } from "shared/helpers/sources";
import ModalService from "shared/modals/ModalService";
import OrganisationReportService from "shared/services/api/OrganisationReportService";
import features from "shared/services/features";
import { Stream } from "shared/types";
import {
  ArticleSyndication,
  Excerpts,
  Mention,
  MentionType,
  Sentiment,
  TvCaptionSyndication,
} from "shared/types/mentions";
import { isMentionWithSyndications } from "shared/types/mentions/guards";

import { isNumber } from "./number";

export enum SortOptionField {
  AUDIENCE = "audience",
  TIMESTAMP = "timestamp",
  AUTHOR = "author.raw",
  SOURCE_NAME = "source.name",
  WORD_COUNT = "word_count",
  ADVERTISEMENT_RATE = "advertisement_rate",
  ENRICHMENT_SCORE = "risk_enrichment.risk_score",
  ENRICHMENT_FAKENESS = "risk_enrichment.aggregate_fakeness",
  ENRICHMENT_EMOTIONALITY = "risk_enrichment.aggregate_emotionality",
  ENRICHMENT_HARMFUL = "risk_enrichment.aggregate_harmful",
  ENRICHMENT_SPAM = "risk_enrichment.aggregate_spam",
}

interface SortOption {
  label: string;
  field: SortOptionField;
  key: SortOptionField;
}

export enum SortOptionOrder {
  DESC = "desc",
  ASC = "asc",
}

export enum SortOptionMissing {
  FIRST = "_first",
  LAST = "_last",
}

interface MentionSortOption {
  sort_by: SortOptionField;
  sort_order: SortOptionOrder;
  missing: SortOptionMissing;
}

interface MentionOptions {
  sort_options: MentionSortOption[];
}

interface OrderOption {
  label: string;
  field: SortOptionOrder;
}

function getSortOptions(): SortOption[] {
  return [
    {
      label: getLocaleText("helpers.mentions.sort_options.timestamp"),
      field: SortOptionField.TIMESTAMP,
      key: SortOptionField.TIMESTAMP,
    },
    {
      label: getLocaleText("helpers.mentions.sort_options.author"),
      field: SortOptionField.AUTHOR,
      key: SortOptionField.TIMESTAMP,
    },
    {
      label: getLocaleText("helpers.mentions.sort_options.source"),
      field: SortOptionField.SOURCE_NAME,
      key: SortOptionField.SOURCE_NAME,
    },
    {
      label: getLocaleText("helpers.mentions.sort_options.word_count"),
      field: SortOptionField.WORD_COUNT,
      key: SortOptionField.WORD_COUNT,
    },
    {
      label: getLocaleText("helpers.mentions.sort_options.audience"),
      field: SortOptionField.AUDIENCE,
      key: SortOptionField.AUDIENCE,
    },
  ];
}

function getOrderOptions(): OrderOption[] {
  return [
    {
      label: getLocaleText("helpers.mentions.order_options.desc"),
      field: SortOptionOrder.DESC,
    },
    {
      label: getLocaleText("helpers.mentions.order_options.asc"),
      field: SortOptionOrder.ASC,
    },
  ];
}

const excludedMediaItemSortFields: SortOptionField[] = [
  SortOptionField.AUTHOR,
  SortOptionField.WORD_COUNT,
  SortOptionField.AUDIENCE,
];

const excludedTranscriptRequestSortFields = [
  SortOptionField.AUTHOR,
  SortOptionField.SOURCE_NAME,
  SortOptionField.WORD_COUNT,
  SortOptionField.AUDIENCE,
];

function sortingField(sortKey: SortOptionField) {
  const excludedWords = ["the"];
  const regex = new RegExp(excludedWords.join("|"));

  let coerceToNumber = false;

  if (sortKey === SortOptionField.AUDIENCE) coerceToNumber = true;

  return (mention: Mention) => {
    const field: string | number = get(mention, sortKey, 0);

    if (coerceToNumber) return field ? parseInt(field as string, 10) : 0;

    return typeof field === "string"
      ? field.toLowerCase().replace(regex, "").trim()
      : field;
  };
}

function sortMentions(
  mentions: Mention[],
  options: MentionOptions = { sort_options: [] }
): Mention[] {
  const sortFields = options.sort_options.map((opt) => opt.sort_by);
  const sortOrders = options.sort_options.map((opt) => opt.sort_order);
  const functions = sortFields.map((sortKey) => sortingField(sortKey));

  return orderBy(mentions, functions, sortOrders);
}

function processMentions(
  mentions: Mention[],
  options: MentionOptions = { sort_options: [] }
): Mention[] {
  return sortMentions(mentions, options);
}

function normalizedKeywords(keywords: string[] = []): string[] {
  return uniqBy(keywords, (keyword) => keyword.toLowerCase());
}

function excerptsKeywords(excerpts: Excerpts[] = []): string[] {
  return normalizedKeywords(
    flatten(excerpts.map(({ keywords }) => keywords || []))
  );
}

function sortedSyndications(
  mention: Mention
): ArticleSyndication[] | TvCaptionSyndication[] {
  if (!isMentionWithSyndications(mention)) {
    return [];
  }

  return mention.syndications
    .slice()
    .sort((syndicationA, syndicationB) =>
      syndicationA.source_name > syndicationB.source_name ? 1 : -1
    );
}

function mentionKeywords(mention: Mention): string[] {
  if (Array.isArray(mention.keywords)) return mention.keywords;

  if (
    mention.keywords &&
    typeof mention.keywords === "object" &&
    mention.keywords.constructor === Object
  ) {
    return Object.keys(mention.keywords);
  }

  if (mention.excerpts && mention.excerpts.length) {
    return excerptsKeywords(mention.excerpts);
  }

  return [];
}

function mediaId(mention: Mention): string {
  return (
    {
      article: "ON",
      magazine_article: "MA",
      podcast_episode: "PE",
      paper_article: "PR",
      caption: "TC",
      tv_caption: "TC",
      tv_super: "TS",
      tv_logo_appearance: "TL",
      radio_clip: "RA",
      tweet: "TW",
      facebook_post: "FP",
      youtube_video: "YV",
      instagram_post: "IP",
      reddit_post: "RP",
      reddit_post_comment: "RC",
      blog_post: "BP",
      forum_post: "FR",
      customer_article: "CA",
    }[mention.type as Exclude<MentionType, MentionType.external_item>] +
    mention.id
  );
}

function targetType(mention: Mention): string {
  return mention.type === "external_item"
    ? "ExternalItem"
    : getSource(mention.type).relatedCamelCaseField || "";
}

function compositeIdToMention(compositeId: string) {
  const mention = compositeId.split("_");

  return {
    id: Number(mention.at(-1)),
    type: mention.slice(0, -1).join("_"),
  };
}

function formattedMedium(mention: Mention) {
  return mention.medium.toLowerCase();
}

function getSortPossibilities(): SortOption[] {
  const possibilities = [
    {
      options: Array.from(getSortOptions()),
    },
  ];

  const defaultGroupOptions = possibilities[0].options;

  if (features.has("advertising_value_mentions")) {
    defaultGroupOptions.push({
      label: getLocaleText("helpers.mentions.sort_options.advertisement_rate"),
      field: SortOptionField.ADVERTISEMENT_RATE,
      key: SortOptionField.ADVERTISEMENT_RATE,
    });
  }

  if (features.has("has_react_score")) {
    defaultGroupOptions.push({
      label: getLocaleText(
        "helpers.mentions.sort_options.risk_enrichment_react_score"
      ),
      field: SortOptionField.ENRICHMENT_SCORE,
      key: SortOptionField.ENRICHMENT_SCORE,
    });

    possibilities.push({
      options: [
        {
          label: getLocaleText(
            "helpers.mentions.sort_options.react_enrichment_aggregate_fakeness"
          ),
          field: SortOptionField.ENRICHMENT_FAKENESS,
          key: SortOptionField.ENRICHMENT_FAKENESS,
        },
        {
          label: getLocaleText(
            "helpers.mentions.sort_options.react_enrichment_aggregate_emotionality"
          ),
          field: SortOptionField.ENRICHMENT_EMOTIONALITY,
          key: SortOptionField.ENRICHMENT_EMOTIONALITY,
        },
        {
          label: getLocaleText(
            "helpers.mentions.sort_options.react_enrichment_aggregate_harmful"
          ),
          field: SortOptionField.ENRICHMENT_HARMFUL,
          key: SortOptionField.ENRICHMENT_HARMFUL,
        },
        {
          label: getLocaleText(
            "helpers.mentions.sort_options.react_enrichment_aggregate_spam"
          ),
          field: SortOptionField.ENRICHMENT_SPAM,
          key: SortOptionField.ENRICHMENT_SPAM,
        },
      ],
    });
  }

  return possibilities.flatMap(({ options }) => options);
}

async function summarise(
  mention: Mention,
  summaryType: string,
  isDerBuilder: boolean = false
): Promise<void> {
  const isFinancialTimes =
    ("license" in mention && mention.license) === "financial-times" ||
    mention.source?.name === "Financial Times";

  try {
    Object.assign(mention, {
      summaryStatus: "in-progress",
    });

    if (isDerBuilder && isFinancialTimes) {
      ModalService.open("OrganisationReportSummaryModal", {
        props: {
          mention,
          isDerBuilder,
        },
        events: {
          update: (changes: { summary: string; title: string }) => {
            Object.assign(mention, {
              summary: changes.summary,
              title: changes.title,
            });
          },
        },
      });
    }

    const response = await OrganisationReportService.mentionSummary(
      mention,
      summaryType,
      isDerBuilder
    );

    Object.assign(mention, {
      summary: response.data.summary,
      summaryStatus: "done",
      summaryCount: response.data.count || null,
    });
  } catch (error: any) {
    if (error.response?.status === 429) {
      Object.assign(mention, {
        summaryStatus: "limit-reached",
      });
      ModalService.close("OrganisationReportSummaryModal");
    } else {
      Object.assign(mention, {
        summaryStatus: "error",
        summaryError: error.response?.data?.message,
      });
    }
  }
}

function sentimentRating(
  mention: Mention,
  stream: Stream
): { sentiment: Sentiment; label: string } {
  let rating = {
    sentiment: mention.sentiment,
  };

  const manualRating =
    mention.sentiment_ratings?.find(
      (mentionSentimentRating) =>
        mentionSentimentRating.origin_id === stream.id &&
        mentionSentimentRating.origin_type === "Stream"
    ) || mention.sentiment_ratings?.at(-1);

  if (
    manualRating &&
    (manualRating.origin_id === stream.id || !manualRating.origin_id)
  ) {
    rating = manualRating;
  }

  const option = sentimentOptions.find(
    (sentimentOption) =>
      isNumber(rating.sentiment) && sentimentOption.inRange(rating.sentiment)
  );

  const field = option ? option.field : "none";

  return {
    ...rating,
    label: getLocaleText(`sentiment.${field}`),
  };
}

export {
  compositeIdToMention,
  excerptsKeywords,
  excludedMediaItemSortFields,
  excludedTranscriptRequestSortFields,
  formattedMedium,
  getOrderOptions,
  getSortOptions,
  getSortPossibilities,
  mediaId,
  mentionKeywords,
  processMentions,
  sentimentRating,
  sortedSyndications,
  sortMentions,
  summarise,
  targetType,
};

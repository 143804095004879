export const shortcuts = {
  enabled: true,
};

function validShortcutFocus(target) {
  const invalidTargetsType = ["text", "textarea", "search", "number"];
  const invalidElements = ["input", "textarea", "select", "button"];
  const invalidClassNames = ["ProseMirror", "ql-editor"];
  const invalidAttributes = ["contenteditable"];
  const invalidIds = [];

  return (
    !invalidElements.includes(target.tagName.toLowerCase()) &&
    !invalidTargetsType.includes(target.type) &&
    !invalidAttributes.some((attribute) => target.getAttribute(attribute)) &&
    !invalidClassNames.some((invalidClassName) =>
      target.classList.contains(invalidClassName)
    ) &&
    !invalidIds.includes(target.id)
  );
}

function getShortcutKey(event) {
  const { ctrlKey, altKey, shiftKey, metaKey, key } = event;

  const shortcutKeys = [];

  if (ctrlKey) shortcutKeys.push("Control");
  if (altKey) shortcutKeys.push("Alt");
  if (shiftKey) shortcutKeys.push("Shift");
  if (metaKey) shortcutKeys.push("Meta");

  if (!shortcutKeys.some((shortcutKey) => shortcutKey === key)) {
    shortcutKeys.push(key);
  }

  return shortcutKeys.join("+");
}

function runCallbacks(event) {
  if (!shortcuts.enabled) return;

  const shortcutKey = getShortcutKey(event);

  if (shortcuts[shortcutKey] && validShortcutFocus(event.target)) {
    event.preventDefault();
    shortcuts[shortcutKey].forEach((callback) => {
      if (callback) callback.call(event);
    });
  }
}

export const addKeydownListener = () => {
  window.addEventListener("keydown", (event) => runCallbacks(event));
};

export const addShortcut = ({ key, callback }) => {
  if (!shortcuts[key]) {
    shortcuts[key] = [callback];
  } else {
    shortcuts[key].push(callback);
  }
};

export const addShortcutList = (shortcutList) => {
  shortcutList.forEach((shortcut) => addShortcut(shortcut));
};

export const removeShortcut = (shortcutKey) => {
  shortcuts[shortcutKey].pop();
};

export const removeShortcutList = (shortcutList) => {
  shortcutList.forEach((shortcut) => removeShortcut(shortcut.key));
};

export const disableShortcuts = () => {
  shortcuts.enabled = false;
};

export const enableShortcuts = () => {
  shortcuts.enabled = true;
};

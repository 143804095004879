<!-- eslint-disable vue/no-v-html -->
<template>
  <MentionCard
    v-intersection="recordView"
    :title="translatedTitle"
    :logo="mention.source ? mention.source.logo_url : null"
    :mention="mention"
    :mentions="mentions"
    :stream="stream"
    :action-sheet-component="actionSheetComponent"
    :options="options"
    :no-highlighting="noHighlighting"
    :show-checkbox="showCheckbox"
    :always-show-keywords="alwaysShowKeywords"
    :selected="selected"
    class="pointer"
    @click="mentionClicked($event)"
    @mention-removed="removeMention"
    @mention-selected="$emit('mention-selected', $event)"
  >
    <template #header>
      <span
        v-if="mention.syndications && mention.syndications.length > 0"
        class="alternate q-mt-xs"
        @click.stop="displaySyndication = !displaySyndication"
      >
        {{ mention.source.name }} + {{ mention.syndications.length }}
      </span>
      <span
        v-else
        class="alternate q-mt-xs"
      >
        {{ mention.source.name }}
      </span>
      <span class="softer">
        <TimeAgo
          :date="mention.timestamp"
          :timezone="mention.source.time_zone"
        />
        <AuthorWidget
          v-if="mention.author"
          :disable-click="options.disableAuthorClick"
          :mention="mention"
        />
        <span class="inline-block">
          {{
            $t("print_mention_card.word_count", {
              wordCount: mention.word_count,
              pageNumbers: formatPageNumbers(mention.page_numbers, true),
            })
          }}
        </span>
      </span>
    </template>

    <slot />

    <template #beforeBody>
      <Syndications
        :expanded="displaySyndication"
        :mention="mention"
        :stream="stream"
        @syndication-clicked="mentionClicked($event)"
      />
    </template>

    <template #afterBody>
      <div class="soft q-mt-md after-body">
        <PotentialAudienceReachWidget :mention="mention" />
        <AdvertisingValueWidget :mention="mention" />
        <SentimentWidget
          :mention="mention"
          :stream="stream"
        />
        <ReactScoreWidget
          class="soft"
          :mention="mention"
          :is-parent-visible="isVisible"
        />
      </div>

      <MentionTranslationLanguage
        v-if="showTranslationLanguage"
        :mention="mention"
      />
    </template>

    <template #after>
      <!-- Related Online Article -->
      <div v-if="canShowRelatedArticle">
        <RelatedMentionCard
          :action-sheet-component="actionSheetComponent"
          :mention="mention.related_article"
          type="online"
          @click="mentionClicked({ ...$event, type: 'article' })"
        >
          {{ $t("print_mention_card.view_online_version") }}
        </RelatedMentionCard>
      </div>
    </template>
  </MentionCard>
</template>

<script>
import { storeToRefs } from "pinia";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import AuthorWidget from "shared/components/AuthorWidget.vue";
import { MentionCard, props } from "shared/components/MentionCard";
import MentionTranslationLanguage from "shared/components/MentionTranslationLanguage.vue";
import PotentialAudienceReachWidget from "shared/components/PotentialAudienceReachWidget.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import RelatedMentionCard from "shared/components/RelatedMentionCard.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import Syndications from "shared/components/Syndications.vue";
import useMention from "shared/composables/useMention";
import { formatPageNumbers } from "shared/helpers/array";
import { useUserStore } from "shared/stores/user";

export default {
  name: "PrintMentionCard",
  components: {
    MentionCard,
    MentionTranslationLanguage,
    RelatedMentionCard,
    AuthorWidget,
    Syndications,
    AdvertisingValueWidget,
    PotentialAudienceReachWidget,
    SentimentWidget,
    ReactScoreWidget,
  },
  props,
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const { mentionClicked } = useMention(mentionProps, context);

    const userStore = useUserStore();
    const { currentUser, isAdminUser } = storeToRefs(userStore);

    return {
      mentionClicked,
      currentUser,
      isAdminUser,
    };
  },
  data() {
    return {
      displaySyndication: false,
      isVisible: false,
      hasRecorded: false,
    };
  },
  computed: {
    translatedTitle() {
      return this.mention.translated_title || this.mention.title;
    },
    canShowRelatedArticle() {
      return (
        this.$features.has("read_online_article") &&
        this.mention.related_article
      );
    },
    showTranslationLanguage() {
      return this.mention?.original_language !== "English";
    },
  },
  methods: {
    formatPageNumbers,
    recordView({ isIntersecting }) {
      this.isVisible = isIntersecting;

      if (isIntersecting && this.$route.name !== "curated-email-report") {
        setTimeout(() => {
          if (this.isVisible && !this.isAdminUser && !this.hasRecorded) {
            this.hasRecorded = true;
            this.$track("Saw Paper Article", {
              userId: this.currentUser.id,
              paperArticleId: this.mention.id,
              sourceId: this.mention.source.id,
            });
          }
        }, 300);
      }
    },
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
  },
};
</script>

<style lang="scss" scoped>
.after-body {
  display: flex;
  gap: 12px;
  align-items: center;
}
</style>

import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class Author extends ApplicationRecord {
  static jsonapiType = "authors";

  @Attr() name: string;
}

export default Author;

import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class Holiday extends ApplicationRecord {
  static jsonapiType = "holidays";

  @Attr() name: string;

  @Attr() date: string;

  @Attr() notes: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default Holiday;

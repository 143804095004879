import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import Contact from "./Contact";
import Influencer from "./Influencer";
import InfluencersOutlet from "./InfluencersOutlet";
import MdmTopic from "./MdmTopic";
import OutletAddress from "./OutletAddress";
import OutletDesk from "./OutletDesk";
import OutletType from "./OutletType";
import type { Homepage, InternalId, Phone, Social } from "./types";

type TargetArea = {
  targetAreaId: number;
  targetArea: string;
};

type Rate = {
  rateType: string;
  rate: number;
  currencyName: string;
  currencyISO: string;
  currencyId: number;
};

type Circulation = {
  circulationType: string;
  circulation: string;
  circulationStartDate: string;
};

type Coverage = {
  coverageId: number;
  coverage: string;
};

type Frequency = {
  frequency: string;
  frequencyId: number;
};

@Model()
class Outlet extends Contact {
  static jsonapiType = "outlets";

  @BelongsTo() outletType: OutletType;

  // eslint-disable-next-line no-use-before-define
  @BelongsTo() parentOutlet: Outlet;

  @HasMany() influencers: Influencer[];

  @HasMany({ name: "outletDesks" }) outletDesks: OutletDesk[];

  @HasMany({ name: "outletAddresses" })
  outletAddresses: OutletAddress[];

  @HasMany() outletMdmTopics: MdmTopic[];

  @HasMany() influencersOutlets: InfluencersOutlet[];

  @Attr() adEqvTier: number;

  @Attr() archivedAt: string;

  @Attr() circulation: string;

  @Attr() circulations: Circulation[];

  @Attr() contentTypes: string[];

  @Attr() coverage: string;

  @Attr() coverages: Coverage[];

  @Attr() documentId: string;

  @Attr() faxes: Phone[];

  @Attr() freelanceOutletFlag: boolean;

  @Attr() frequencies: Frequency[];

  @Attr() group: string;

  @Attr() highResAvatarUrl: string;

  @Attr() homepages: Homepage[];

  @Attr() influencersCount: number;

  @Attr() internalIds: InternalId[];

  @Attr() languages: string[];

  @Attr() location: string;

  @Attr() monitoringOnlyFlag: boolean;

  @Attr() owner: string;

  @Attr() phones: Phone[];

  @Attr() profile: string;

  @Attr() rates: Rate[];

  @Attr() socials: Social[];

  @Attr() status: string;

  @Attr() targetAreas: TargetArea[];

  @Attr() uniqueVisitorsPerMonth: number;

  @Attr() verifiedBy: string;

  @Attr() verifiedDate: string;

  @Attr({ persist: false }) mdmId: string;
}

export default Outlet;

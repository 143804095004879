import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

enum FileType {
  gfk = 1,
  comscore = 2,
  oztam = 3,
  nielsen_tv = 4,
  nielsen_print = 5,
  roy_morgan_print = 6,
  regional_tam = 7,
  xtrainsights = 8,
}

@Model()
class AudienceFile extends ApplicationRecord {
  static jsonapiType = "audience_files";

  @Attr() filename: string;

  @Attr() fileType: FileType;

  @Attr() scheduleStartDate: string;

  @Attr() scheduleEndDate: string;

  @Attr() content: string;

  @Attr() signedUrl: string;

  @Attr() audienceBlocksCount: number;

  @Attr() completedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default AudienceFile;

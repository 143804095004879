import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class UserAccessible extends ApplicationRecord {
  static jsonapiType = "user_accessibles";

  @Attr() userId: number;

  @Attr() accessLevel: number;

  @Attr({ persist: false }) accessibleId: number;

  @Attr({ persist: false }) accessibleType: string;
}

export default UserAccessible;

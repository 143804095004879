import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Journalist from "./Journalist";
import JournalistMailingList from "./JournalistMailingList";
import OutreachCampaign from "./OutreachCampaign";
import OutreachRecipientList from "./OutreachRecipientList";

@Model()
class OutreachCampaignRecipient extends ApplicationRecord {
  static jsonapiType = "outreach_campaign_recipients";

  @BelongsTo() outreachRecipientList: OutreachRecipientList;

  @BelongsTo() journalistMailingList: JournalistMailingList;

  @BelongsTo() outreachCampaign: OutreachCampaign;

  @BelongsTo() journalist: Journalist;

  @Attr() name: string;

  @Attr() email: string;

  @Attr() outreachRecipientListId: number;

  @Attr() journalistMailingListId: number;

  @Attr() outreachCampaignId: number;

  @Attr() journalistId: number;
}

export default OutreachCampaignRecipient;

import { useMobileStore } from "mobile/src/stores/mobile";
import { storeToRefs } from "pinia";
import { computed, inject, ref, toRefs } from "vue";
import { useRouter } from "vue-router";

import { ErrorDialog } from "shared/boot/alert";
import { track } from "shared/boot/analytics";
import { $streemApiV1 } from "shared/boot/api";
import { streamTypes } from "shared/constants";
import {
  openExternalUrl,
  openExternalUrlForMention,
} from "shared/helpers/external";
import { mentionKeywords } from "shared/helpers/mentions";
import { safePush } from "shared/helpers/routing";
import DialogService from "shared/modals/DialogService";
import ModalService from "shared/modals/ModalService";
import features from "shared/services/features";
import { useUserStore } from "shared/stores/user";
import type { Stream } from "shared/types";
import type { Mention } from "shared/types/mentions";
import {
  isMagazineArticle,
  isMentionRequiresAllowanceCheck,
  isMentionWithAppRedirect,
  isMentionWithBody,
  isMentionWithDatahub,
  isMentionWithTvEyes,
  isPaperArticle,
} from "shared/types/mentions/guards";

export interface Emit {
  (event: "click", mention: Mention): void;
}

export default function useMention(
  props: { mention?: Mention; stream?: Stream },
  { emit }: { emit: Emit }
) {
  const router = useRouter();
  const safeRouterPush = safePush({ router });

  const isAdminMode = inject("isAdminMode");
  const isMobile = inject("isMobile");

  const { mention = ref(null), stream = ref<Stream>({ id: "" }) } =
    toRefs(props);
  const loadingMention = ref(false);

  const { setSelectedMention } = useMobileStore();

  const userStore = useUserStore();
  const { isAdminUser } = storeToRefs(userStore);

  const isSocialStream = computed(
    () => stream.value?.type === streamTypes.socialStream
  );

  const mentionType = computed(() => mention.value?.type || "");

  const medium = computed(() => mention.value?.medium?.toLowerCase() || "");

  const keywords = computed(() =>
    mention.value ? mentionKeywords(mention.value) : []
  );

  const playsOnTveyes = computed(() =>
    Boolean(
      mention.value &&
        isMentionWithTvEyes(mention.value) &&
        mention.value.tveyes_player_url?.length
    )
  );

  const isSocial = computed(() =>
    [
      "facebook_post",
      "tweet",
      "youtube_clip",
      "youtube_video",
      "instagram_post",
      "reddit_post",
      "reddit_post_comment",
      "blog_post",
      "forum_post",
    ].includes(mentionType.value)
  );

  async function loadMentionDetails(loadMention: Mention): Promise<Mention> {
    const loadedMention = (
      await $streemApiV1.get(`${loadMention.type}s/${loadMention.id}`)
    ).data;

    return { ...loadMention, ...loadedMention };
  }

  function trackClick(trackedMention: Mention) {
    const title = isSocialStream.value ? "Social Stream" : "Mention Stream";
    const type =
      trackedMention.type === "external_item" ? "External Item" : "Mention";

    track(`Clicked on ${type} in ${title}`, {
      id: trackedMention.id,
      type: trackedMention.type,
      streamId: stream.value?.id,
      reactScore: trackedMention.factmata_enrichment
        ? Math.round(
            Number(trackedMention.factmata_enrichment?.risk_score) * 100
          )
        : null,
    });
  }

  function shouldOpenExternalUrl(
    loadedMention: Mention,
    viewMention?: boolean
  ): boolean {
    if (
      isMentionWithAppRedirect(loadedMention) &&
      loadedMention.in_app_redirect &&
      (!isAdminUser.value || (isAdminUser.value && !viewMention))
    ) {
      return true;
    }

    if (["external_item", "customer_article"].includes(mentionType.value)) {
      return true;
    }

    if (["tv", "radio", "podcast"].includes(medium.value)) {
      return false;
    }

    if (isMentionWithBody(loadedMention) && !viewMention) {
      return Boolean(!loadedMention.body && !loadedMention.translated_body);
    }

    return !viewMention;
  }

  function openTveyesPlayerModal(tveyesMention: Mention) {
    ModalService.open("TveyesPlayerModal", {
      props: {
        mention: tveyesMention,
      },
    });
  }

  function openViewMentionDetailModal(printMention: Mention) {
    const modal = isAdminMode ? "PaperPreviewModal" : "ViewMentionDetailModal";

    ModalService.open(modal, {
      props: {
        mention: printMention,
      },
    });
  }

  function closeViewMentionAlertModal() {
    ModalService.close("ViewMentionAlertModal");
  }

  function continueToDetail(
    loadedMention: Mention,
    viewMention?: boolean,
    redirect?: boolean
  ) {
    if (shouldOpenExternalUrl(loadedMention, viewMention)) {
      openExternalUrlForMention(loadedMention, router, redirect);

      return;
    }

    if (isAdminMode && medium.value === "tv") return;

    const isDatahub =
      isMentionWithDatahub(loadedMention) && loadedMention.datahub;

    if (
      ["radio", "podcast"].includes(medium.value) &&
      !viewMention &&
      !isDatahub
    ) {
      return;
    }

    if (isMobile) {
      setSelectedMention(loadedMention);

      safeRouterPush({
        path: `/${loadedMention.type}s/${loadedMention.id}${
          stream.value && stream.value.id ? `/${stream.value.id}` : ""
        }`,
        query: { keywords: keywords.value },
      });
    } else if (
      isMentionWithTvEyes(loadedMention) &&
      loadedMention.tveyes_player_url?.length
    ) {
      openTveyesPlayerModal(loadedMention);
    } else {
      openViewMentionDetailModal(loadedMention);
    }
  }

  function openViewMentionAlertModal(
    alertMention: Mention,
    viewMention?: boolean
  ) {
    ModalService.open("ViewMentionAlertModal", {
      props: {
        mention: alertMention,
      },
      events: {
        continue: async () => {
          closeViewMentionAlertModal();

          try {
            const loadedMention = await loadMentionDetails(alertMention);
            continueToDetail(loadedMention, viewMention);
          } catch (error) {
            ErrorDialog("Unabled to load Media Item", error);
          }
        },
        cancel: () => closeViewMentionAlertModal(),
      },
    });
  }

  async function mentionClicked(
    clickedMention: Mention,
    viewMention?: boolean
  ) {
    if (loadingMention.value) {
      return;
    }

    try {
      loadingMention.value = true;

      trackClick(clickedMention);

      emit("click", clickedMention);

      if (isSocial.value) {
        openExternalUrlForMention(clickedMention, router);

        return;
      }

      if (playsOnTveyes.value) {
        openTveyesPlayerModal(clickedMention);

        return;
      }

      let needsRecording = false;

      if (
        isMentionRequiresAllowanceCheck(clickedMention) &&
        clickedMention.requires_allowance_check
      ) {
        needsRecording = await features.requiresActivityRecord(clickedMention);
      }

      if (needsRecording) {
        openViewMentionAlertModal(clickedMention, viewMention);

        return;
      }

      const loadedMention = await loadMentionDetails(clickedMention);

      if (isPaperArticle(loadedMention) && loadedMention.permission === false) {
        DialogService.alert({
          title: "Unauthorised",
          confirmLabel: "Read on AFR with subscription",
        }).onOk(() => {
          openExternalUrl(loadedMention.url);
        });

        return;
      }

      if (
        (isMagazineArticle(loadedMention) || isPaperArticle(loadedMention)) &&
        loadedMention.expired_article
      ) {
        DialogService.alert({
          title: "This content is not available",
          confirmLabel: "Close",
        }).onOk(() => {});

        return;
      }

      continueToDetail(loadedMention, viewMention);
    } catch (error) {
      ErrorDialog("Unabled to load Media Item", error);
    } finally {
      loadingMention.value = false;
    }
  }

  function viewMentionDetail(loadedMention: Mention) {
    continueToDetail(loadedMention, true, true);
  }

  return {
    medium,
    mentionType,
    keywords,
    playsOnTveyes,

    mentionClicked,
    viewMentionDetail,
  };
}

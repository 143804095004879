<template>
  <div class="card related-content hide-radius-top">
    <div
      class="card-online mention-content hide-radius-top"
      :class="'border-color-' + type"
    >
      <div class="row items-center">
        <div class="small-logo">
          <img
            v-if="mention.logo"
            :src="mention.logo"
            width="30px"
            :alt="$t('global.source_logo')"
          />
        </div>
        <span
          class="q-py-md alternate hover-active"
          @click="mentionClicked(mention)"
        >
          <slot />
        </span>
        <span
          v-if="mention.syndications.length > 0"
          class="alternate q-ml-sm soft"
          @click="displaySyndication = !displaySyndication"
        >
          +{{ mention.syndications.length }}
        </span>
        <span class="muted q-ml-sm published-time">
          <TimeAgo
            :date="mention.published_at"
            :timezone="mention.time_zone"
          />
        </span>
        <div class="action-sheet relative">
          <Component
            :is="actionSheetComponent"
            :mention="mention"
            button-color="blue-grey"
          />
        </div>
      </div>
      <div
        v-if="displaySyndication"
        class="publishers-list q-mr-smd syndications"
      >
        <a
          v-for="(syndicated, key) in syndications"
          :key="key"
          class="no-shrink hover-active"
          @click="mentionClicked(syndicated)"
        >
          <span class="bold">
            {{ syndicated.source_name }}
          </span>
          <span class="muted pull-right syndication-time">
            {{ formatDate(new Date(syndicated.published_at), "h:mmaaa") }}
          </span>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { formatDate } from "shared/helpers/date";
import { sortedSyndications } from "shared/helpers/mentions";

export default {
  name: "RelatedMentionCard",
  props: {
    mention: {
      type: Object,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
    actionSheetComponent: {
      type: [Function, Object, String],
      default: null,
    },
  },
  emits: ["click"],
  data() {
    return {
      displaySyndication: false,
    };
  },
  computed: {
    syndications() {
      return sortedSyndications(this.mention);
    },
  },
  methods: {
    formatDate,
    mentionClicked(item) {
      this.$emit("click", {
        ...item,
        excerpts: this.mention.excerpts,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.small-logo {
  min-width: 45px;
  color: #767676;
  margin: 0 2px;

  img {
    align-self: center;
  }
}

.published-time {
  white-space: nowrap;
}

.action-sheet {
  margin-left: auto;
  margin-right: 2px;
  padding: 3px 5px;
}

.related-content {
  margin-top: 2px;
  display: inline-block;
  width: 100%;
}

.card-online {
  padding-left: 10px;
  border-radius: 4px;
}

.syndications {
  margin-left: 47px;
  margin-bottom: 10px !important;
}
</style>

<template>
  <div class="no-media-container column justify-center items-center">
    <strong class="q-my-md">
      {{ $t("media_not_found_card.no_item_found", { item }) }}
    </strong>
    <div class="soft message">
      {{
        $t("media_not_found_card.no_item_found_subtext", {
          item,
          typeOfStream,
          rangeText: range.rangeText,
        })
      }}
      <i18n-t
        :keypath="noMediaItemLocaleKey"
        scope="global"
        tag="span"
      >
        <template #customerSuccessEmailLink>
          <a
            :href="`mailto:${appConfig.emails.streemSocial}`"
            class="dashed-underline pointer"
          >
            {{ $t("media_not_found_card.customer_success") }}
          </a>
        </template>
        <template #accountManagerContactPopup>
          <ContactPopup :organisation="organisation">
            <span class="dashed-underline pointer">
              {{ $t("media_not_found_card.account_manager") }}
            </span>
          </ContactPopup>
        </template>
        <template #cisionCustomerPortalLink>
          <a
            href="https://support.cision.com/en/support/tickets/new"
            target="_blank"
            rel="noopener noreferrer"
            class="dashed-underline pointer"
          >
            {{ $t("media_not_found_card.customer_portal") }}
          </a>
        </template>
        <template #cisionKnowledgeBaseLink>
          <a
            :href="appConfig.urls.helpArticles"
            target="_blank"
            rel="noopener noreferrer"
            class="dashed-underline pointer"
          >
            {{ $t("media_not_found_card.knowledge_base") }}
          </a>
        </template>
      </i18n-t>
    </div>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";

import ContactPopup from "shared/components/user/ContactPopup.vue";
import DateRange from "shared/helpers/DateRange";
import { useGlobalStore } from "shared/stores/global";
import { useUserStore } from "shared/stores/user";

export default {
  name: "MediaNotFoundCard",
  components: {
    ContactPopup,
  },
  props: {
    range: {
      type: DateRange,
      required: true,
    },
    social: {
      type: Boolean,
    },
  },
  setup() {
    const globalStore = useGlobalStore();
    const { appConfig } = storeToRefs(globalStore);

    const userStore = useUserStore();
    const { organisation } = storeToRefs(userStore);

    return { appConfig, organisation };
  },
  computed: {
    item() {
      return this.social
        ? this.$t("media_not_found_card.social_items")
        : this.$t("media_not_found_card.media_items");
    },
    typeOfStream() {
      return this.social
        ? this.$t("media_not_found_card.social_stream")
        : this.$t("media_not_found_card.mention_stream");
    },
    noMediaItemLocaleKey() {
      return this.social
        ? "media_not_found_message_social"
        : "media_not_found_message_traditional";
    },
  },
};
</script>

<style lang="scss" scoped>
.message {
  max-width: 100%;
  min-height: 450px;
}
</style>

import { Attr, HasOne, Model } from "spraypaint";

import SourceRecord from "shared/resources/SourceRecord";

import Category from "./Category";
import CopyrightAgency from "./CopyrightAgency";
import Outlet from "./Outlet";
import SourceGroup from "./SourceGroup";

@Model()
class Newspaper extends SourceRecord {
  static jsonapiType = "newspapers";

  @HasOne() copyrightAgency: CopyrightAgency;

  @HasOne() location: Location;

  @HasOne() sourceGroup: SourceGroup;

  @HasOne() category: Category;

  @HasOne() outlet: Outlet;

  @Attr() name: string;

  @Attr() label: string;

  @Attr() aliases: string[];

  @Attr() externalId: string;

  @Attr({ persist: false }) medium: string;

  @Attr() locationId: number;

  @Attr() timeZone: string;

  @Attr() sourceGroupId: number;

  @Attr() satirical: boolean;

  @Attr() logoUrl: string;

  @Attr() logoData: string;

  @Attr() tier: number;

  @Attr() categoryId: number;

  @Attr() dailyAudience: number;

  @Attr() copyrightAgencyId: number;

  @Attr() origin: string;

  @Attr() filenamePattern: string;

  @Attr() audienceFileIdentifier: string;

  @Attr() frontPageReportEnabled: boolean;

  @Attr() frontPageReportOrder: number;

  @Attr() syndication: boolean;

  @Attr() internationalMax: number;

  @Attr() lastArticleAt: string;

  @Attr() subscriptionExpiry: string;

  @Attr() notes: string;

  @Attr() visible: boolean;

  @Attr() caldContent: string;

  @Attr() translate: boolean;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr({ persist: false }) archivedAt: string;
}

export default Newspaper;

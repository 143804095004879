import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import AdvertisementFragment from "./AdvertisementFragment";
import MentionReview from "./MentionReview";

@Model()
class Advertisement extends ApplicationRecord {
  static jsonapiType = "advertisements";

  @BelongsTo() mentionReview: MentionReview;

  @HasMany() fragments: AdvertisementFragment[];

  @Attr({ persist: false }) text: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default Advertisement;

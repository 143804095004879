import { Attr, BelongsTo, HasMany, HasOne, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import EmailDomain from "./EmailDomain";
import OrganisationBrand from "./OrganisationBrand";
import ScheduledReport from "./ScheduledReport";
import ScheduledReportSpecificationSchedule from "./ScheduledReportSpecificationSchedule";
import ScheduledReportSpecificationSection from "./ScheduledReportSpecificationSection";
import Stream from "./Stream";
import User from "./User";
import UserAccessible from "./UserAccessible";

@Model()
class ScheduledReportSpecification extends ApplicationRecord {
  static jsonapiType = "scheduled_report_specifications";

  @BelongsTo() emailDomain: EmailDomain;

  @BelongsTo() user: User;

  @BelongsTo() organisationBrand: OrganisationBrand;

  @HasOne() lastReport: ScheduledReport;

  @HasMany() schedules: ScheduledReportSpecificationSchedule[];

  @HasMany() sections: ScheduledReportSpecificationSection[];

  @HasMany() scheduledReports: ScheduledReport[];

  @HasMany() streams: Stream[];

  @HasMany() userAccessibles: UserAccessible[];

  @Attr() subject: string;

  @Attr() subjectDate: boolean;

  @Attr() enabled: boolean;

  @Attr() recipients: string[];

  @Attr() sender: string;

  @Attr() customSender: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr() completedAt: string;

  @Attr() userId: number;

  @Attr() organisationBrandId: number;

  @Attr() organisationTeamId: number;

  @Attr() fontSize: string;

  @Attr() condenseMentions: boolean;

  @Attr() groupByMedia: boolean;

  @Attr() mediaOrder: string[];

  @Attr() sendWhenNoMention: boolean;

  @Attr() subjectMentionCount: boolean;

  @Attr() showExcerpts: boolean;

  @Attr() showKeywordCount: boolean;

  @Attr() includePdf: boolean;

  @Attr() includePrintClipsPdf: boolean;

  @Attr() excludeDuplicateMentions: boolean;

  @Attr() limitBroadcastPerSection: boolean;

  @Attr() includeDailyHighlights: boolean;

  @Attr() includeMediaItems: boolean;

  @Attr() hideEmptyFolders: boolean;

  @Attr() header: string;

  @Attr() footer: string;

  @Attr() sortBy: string;

  @Attr() sortOrder: string;

  @Attr({ persist: false }) streamsData: any[];

  @Attr() preferenceOnlineMentions: boolean;

  @Attr({ persist: false }) canShare: boolean;

  @Attr({ persist: false }) canModify: boolean;

  @Attr() options: Record<string, any>;

  @Attr() showRestrictedContent: boolean;

  @Attr() language: string;

  @Attr() senderName: string;

  @Attr() senderEmail: string;

  @Attr() emailDomainId: number;
}

export default ScheduledReportSpecification;

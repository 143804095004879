import { Attr, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import PermissionsRole from "./PermissionsRole";

@Model()
class Role extends ApplicationRecord {
  static jsonapiType = "roles";

  @HasMany() permissionsRoles: PermissionsRole[];

  @Attr() name: string;

  @Attr() position: number;

  @Attr() product: string;
}

export default Role;

import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import CopyrightReport from "./CopyrightReport";

@Model()
class CopyrightReportFile extends ApplicationRecord {
  static jsonapiType = "copyright_report_files";

  @BelongsTo() copyrightReport: CopyrightReport;

  @Attr({ persist: false }) copyrightReportId: number;

  @Attr({ persist: false }) filename: string;

  @Attr({ persist: false }) signedUrl: string;

  @Attr({ persist: false }) completedAt: string;

  @Attr({ persist: false }) createdAt: string;
}

export default CopyrightReportFile;

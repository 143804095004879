import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class BlocklistUrlPattern extends ApplicationRecord {
  static jsonapiType = "blocklist_url_patterns";

  @Attr() urlPattern: string;

  @Attr({ persist: false }) domain: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default BlocklistUrlPattern;

<template>
  <div class="flex justify-end">
    <div
      v-if="searchScope === 'streams'"
      class="flex-1"
    >
      <StreamsByGroupPicker v-model="searchStreams" />
    </div>
    <QBtn
      :label="btnLabel"
      :flat="!filterApplied"
      :class="{ 'bold rounded': filterApplied }"
      class="q-px-xs q-mx-xs"
      color="primary"
      icon="filter_list"
      dense
      @click="open"
    />

    <QDialog
      v-model="show"
      position="bottom"
      square
    >
      <QCard class="full-width">
        <div class="header flex justify-between items-center q-pa-md">
          <span class="title bold block">{{
            $t("universal_search_actionsheet_filters.filter_options")
          }}</span>
          <div class="q-gutter-sm">
            <QBtn
              :label="$t('universal_search_actionsheet_filters.reset_filters')"
              dense
              flat
              class="font-small"
              @click="resetFilters"
            />
            <QBtn
              :label="$t('universal_search_actionsheet_filters.done')"
              dense
              flat
              class="font-small"
              @click="close"
            />
          </div>
        </div>
        <ul class="list">
          <li
            v-if="searchScope !== 'journalists'"
            class="q-px-md q-py-sm flex items-center"
          >
            <QIcon
              :class="{
                active: searchRange.toString() !== defaultDateRange.toString(),
              }"
              name="ion-time"
              class="icon"
            />
            <RangePickerActionSheet
              v-model="searchRange"
              class="flex-1"
            />
          </li>
          <li class="q-px-md q-py-sm flex items-center">
            <QIcon
              :class="{
                active: selectedMediaTypes.length !== mediaTypeSources.length,
              }"
              name="ion-options"
              class="icon"
            />
            <UniversalSearchActionsheetSelectMediaTypes
              v-model="searchStream"
              :excluded-media="excludedMedia"
              class="flex-1"
            />
          </li>
          <li class="q-px-md q-py-sm flex items-center">
            <QIcon
              :class="{ active: searchSources.length }"
              name="filter_alt"
              class="icon"
            />
            <UniversalSearchActionsheetSelectSourceFilter
              v-model="searchSources"
              :stream="searchStream"
              class="flex-1"
            />
          </li>
        </ul>
      </QCard>
    </QDialog>
  </div>
</template>

<script>
import RangePickerActionSheet from "shared/components/core/pickers/RangePickerActionSheet.vue";
import StreamsByGroupPicker from "shared/components/core/pickers/StreamsByGroupPicker.vue";
import UniversalSearchActionsheetSelectMediaTypes from "shared/components/search/UniversalSearchActionsheetSelectMediaTypes.vue";
import UniversalSearchActionsheetSelectSourceFilter from "shared/components/search/UniversalSearchActionsheetSelectSourceFilter.vue";
import DateRange from "shared/helpers/DateRange";
import { getMediaForStream, getSources } from "shared/helpers/sources";

export default {
  name: "UniversalSearchActionsheetFilters",
  components: {
    RangePickerActionSheet,
    StreamsByGroupPicker,
    UniversalSearchActionsheetSelectMediaTypes,
    UniversalSearchActionsheetSelectSourceFilter,
  },
  props: {
    stream: {
      type: Object,
      required: true,
    },
    range: {
      type: DateRange,
      required: true,
    },
    streams: {
      type: Array,
      required: true,
    },
    sources: {
      type: Array,
      required: true,
    },
    searchScope: {
      type: String,
      required: true,
    },
    excludedMedia: {
      type: Array,
      default: () => [],
    },
  },
  emits: [
    "update:stream",
    "update:range",
    "update:streams",
    "update:sources",
    "search",
  ],
  data() {
    return {
      show: false,
      defaultDateRange: DateRange.lastSevenDays(),
      mediaTypeSources: getSources(),
    };
  },
  computed: {
    searchStream: {
      get() {
        return this.stream;
      },
      set(value) {
        this.$emit("update:stream", value);
        this.$emit("search");
      },
    },
    searchRange: {
      get() {
        return this.range;
      },
      set(value) {
        this.$emit("update:range", value);
        this.$emit("search");
      },
    },
    searchStreams: {
      get() {
        return this.streams;
      },
      set(value) {
        this.$emit("update:streams", value);
        this.$emit("search");
      },
    },
    searchSources: {
      get() {
        return this.sources;
      },
      set(value) {
        this.$emit("update:sources", value);
        this.$emit("search");
      },
    },
    filterApplied() {
      return (
        this.selectedMediaTypes.length !== this.mediaTypeSources.length ||
        this.defaultDateRange.toString() !== this.searchRange.toString() ||
        this.searchSources.length
      );
    },
    btnLabel() {
      if (this.filterApplied)
        return this.$t("universal_search_actionsheet_filters.filters_applied");

      return this.$t("universal_search_actionsheet_filters.add_filter");
    },
    selectedMediaTypes() {
      return getMediaForStream(this.searchStream);
    },
  },
  methods: {
    open() {
      this.show = true;
    },
    close() {
      this.show = false;
    },
    resetFilters() {
      this.searchRange = this.defaultDateRange;
      this.searchSources = [];

      const stream = { ...this.stream };
      this.mediaTypeSources.forEach((source) => {
        stream[`${source.field}_content`] = true;
      });

      this.searchStream = stream;
      this.searchScope = "select";
    },
  },
};
</script>

<style lang="scss" scoped>
.q-btn :deep(.on-left) {
  margin-right: 4px;
}

.flex-1 {
  flex: 1;
}

.font-small {
  font-size: 12px;
}

.rounded {
  border-radius: 2px;
}

.icon {
  color: #9b9b9b;

  &.active {
    color: #444;
  }
}

.header {
  border-bottom: 1px solid #ddd;

  .title {
    font-size: 19px;
  }
}

.list {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    border-bottom: 1px solid #ddd;
  }
}
</style>

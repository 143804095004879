import { Attr, BelongsTo, HasMany, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import CopyrightAgency from "./CopyrightAgency";
import CopyrightReportFile from "./CopyrightReportFile";
import SourceGroup from "./SourceGroup";
import User from "./User";

enum CopyrightReportDataSet {
  activities = 1,
  audit_events = 2,
}

enum CopyrightReportType {
  itemised_report = 1,
  customer_report = 2,
  media_requests_report = 3,
  audience_activity_report = 4,
  downstream_report = 5,
  internal_summary_report = 6,
}

type CopyrightReportMedium = "online" | "print" | "magazine";

@Model()
class CopyrightReport extends ApplicationRecord {
  static jsonapiType = "copyright_reports";

  @BelongsTo() copyrightAgency: CopyrightAgency;

  @BelongsTo() sourceGroup: SourceGroup;

  @BelongsTo() user: User;

  @HasMany() files: CopyrightReportFile[];

  @Attr() copyrightAgencyId: number;

  @Attr() sourceGroupId: number;

  @Attr() medium: CopyrightReportMedium;

  @Attr() reportType: CopyrightReportType;

  @Attr() dataSet: CopyrightReportDataSet;

  @Attr() signedUrl: string;

  @Attr() before: string;

  @Attr() after: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) completedAt: string;
}

export default CopyrightReport;

import { Attr, HasOne, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Category from "./Category";
import SourceGroup from "./SourceGroup";

@Model()
class Podcast extends ApplicationRecord {
  static jsonapiType = "podcasts";

  @HasOne() location: Location;

  @HasOne() category: Category;

  @HasOne() sourceGroup: SourceGroup;

  @Attr() name: string;

  @Attr({ persist: false }) medium: string;

  @Attr() tier: number;

  @Attr() locationId: number;

  @Attr() label: string;

  @Attr() categoryId: number;

  @Attr() itunesCollectionId: string;

  @Attr() timeZone: string;

  @Attr() url: string;

  @Attr() feedUrl: string;

  @Attr() logoUrl: string;

  @Attr() genres: string[];

  @Attr() primaryGenre: string;

  @Attr() notes: string;

  @Attr() visible: boolean;

  @Attr({ persist: false }) archivedAt: string;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;

  @Attr() logoData: string;

  @Attr() aliases: string[];
}

export default Podcast;

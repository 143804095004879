import { Attr, BelongsTo, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Influencer from "./Influencer";

@Model()
class InfluencerAddress extends ApplicationRecord {
  static jsonapiType = "influencer_addresses";

  @BelongsTo() influencer: Influencer;

  @Attr() address: string;

  @Attr() defaultFlag: boolean;

  @Attr() influencerId: number;

  @Attr() archivedAt: string;
}

export default InfluencerAddress;

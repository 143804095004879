<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    v-intersection="handleIntersection"
    class="full-width"
  >
    <MentionCard
      :title="mention.title"
      :list-title="listTitle"
      :logo="mention.source ? mention.source.logo_url : null"
      :mention="mention"
      :mentions="mentions"
      :stream="stream"
      :action-sheet-component="actionSheetComponent"
      :options="options"
      :no-highlighting="noHighlighting"
      :show-checkbox="showCheckbox"
      :always-show-keywords="alwaysShowKeywords"
      :selected="selected"
      class="scoped"
      :class="{ pointer: !showPopUpPlayerWidget }"
      @click="mentionClicked($event)"
      @mention-removed="removeMention"
      @mention-selected="$emit('mention-selected', $event)"
    >
      <template #header>
        <span
          v-if="mention.syndications && mention.syndications.length > 0"
          class="alternate q-mt-xs"
          @click.stop="displaySyndication = !displaySyndication"
        >
          <strong class="bigger2">
            {{ mention.source.name }}
          </strong>
          {{ mention.source.location }}
          <span v-if="mention.syndications && mention.syndications.length > 0">
            +{{ mention.syndications.length }}
          </span>
        </span>
        <span
          v-else
          class="alternate q-mt-xs"
        >
          <strong class="bigger2">
            {{ mention.source.name }}
          </strong>
          {{ mention.source.location }}
        </span>
        <div class="row items-center">
          <span class="softer">
            <TimeAgo
              :date="mention.timestamp"
              :timezone="mention.source.time_zone"
            />
            <span v-if="mention.program_airing">
              · {{ mention.program_airing.name }}
            </span>
          </span>
        </div>
      </template>

      <slot />

      <template #beforeBody>
        <Syndications
          :expanded="displaySyndication"
          :mention="mention"
          :stream="stream"
          @syndication-clicked="syndicationClicked($event)"
        />
      </template>

      <template #body="{ mentionFormatted }">
        <div class="row justify-start no-wrap">
          <UniversalPlayerControlWidget
            v-if="showPopUpPlayerWidget"
            :clip="mentionFormatted"
            class="play-button"
            :class="{ 'in-card': !isListView() }"
            @play="playInPopout"
          />
          <MentionExcerpts
            :mention="mentionFormatted"
            :no-highlighting="noHighlighting"
            expandable
            class="q-pl-sm"
          />
        </div>

        <div @click.stop>
          <PortalTarget :name="`radio-card-${uid}`" />
        </div>
      </template>

      <template #afterBody>
        <div class="row soft q-mt-md items-center q-gutter-x-sm">
          <BroadcastAudienceWidget
            :mention="mention"
            medium="radio"
          />
          <AdvertisingValueWidget :mention="mention" />
          <SentimentWidget
            :mention="mention"
            :stream="stream"
          />
          <ReactScoreWidget
            class="soft"
            :mention="mention"
            :is-parent-visible="isIntersecting"
          />
        </div>
      </template>

      <!-- Display audio player for mention search page in admin mode -->
      <template
        v-if="$isAdminMode"
        #afterRow
      >
        <div v-if="mention.type === 'radio_clip' && $isAdminMode">
          <div>
            <!-- Display audio player for audio clips
            (show "Access Full Media" button before player) -->
            <InlinePlayer
              v-if="mention.type === 'radio_clip'"
              :clip="mention"
              :mention="mention"
              :poster="thumbnail"
              type="audio"
              auto-fetch-neighbours
              hide-video
            />
          </div>
        </div>
      </template>

      <template #list-actions>
        <UniversalPlayerControlWidget
          v-if="showPopUpPlayerWidget"
          :clip="mention"
          class="play-button"
          @play="playInPopout"
        />
      </template>
    </MentionCard>
  </div>
</template>

<script>
import { storeToRefs } from "pinia";
import { PortalTarget } from "portal-vue";

import AdvertisingValueWidget from "shared/components/AdvertisingValueWidget.vue";
import BroadcastAudienceWidget from "shared/components/BroadcastAudienceWidget.vue";
import { MentionCard, propsExcept } from "shared/components/MentionCard";
import MentionExcerpts from "shared/components/MentionExcerpts.vue";
import InlinePlayer from "shared/components/players/InlinePlayer.vue";
import UniversalPlayerControlWidget from "shared/components/players/UniversalPlayerControlWidget.vue";
import ReactScoreWidget from "shared/components/ReactScoreWidget.vue";
import SentimentWidget from "shared/components/SentimentWidget.vue";
import Syndications from "shared/components/Syndications.vue";
import useMention from "shared/composables/useMention";
import { POPOUT_PLAYER_TARGET } from "shared/constants";
import generateId from "shared/helpers/generateId";
import { useUniversalPlayerStore } from "shared/stores/universalPlayer";

export default {
  name: "RadioMentionCard",
  components: {
    PortalTarget,
    MentionCard,
    AdvertisingValueWidget,
    BroadcastAudienceWidget,
    InlinePlayer,
    MentionExcerpts,
    UniversalPlayerControlWidget,
    Syndications,
    ReactScoreWidget,
    SentimentWidget,
  },
  props: {
    ...propsExcept(["listTitle"]),
  },
  emits: ["click", "mention-selected", "mention-removed"],
  setup(mentionProps, context) {
    const universalPlayerStore = useUniversalPlayerStore();
    const {
      playerOpen,
      playerPopoutRef,
      playerInitialClip,
      playerIsDismissed,
    } = storeToRefs(universalPlayerStore);

    const {
      playerHide,
      setPlayerPopoutTarget,
      playerInsertClip,
      playerShow,
      setPlayerDismissed,
    } = universalPlayerStore;

    const { mentionClicked } = useMention(mentionProps, context);

    return {
      playerOpen,
      playerPopoutRef,
      playerInitialClip,
      playerIsDismissed,

      playerHide,
      setPlayerPopoutTarget,
      playerInsertClip,
      playerShow,
      setPlayerDismissed,

      mentionClicked,
    };
  },
  data() {
    return {
      uid: generateId(),
      displaySyndication: false,
      thumbnail: "",
      isIntersecting: false,
    };
  },
  computed: {
    listTitle() {
      const mentionTitle = this.mention.program_airing
        ? this.mention.program_airing.name
        : this.mention.source.name;

      return `${mentionTitle} ${this.mention.source.location}`;
    },
    showPopUpPlayerWidget() {
      return !this.$isAdminMode && !this.mention.tveyes_player_url?.length;
    },
  },
  beforeUnmount() {
    if (
      this.isPopoutPlayerPlayingThisClip() &&
      this.playerOpen &&
      this.$isDesktop
    ) {
      this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);
      this.$track("Playing the clip in the popout player", {
        type: "radio",
      });
    }
  },
  methods: {
    removeMention(mention) {
      this.$emit("mention-removed", mention);
    },
    handleIntersection(el) {
      this.isIntersecting = el.isIntersecting;
      if (this.playerClosed()) return;
      if (!this.playerOpen) this.playerShow();
      this.setPlayerTarget();
    },
    playInPopout() {
      this.playerInsertClip(this.mention);

      if (!this.playerOpen) this.playerShow();

      this.$nextTick(() => {
        this.playerPopoutRef.playNow(this.mention);
      });

      this.setPlayerTarget();
      this.setPlayerDismissed(false);
    },
    setPlayerTarget() {
      if (this.playInCard()) {
        this.setPlayerPopoutTarget(`radio-card-${this.uid}`);
        this.$track("Playing the clip in the card", {
          type: "radio",
        });
      } else {
        this.setPlayerPopoutTarget(POPOUT_PLAYER_TARGET);
        this.$track("Playing the clip in the popout player", {
          type: "radio",
        });
      }
    },
    playInCard() {
      return (
        (this.isIntersecting &&
          !this.isListView() &&
          !this.alwaysPlayInPopout) ||
        this.$isMobile
      );
    },
    isPopoutPlayerPlayingThisClip() {
      return (
        this.playerInitialClip &&
        this.mention &&
        this.playerInitialClip.id === this.mention.id
      );
    },
    isListView() {
      return this.options?.list;
    },
    playerClosed() {
      return !this.isPopoutPlayerPlayingThisClip() || this.playerIsDismissed;
    },
    syndicationClicked(syndication) {
      this.mentionClicked(syndication);
    },
  },
};
</script>

<style lang="scss" scoped>
.scoped:deep(.card) {
  .hidden-player {
    height: 0;
    transition: height 0.5s;
    overflow: hidden;
  }

  &:hover {
    .hidden-player {
      height: 50px;
    }
  }
}

.wrap-play-button {
  &::before {
    width: 50px;
    height: 40px;
    float: left;
    content: "";
  }
}

.play-button {
  opacity: 0;
  transition: opacity 0.5s;
}

.scoped:deep(.card .play-button) {
  opacity: 1;
}

.hide {
  visibility: hidden;
}

.play-button.in-card {
  margin-top: -5px;
}
</style>

<template>
  <AlertMessage
    v-if="showMultiplierInfo"
    type="info"
    icon-size="1rem"
    v-bind="$attrs"
  >
    {{
      $t("advertising_value_widget.tooltip_info", {
        multiplier: $t(multiplierField),
        value: multiplierValue,
        lastUpdatedBy: lastUpdatedBy,
      })
    }}
  </AlertMessage>
</template>

<script>
import { storeToRefs } from "pinia";
import { computed } from "vue";

import { AlertMessage } from "shared/components/base";
import { useUserStore } from "shared/stores/user";

export default {
  name: "MetricEditorInfoAlert",
  components: {
    AlertMessage,
  },
  props: {
    showAveMultiplierInfo: {
      type: Boolean,
    },
    showAudienceMultiplierInfo: {
      type: Boolean,
    },
  },

  setup(props) {
    const userStore = useUserStore();
    const { multiplier } = storeToRefs(userStore);

    const showMultiplierInfo = computed(() => {
      if (!multiplier.value?.id) return false;

      return (
        (props.showAveMultiplierInfo &&
          multiplier.value.aveMultiplier !== 1.0) ||
        (props.showAudienceMultiplierInfo &&
          multiplier.value.audienceMultiplier !== 1.0)
      );
    });

    const multiplierField = computed(() =>
      props.showAveMultiplierInfo
        ? "metric_editor.ave_title"
        : "metric_editor.par_title"
    );

    const multiplierValue = computed(() =>
      props.showAveMultiplierInfo
        ? multiplier.value.aveMultiplier
        : multiplier.value.audienceMultiplier
    );

    const lastUpdatedBy = computed(() =>
      props.showAveMultiplierInfo
        ? multiplier.value.aveMultiplierLastUpdatedBy?.name
        : multiplier.value.audienceMultiplierLastUpdatedBy?.name
    );

    return {
      multiplier,
      multiplierField,
      showMultiplierInfo,
      multiplierValue,
      lastUpdatedBy,
    };
  },
};
</script>

<template>
  <div
    v-intersection="handleIntersection"
    class="c-chart-library-chart"
    :style="chartStyle"
  >
    <DataLoadingWrapper
      :state="state"
      class="column no-wrap"
      @reload="$emit('reload')"
    >
      <slot name="before" />

      <Transition name="fade">
        <Component
          :is="chartComponent"
          v-if="isIntersecting"
          v-bind="chartProps"
          class="fit self-center"
          :interaction-enabled="Boolean(chartConfig.interactionEnabled)"
          @chart-clicked="$emit('chart-clicked', $event)"
          @chart:render="$emit('chart:render', $event)"
        />
      </Transition>
    </DataLoadingWrapper>
  </div>
</template>

<script>
import { merge } from "lodash-es";

import { appInstance } from "shared/boot/app";
import { DataLoadingWrapper } from "shared/components/base";

export default {
  name: "ChartLibraryChart",
  components: {
    DataLoadingWrapper,
  },
  props: {
    chartConfig: {
      type: Object,
      required: true,
    },
    chartData: {
      type: Object,
      default: () => ({}),
    },
    loading: {
      type: Boolean,
    },
    error: {
      type: Boolean,
    },
  },
  emits: ["reload", "chart-clicked", "chart:render", "intersection"],
  data() {
    return {
      isIntersecting: false,
    };
  },
  computed: {
    state() {
      if (this.loading) return "loading";
      if (this.error) return "error";

      return "done";
    },
    chartComponent() {
      return appInstance.component(this.chartConfig.component);
    },
    chartStyle() {
      return this.chartConfig.style;
    },
    chartProps() {
      return merge({}, this.chartData, this.chartConfig.componentAttributes);
    },
  },
  methods: {
    handleIntersection(el) {
      this.isIntersecting = el.isIntersecting;
      this.$emit("intersection", this.isIntersecting);
    },
  },
};
</script>

<style lang="scss" scoped>
.c-chart-library-chart {
  width: 100%;
}
</style>

import { defineStore } from "pinia";

import actions from "./actions";
import getters from "./getters";
import state from "./state";

// eslint-disable-next-line import/prefer-default-export
export const useUserStore = defineStore("user", {
  actions,
  getters,
  state,
});

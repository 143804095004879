import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class TvCaptionFragment extends ApplicationRecord {
  static jsonapiType = "tv_caption_fragments";

  @Attr() text: string;

  @Attr() timestamp: number;
}

export default TvCaptionFragment;

<template>
  <div class="expiry q-py-xs q-px-md row no-wrap smaller">
    <div class="q-mr-auto bold row items-center">
      <QIcon
        name="ion-md-time"
        class="q-mr-xs"
      />
      {{ $t("mention_stream_expiry_watcher.expires_in", { expiresIn }) }}
    </div>
    <QBtn
      flat
      dense
      :label="$t('mention_stream_expiry_watcher.extend')"
      style="font-weight: 400"
      class="q-py-none q-mr-sm smaller"
      :aria-label="$t('mention_stream_expiry_watcher.extend_expiry')"
      @click="extend"
    />
    <QBtn
      flat
      dense
      :label="$t('mention_stream_expiry_watcher.dismiss')"
      style="font-weight: 400"
      class="q-py-none smaller"
      :aria-label="$t('mention_stream_expiry_watcher.dismiss')"
      @click="dismiss"
    />
  </div>
</template>

<script>
import addHours from "date-fns/addHours";
import { storeToRefs } from "pinia";

import { durationTimer } from "shared/helpers/date";
import { useStreamsStore } from "shared/stores/streams";

export default {
  name: "MentionStreamExpiryWatcher",
  props: {
    stream: {
      type: Object,
      default: () => ({}),
    },
  },
  setup() {
    const streamsStore = useStreamsStore();
    const { updateStream } = storeToRefs(streamsStore);

    return {
      updateStream,
    };
  },
  data() {
    return {
      timeout: null,
      expiresIn: "",
    };
  },
  computed: {
    expiryDate() {
      return new Date(this.stream.expires_at);
    },
  },
  mounted() {
    this.timeout = setInterval(this.updateTimer, 1000); // eslint-disable-line @typescript-eslint/no-implied-eval
    this.updateTimer();
  },
  beforeUnmount() {
    clearInterval(this.timeout);
  },
  methods: {
    updateTimer() {
      this.expiresIn = durationTimer(this.expiryDate, new Date());
    },
    extend() {
      const params = {
        expires_at: addHours(new Date(this.stream.expires_at), 72),
      };
      this.updateStream({
        id: this.stream.id,
        params,
      });
    },
    dismiss() {
      this.updateStream({
        id: this.stream.id,
        params: { expiration_dismissed: true },
      });
    },
  },
};
</script>

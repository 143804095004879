import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

@Model()
class RedditSubreddit extends ApplicationRecord {
  static jsonapiType = "reddit_subreddits";

  @Attr() name: string;

  @Attr() enabled: boolean;

  @Attr() categoryId: number;

  @Attr() locationId: number;

  @Attr() subscriberCount: number;

  @Attr({ persist: false }) createdAt: string;

  @Attr({ persist: false }) updatedAt: string;
}

export default RedditSubreddit;

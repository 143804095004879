export const USA = {
  id: "US",
  name: "United States of America",
};

export const CANADA = {
  id: "CA",
  name: "Canada",
};

export const MEXICO = {
  id: "MX",
  name: "Mexico",
};

export const AUSTRALIA = {
  id: "AU",
  name: "Australia",
};

export const NEW_ZEALAND = {
  id: "NZ",
  name: "New Zealand",
};

export const UNITED_KINGDOM = {
  id: "GB",
  name: "United Kingdom",
};

export const GERMANY = {
  id: "DE",
  name: "Germany",
};

export const EUROPE = {
  id: "EUROPE",
  name: "Europe",
};

export const ASIA = {
  id: "ASIA",
  name: "Asia",
};

export const AFRICA = {
  id: "AFRICA",
  name: "Africa",
};

export const SOUTH_AMERICA = {
  id: "SOUTH_AMERICA",
  name: "South America",
};

// Only for use in the AU environment
// This is set to visible=0 in the UK
export const NORTH_AMERICA = {
  id: "NORTH_AMERICA",
  name: "North America",
};

export const FRANCE = {
  id: "FR",
  name: "France",
};

export const NORTH_AMERICA_LOCATION_IDS = [CANADA.id, USA.id, MEXICO.id];

export const DEFAULT_LOCATION_IDS = [
  ...NORTH_AMERICA_LOCATION_IDS,
  AUSTRALIA.id,
  NEW_ZEALAND.id,
  UNITED_KINGDOM.id,
  EUROPE.id,
  GERMANY.id,
];

export const EUROPE_EXCLUDED_LOCATION_IDS = [UNITED_KINGDOM.id];

export const TOP_LOCATION_NAMES = [
  USA.name,
  CANADA.name,
  MEXICO.name,
  SOUTH_AMERICA.name,
  UNITED_KINGDOM.name,
  EUROPE.name,
  AUSTRALIA.name,
  NEW_ZEALAND.name,
  ASIA.name,
  AFRICA.name,
];

export const PREFERRED_LOCATIONS_BY_REGION = {
  [AUSTRALIA.id]: [AUSTRALIA.name, NEW_ZEALAND.name],
  [NEW_ZEALAND.id]: [NEW_ZEALAND.name, AUSTRALIA.name],
  [UNITED_KINGDOM.id]: [UNITED_KINGDOM.name, EUROPE.name],
  [USA.id]: [USA.name, CANADA.name],
  [CANADA.id]: [CANADA.name, USA.name],
  [EUROPE.id]: [EUROPE.name, UNITED_KINGDOM.name],
  [FRANCE.id]: [EUROPE.name],
  DACH: [EUROPE.name],
  NORDIC: [EUROPE.name],
  APAC: [
    USA.name,
    CANADA.name,
    MEXICO.name,
    SOUTH_AMERICA.name,
    UNITED_KINGDOM.name,
    EUROPE.name,
    AUSTRALIA.name,
    NEW_ZEALAND.name,
    ASIA.name,
    AFRICA.name,
  ],
};

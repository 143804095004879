import { Attr, Model } from "spraypaint";

import ApplicationRecord from "shared/services/spraypaint";

import Influencer from "./Influencer";
import Outlet from "./Outlet";
import type { Email } from "./types";

@Model()
export class Contact extends ApplicationRecord {
  static jsonapiType = "contacts";

  @Attr() avatarUrl: string;

  @Attr() name: string;

  @Attr() emails: Email[];

  get email(): string | undefined {
    return this.emails?.find((email) => email.defaultFlag)?.email;
  }
}

export function isOutlet(contact: Contact): contact is Outlet {
  return contact.resourceIdentifier.type.includes("outlets");
}

export function isInfluencer(contact: Contact): contact is Influencer {
  return contact.resourceIdentifier.type.includes("influencers");
}

export default Contact;
